import { useEffect, useState } from 'react';

import { Token } from '@stripe/stripe-js';

import { Card } from '@parsec/kessel';
import { styled } from '@parsec/styles';

import BillingCardForm from '../BillingCardForm';
import BillingCardPreview from '../BillingCardPreview';
import Button from '../Button';
import FieldLabel from '../FieldLabel';
import Input from '../Input';
import Loading from '../Loading';

export interface PaymentMethodFormProps {
  id?: string;
  card?: Card;
  disabled?: boolean;
  loading: boolean;
  showSubmitButton?: boolean;
  showName?: boolean;
  name?: string;
  onToggleEdit?(): void;
  onSubmit(token: Token, event: Event): Promise<void>;
  onSubmitError?(err: Error): void;
  onSubmitSuccess?(): void;
  setLoading?(): void;
  version?: 'newFont';
}

export default function PaymentMethodForm(props: PaymentMethodFormProps) {
  const {
    id,
    card,
    disabled,
    loading,
    showName = false,
    showSubmitButton = true,
    onToggleEdit,
    onSubmit,
    onSubmitError,
    onSubmitSuccess,
    version
  } = props;

  const [isEditing, setIsEditing] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [name, setName] = useState(props.name ?? '');
  const mustEdit = !card;

  useEffect(() => {
    setIsEditing(mustEdit);
  }, [mustEdit]);

  return (
    <Wrapper>
      <Loading loading={loading}>
        {() => (
          <>
            <Header>
              <Title version={version}>Payment Method</Title>
              {isEditing ? (
                <Button
                  version={version}
                  level="secondary"
                  size="small"
                  onClick={() => {
                    setIsEditing(false);
                    onToggleEdit?.();
                  }}
                  disabled={disabled || isUpdating || mustEdit}
                >
                  Cancel
                </Button>
              ) : (
                <MethodFormButton
                  version={version}
                  onClick={() => {
                    setIsEditing(true);
                    onToggleEdit?.();
                  }}
                >
                  Edit
                </MethodFormButton>
              )}
            </Header>

            <FormWrapper>
              {isEditing || mustEdit ? (
                <>
                  {showName && (
                    <FieldSet>
                      <FieldLabel version={version}>
                        <FieldLabel.Label label="Name" required>
                          <Input
                            version={version}
                            form={id}
                            id="name"
                            name="name"
                            onChange={e => setName(e.target.value)}
                            defaultValue={
                              card?.first_name
                                ? `${card?.first_name} ${card?.last_name}`
                                : ''
                            }
                            required
                          />
                        </FieldLabel.Label>
                      </FieldLabel>
                    </FieldSet>
                  )}
                  <FieldSet>
                    <BillingCardForm
                      version={version}
                      id={id}
                      name={name}
                      disabled={disabled}
                      onStartSubmit={() => {
                        setIsUpdating(true);
                      }}
                      onSubmit={onSubmit}
                      onFinishSubmit={() => {
                        setIsUpdating(false);
                      }}
                      onSubmitError={err => onSubmitError?.(err)}
                      onSubmitSuccess={() => {
                        onSubmitSuccess?.();
                        setIsEditing(false);
                      }}
                    />
                  </FieldSet>
                </>
              ) : (
                <BillingCardPreview
                  version={version}
                  card={card}
                  name={showName ? name : undefined}
                />
              )}
            </FormWrapper>

            {showSubmitButton && isEditing && (
              <div>
                <Button
                  version={version}
                  form={id}
                  type="submit"
                  disabled={!isEditing}
                  loading={isUpdating}
                >
                  Update Card
                </Button>
              </div>
            )}
          </>
        )}
      </Loading>
    </Wrapper>
  );
}

const FormWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$large'
});

const FieldSet = styled('fieldset', {
  display: 'flex',
  flexDirection: 'column',
  flex: 1
});

const Wrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$large',
  minHeight: '10rem'
});

const Header = styled('header', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0
});

const Title = styled('h5', {
  fontSize: '$attribution',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const MethodFormButton = styled('button', {
  background: 'transparent',
  fontSize: '$attribution',
  lineHeight: '$attribution',
  fontWeight: 'bold',
  color: '$primary500',
  margin: '0.4rem 0',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
