import { styled } from '@parsec/components';

export const Subtitle = styled('h4', {
  fontSize: '$header',
  lineHeight: '$header',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$attribution'
      }
    }
  }
});
