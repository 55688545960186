import { ReactNode } from 'react';

import { Icon, IconNames, styled, Tooltip } from '@parsec/components';

export interface SidebarListItemProps {
  className?: string;
  title: string;
  subtitle?: string | ReactNode;
  color?: string;
  selected?: boolean;
  onClick?(): void;
  icon?: {
    name: IconNames;
    tooltip: ReactNode;
  };
  type?: 'primary' | 'secondary';
  version?: 'newFont';
}

export function SidebarListItem(props: SidebarListItemProps) {
  const {
    className,
    title,
    subtitle,
    selected,
    icon,
    type = 'secondary',
    onClick,
    version
  } = props;
  const color = props.color ?? 'transparent';
  const invertColor = !!props.color;
  return (
    <Wrapper
      hasColor={invertColor}
      selected={selected}
      onClick={onClick}
      clickable={!!onClick}
      className={className}
      color={type === 'primary' || !selected ? 'white' : 'blue'}
      css={listItemCss(!!selected, color)}
      role="group"
      tabIndex={0}
    >
      <Title version={version}>{title}</Title>
      <Subtitle type={type} version={version}>
        {subtitle}
      </Subtitle>
      {icon && (
        <IconSection>
          <Tooltip placement="top" tooltipText={icon.tooltip} version={version}>
            <StyledIcon name={icon.name} />
          </Tooltip>
        </IconSection>
      )}
    </Wrapper>
  );
}

const listItemCss = (selected: boolean, color: string) => ({
  '&::before': {
    backgroundColor: color
  },
  '&::after': {
    backgroundColor: selected ? color : 'transparent'
  }
});

const Wrapper = styled('div', {
  '& > *': {
    zIndex: 1
  },
  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: '$xxlarge',
    margin: 'auto',
    display: 'block',
    width: '1.6rem',
    height: '1.6rem',
    borderRadius: '1.6rem'
  },
  '&::after': {
    content: '',
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    margin: 'auto',
    display: 'block',
    borderRadius: '$medium',
    opacity: '.15',
    zIndex: 0
  },
  height: '6.4rem',
  padding: '0 $xxlarge',
  position: 'relative',
  borderRadius: '$medium',
  display: 'inline-flex',
  flexFlow: 'column',
  alignItems: 'flex-start',
  justifyContent: 'center',
  overflow: 'hidden',
  '&:hover': {
    color: '$primary500'
  },

  '&:focus': {
    color: '$primary500'
  },

  variants: {
    clickable: {
      true: {
        cursor: 'pointer'
      }
    },
    selected: {
      true: {
        backgroundColor: '$carkol'
      }
    },
    color: {
      blue: {
        color: '$primary500'
      },
      white: {
        color: '$consoleWhite'
      }
    },
    hasColor: {
      true: {
        paddingLeft: '4.7rem',
        paddingRight: '4.7rem'
      }
    }
  }
});

const Title = styled('h3', {
  fontSize: '$body',
  lineHeight: '$body',
  width: '100%',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  color: 'inherit',
  '&:hover': {
    color: '$primary500'
  },
  '&:focus': {
    color: '$primary500'
  },
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody',
        lineHeight: '$attribution'
      }
    }
  }
});

const Subtitle = styled('div', {
  marginTop: '$small',
  maxHeight: '3rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  lineHeight: '$info',
  display: '-webkit-box',
  '-webkit-line-clamp': 2,
  lineClamp: 2,
  '-webkit-box-orient': 'vertical',
  fontSize: '$info',
  variants: {
    type: {
      secondary: { color: '$rhyhorn' },
      primary: {
        color: '$consoleWhite'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const IconSection = styled('div', {
  position: 'absolute',
  top: 0,
  right: '$xlarge',
  bottom: 0,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '$duskull',
  borderRadius: '100%',
  width: '2rem',
  height: '2rem'
});

const StyledIcon = styled(Icon, {
  width: '1rem',
  color: '$rhyhorn'
});
