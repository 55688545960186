/**
 * This component is deprecated use BaseModal instead
 */

import { createContext, useContext, useState, ReactNode } from 'react';

import { createPortal } from 'react-dom';
import ReactModal, { Aria } from 'react-modal';

import { styled } from '@parsec/styles';

import Button from '../Button';
import IconButton from '../IconButton';

export interface ModalAction {
  text: string;
  onClick?(): void;
  level?: 'primary' | 'secondary' | 'link';
  kind?: 'neutral' | 'success' | 'error';
  disabled?: boolean;
  form?: string;
}

export interface ModalProps {
  title?: string;
  description?: ReactNode;
  children: React.ReactNode;
  size?: 'small' | 'medium' | 'large';
  isOpen: boolean;
  onOpen?(): void;
  onClose(): void;
  onAfterClose?(): void;
  actions?: ModalAction[];
  appElement?: HTMLElement;
  role?: string;
  aria?: Aria;
  version?: 'newFont';
}

const ModalContext = createContext<HTMLElement | null>(null);

function Modal(props: ModalProps) {
  const {
    title,
    description,
    children,
    size = 'large',
    isOpen,
    onOpen,
    onAfterClose,
    onClose,
    actions = [],
    appElement,
    role = 'dialog',
    aria,
    version
  } = props;

  const [portal, setPortal] = useState<HTMLElement | null>(null);

  return (
    <ModalContext.Provider value={portal}>
      <ReactModal
        isOpen={isOpen}
        closeTimeoutMS={250}
        contentLabel={title}
        onAfterOpen={onOpen}
        onRequestClose={onClose}
        onAfterClose={onAfterClose}
        appElement={appElement}
        role={role}
        aria={aria}
      >
        <Wrapper size={size}>
          <HeaderWrapper>
            <Header rows={description ? 2 : 1}>
              {title && <Title version={version}>{title}</Title>}

              {description && (
                <Description version={version}>{description}</Description>
              )}
              <Close
                icon="ex"
                title="Close"
                kind="basic"
                onClick={() => onClose()}
              />
            </Header>
          </HeaderWrapper>
          <Content version={version}>{children}</Content>
          <Footer ref={setPortal}>
            {actions.map((action, i) => (
              <ModalBtn
                key={i}
                onClick={action.onClick}
                level={action.level}
                kind={action.kind}
                disabled={action.disabled}
                form={action.form}
                version={version}
              >
                {action.text}
              </ModalBtn>
            ))}
          </Footer>
        </Wrapper>
      </ReactModal>
    </ModalContext.Provider>
  );
}
export default Modal;

function Actions(props: { children: ReactNode }) {
  const { children } = props;
  const portal = useContext(ModalContext);

  return portal ? createPortal(children, portal) : null;
}

export function setModalAppElement(el: string | HTMLElement) {
  ReactModal.setAppElement(el);
}

Modal.Actions = Actions;

ReactModal.defaultStyles = {};

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto 1fr auto',
  border: '0.05rem solid $pangoro',
  color: '$consoleWhite',
  borderRadius: '$xlarge',
  overflow: 'hidden',
  width: '38rem',
  maxHeight: '90vh',
  backgroundColor: '$computerBlack',
  minHeight: '25.6rem',
  boxShadow:
    '0 $xsmall $small $xsmall rgba(0, 0, 0, 0.15), inset 0 .1rem 0 rgba(255, 255, 255, 0.2)',
  variants: {
    size: {
      small: {
        width: '38rem'
      },
      medium: {
        width: '54rem'
      },
      large: {
        width: '62rem'
      }
    }
  }
});

const HeaderWrapper = styled('div', {
  padding: '0 $xxlarge',
  '&::after': {
    content: '',
    display: 'block',
    height: '0.1rem',
    backgroundColor: '$pangoro'
  }
});

const Header = styled('header', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  gridTemplateRows: 'auto auto',
  gap: '$medium',
  padding: '$xxlarge 0',

  variants: {
    rows: {
      [2]: {
        gridTemplateRows: 'auto auto'
      },
      [1]: {
        gridTemplateRows: 'auto'
      }
    }
  }
});

const Title = styled('h1', {
  fontWeight: 'bold',
  fontSize: '$subtitle',
  lineHeight: '$subtitle',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const Description = styled('div', {
  gridColumn: '1',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$consoleWhite',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        lineHeight: '$body'
      }
    }
  }
});

const ModalBtn = styled(Button, {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const Close = styled(IconButton, {
  gridRow: '1',
  gridColumn: '2'
});

const Content = styled('div', {
  overflow: 'auto',
  padding: '$xxlarge',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const Footer = styled('footer', {
  display: 'flex',
  gap: '$xxlarge',
  backgroundColor: '$carkol',
  padding: '$xxlarge',
  '&:empty': {
    display: 'none'
  }
});
