import { styled } from '@parsec/styles';

const ButtonSpan = styled('span', {
  display: 'inline-grid',
  alignItems: 'center',
  gridAutoFlow: 'column',
  gap: '1rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

export default ButtonSpan;
