import { styled, FieldLabel, Input } from '@parsec/components';
import { Customer } from '@parsec/kessel';

export interface CustomerFormValues {
  company_name: string;
  billing_email: string;
  phone: string;
  tax_id_type: string;
  tax_id_value: string;
}

export interface CustomerFormProps {
  className?: string;
  email?: string;
  customer?: Customer;
  form?: string;
  disabled?: boolean;
}

export function CustomerForm(props: CustomerFormProps) {
  const { className, email, customer, form, disabled } = props;

  return (
    <CardSection id="company_tax_billing_form" className={className}>
      <CardField span="half">
        <FieldLabel.Label label="Company Name" required>
          <Input
            name="company_name"
            placeholder="Parsec"
            defaultValue={customer?.company}
            form={form}
            disabled={disabled}
            required
          />
        </FieldLabel.Label>
      </CardField>
      <CardField span="half">
        <FieldLabel.Label label="Billing Email" required>
          <Input
            name="billing_email"
            placeholder="sam.oak@parsec.app"
            defaultValue={customer?.email || email}
            form={form}
            disabled={disabled}
            required
          />
        </FieldLabel.Label>
      </CardField>

      <CardField span="third">
        <FieldLabel.Label label="Phone">
          <Input
            name="phone"
            placeholder="XXX-XXX-XXXX"
            defaultValue={customer?.phone}
            disabled={disabled}
            form={form}
          />
        </FieldLabel.Label>
      </CardField>
      <CardField span="third">
        <FieldLabel.Label label="Tax ID">
          <Input
            name="tax_id_value"
            placeholder="ID"
            defaultValue={customer?.tax_id}
            disabled={disabled}
            form={form}
          />
        </FieldLabel.Label>
      </CardField>
    </CardSection>
  );
}

const CardSection = styled('section', {
  padding: '$xxlarge',
  display: 'grid',
  gridTemplateColumns: 'repeat(6, 1fr)',
  rowGap: '$xxlarge',
  columnGap: '$large'
});

const CardField = styled(FieldLabel, {
  variants: {
    span: {
      full: {
        gridColumn: 'span 6'
      },
      half: {
        gridColumn: 'span 3'
      },
      third: {
        gridColumn: 'span 2'
      },
      sixth: {
        gridColumn: 'span 1'
      }
    }
  }
});
