/* eslint-disable react/jsx-handler-names */
// TODO: Fix eslint
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import {
  Button,
  Icon,
  Input,
  MultiSelect,
  MultiSelectItemGroup,
  styled
} from '@parsec/components';
import { formValues } from '@parsec/form';
import {
  useBulkUpdateGroupMemberships,
  useAssignAppRule,
  useGetAllAppRules,
  useGetMe,
  useGetTeamMembers,
  useGetTeamRolePermissionSummary,
  useGetTeam
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { useSelectionState, useGetGroupsFromPerms } from 'lib/hooks';

import { Section, TeamMemberTableColumnType } from 'components';

import { TeamMemberListToolbar } from './TeamMemberListToolbar';
import { TeamMemberTable } from './TeamMemberTable';

const version = 'newFont';

type MemberSearchFilter =
  | {
      type: 'group';
      value: number;
    }
  | {
      type: 'appRule';
      value: string;
    }
  | {
      type: 'members';
      value: 'all' | 'admin';
    };

export enum MemberFilterType {
  SCIMUsers = 'SCIM Users',
  NonSCIMUsers = 'Non-SCIM Users',
  UsersInGroup = 'Users in a group',
  UsersNotInGroup = 'Users not in a group'
}

export interface TeamMemberSelectTableProps<T> {
  filter: MemberSearchFilter;
  groupId?: number;
  ruleId?: string;
  columns: TeamMemberTableColumnType[];
  memberFilters: MultiSelectItemGroup<T>[];
}

export function TeamMemberSelectTable<T>(props: TeamMemberSelectTableProps<T>) {
  const { groupId, ruleId, columns, memberFilters } = props;

  const searchBarRef = useRef<HTMLInputElement>(null);

  // Alerts
  const alert = useAlertContext();

  // Me
  const me = useGetMe().data;
  const userId = me?.id ?? 0;

  // Team
  const teamQuery = useGetTeam();
  const isScimEnabled = !!teamQuery.data?.capabilities.settings.is_scim_enabled;

  // Permissions (global)
  const permsQuery = useGetTeamRolePermissionSummary();
  const globalPerms = permsQuery.data?.team;

  // Team Groups
  const groups = useGetGroupsFromPerms([
    'assign_team_members_to_groups',
    'reset_team_members_tfa'
  ]);

  const assignableGroups = useGetGroupsFromPerms([
    'assign_team_members_to_groups'
  ]);

  // Team App Rules
  const allAppRulesQuery = useGetAllAppRules();
  const allAppRules = allAppRulesQuery.data?.data ?? [];

  // Filter
  const memberFilterTypes = memberFilters.flatMap(memberFilter => {
    return memberFilter.items.map(memberFilterItem => {
      return memberFilterItem.text;
    });
  });

  const [selectedMemberTypes, setSelectedMemberTypes] =
    useState(memberFilterTypes);

  /**
   * Uses the selected filters to determine whether to show only SCIM users in the table.
   */
  const showOnlySCIMUsers = useCallback(() => {
    return (
      !selectedMemberTypes.includes(MemberFilterType.NonSCIMUsers) &&
      selectedMemberTypes.includes(MemberFilterType.SCIMUsers)
    );
  }, [selectedMemberTypes]);

  /**
   * Uses the selected filters to determine whether to show only Non-SCIM users in the table.
   */
  const showOnlyNonSCIMUsers = useCallback(() => {
    return (
      selectedMemberTypes.includes(MemberFilterType.NonSCIMUsers) &&
      !selectedMemberTypes.includes(MemberFilterType.SCIMUsers)
    );
  }, [selectedMemberTypes]);

  const showOnlyGroupedMembers = useCallback(() => {
    return (
      selectedMemberTypes.includes(MemberFilterType.UsersInGroup) &&
      !selectedMemberTypes.includes(MemberFilterType.UsersNotInGroup)
    );
  }, [selectedMemberTypes]);

  const showOnlyUngroupedGroupedMembers = useCallback(() => {
    return (
      selectedMemberTypes.includes(MemberFilterType.UsersNotInGroup) &&
      !selectedMemberTypes.includes(MemberFilterType.UsersInGroup)
    );
  }, [selectedMemberTypes]);

  // Team Members
  const [teamMembersParams, setTeamMembersParams] = useState<
    Parameters<typeof useGetTeamMembers>[0]
  >({
    group_id: groupId,
    team_app_rule_id: ruleId,
    offset: 0,
    limit: 10,
    is_scim: showOnlySCIMUsers()
      ? true
      : showOnlyNonSCIMUsers()
        ? false
        : undefined,
    is_in_group: showOnlyGroupedMembers()
      ? true
      : showOnlyUngroupedGroupedMembers()
        ? false
        : undefined
  });

  const getTeamMembersQuery = useGetTeamMembers(teamMembersParams);
  const isLoadingTeamMembers = !getTeamMembersQuery.isFetched;
  const teamMembersQueryCount = getTeamMembersQuery.data?.count ?? 0;

  useEffect(() => {
    setTeamMembersParams(params => ({
      ...params,
      is_scim: showOnlySCIMUsers()
        ? true
        : showOnlyNonSCIMUsers()
          ? false
          : undefined,
      is_in_group: showOnlyGroupedMembers()
        ? true
        : showOnlyUngroupedGroupedMembers()
          ? false
          : undefined
    }));
  }, [
    showOnlySCIMUsers,
    showOnlyNonSCIMUsers,
    showOnlyGroupedMembers,
    showOnlyUngroupedGroupedMembers,
    setTeamMembersParams
  ]);
  // If global admin with certain perms, show all members
  // otherwise, only show members that belong to at least one
  // will only return paginations of members based on `limit` param in `teamMembersParams`
  const allMembers = getTeamMembersQuery.data?.data;

  const members = useMemo(() => {
    if (!allMembers) return [];

    // If filters exclude both SCIM members and Non-SCIM members, then there cannot be any results.
    if (
      memberFilterTypes.includes(MemberFilterType.SCIMUsers) &&
      !selectedMemberTypes.includes(MemberFilterType.NonSCIMUsers) &&
      !selectedMemberTypes.includes(MemberFilterType.SCIMUsers)
    )
      return [];

    if (
      memberFilterTypes.includes(MemberFilterType.UsersInGroup) &&
      !selectedMemberTypes.includes(MemberFilterType.UsersInGroup) &&
      !selectedMemberTypes.includes(MemberFilterType.UsersNotInGroup)
    )
      return [];

    const hasGlobalPerms = Boolean(
      globalPerms?.read_team_members ||
        globalPerms?.assign_team_members_to_groups ||
        globalPerms?.remove_team_members ||
        globalPerms?.reset_team_members_tfa
    );

    if (hasGlobalPerms) {
      return allMembers;
    }

    const canAssignToGroup = assignableGroups.length > 0;

    const m = allMembers.filter(member => {
      // If member doesn't belong to any group, include in final result if the admin has assign permission
      if (!member.team_group_ids || !member.team_group_ids?.length)
        return canAssignToGroup;

      // Otherwise, check that the member's group is in admin's jurisdiction
      return groups.some(group => {
        return member.team_group_ids?.includes(group.id);
      });
    });

    return m;
  }, [
    globalPerms,
    allMembers,
    assignableGroups.length,
    groups,
    memberFilterTypes,
    selectedMemberTypes
  ]);

  // Selections
  const selectionState = useSelectionState<number>(teamMembersQueryCount);
  const { selections } = selectionState;

  // Mutations
  const { mutateAsync: bulkUpdateGroupMemberships } =
    useBulkUpdateGroupMemberships();
  const { mutateAsync: assignAppRule } = useAssignAppRule();

  /**
   * Adds all selected team members to the chosen app rule
   */
  const onMassAssignAppRule = useCallback(
    async (ruleId: string) => {
      // Add user IDs to app rule
      try {
        await assignAppRule({
          app_rule_id: ruleId,
          user_ids: Array.from(selections.include as Set<number>)
        });
        alert.show({
          type: 'success',
          title: 'Success',
          message: 'Successfully updated member(s)',
          version
        });
        selectionState.deselectAll();
      } catch (err) {
        const res = parseError(err);
        alert.show({
          type: 'error',
          title: 'Error adding team member(s) to ruleset',
          message: res.body?.error ?? res.error,
          version
        });
        throw err;
      }
    },
    [assignAppRule, alert, selections.include, selectionState]
  );

  const selectAll = useCallback(
    () => selectionState.selectAll(members.map(m => m.user_id)),
    [members, selectionState]
  );

  const userIds = useMemo(
    () =>
      selections.mode === 'include'
        ? Array.from(selections.include)
        : Array.from(selections.exclude),
    [selections.mode, selections.include, selections.exclude]
  );

  const selectedUsersGroups = useMemo(() => {
    const groupMembershipMap = new Map(
      members.map(member => [member.user_id, member.team_group_ids])
    );
    return userIds.map(userId => groupMembershipMap.get(userId) ?? []);
  }, [userIds, members]);

  return (
    <>
      <StyledSection>
        <StyledForm
          id="team_member_search_form"
          onSubmit={e => {
            e.preventDefault();
            const values = formValues<{ search: string }>(e.nativeEvent);
            onSearch(values.search);
          }}
        >
          <Input
            ref={searchBarRef}
            name="search"
            icon="search"
            type="search"
            placeholder="Search by Email"
            autoComplete="off"
            version={version}
          />
        </StyledForm>
        {props.memberFilters.length > 0 && (
          <MultiSelect
            title="Filter"
            showActions={false}
            showSearchbar={false}
            placement="bottom-end"
            onSelect={item => {
              includeMemberType(item.text as string);
            }}
            onDeselect={item => {
              excludeMemberType(item.text as string);
            }}
            onReset={onResetFilter}
            items={props.memberFilters}
            version={version}
          >
            {({ isOpen, props }) => {
              return (
                <FilterButton
                  isFilterOn={isFilterOn()}
                  isOpen={isOpen}
                  {...props}
                  level="secondary"
                >
                  <ButtonWrapper version={version}>
                    <Icon name="filter" />
                    Filter
                  </ButtonWrapper>
                </FilterButton>
              );
            }}
          </MultiSelect>
        )}
      </StyledSection>
      <SearchList>
        <TeamMemberListToolbar
          selectionMode={selections.mode}
          selectedCount={selectionState.selectedCount}
          onSelectAll={selectAll}
          onSelectPage={onSelectPage}
          onDeselectAll={selectionState.deselectAll}
          groups={assignableGroups}
          selectedUsersGroups={selectedUsersGroups}
          onAddToGroups={onAddSelectedTeamMembersToGroups}
          appRules={allAppRules}
          onMassAssignAppRule={onMassAssignAppRule}
          offset={teamMembersParams.offset}
          limit={teamMembersParams.limit}
          total={teamMembersQueryCount}
          onPrevPage={onPrevPage}
          onNextPage={onNextPage}
        />

        <TableWrapper>
          <TeamMemberTable
            selectionState={selectionState}
            columns={columns}
            currentUserId={userId}
            isScimEnabled={isScimEnabled}
            members={members}
            isLoading={isLoadingTeamMembers}
            filterName={
              assignableGroups.find(g => g.id === teamMembersParams.group_id)
                ?.name ?? 'group'
            }
            onResetSearch={
              !!teamMembersParams.email ? onResetSearch : undefined
            }
            version={version}
          />
        </TableWrapper>
      </SearchList>
    </>
  );

  /**
   * Fetches a list of team members whose email addresses match the search string
   */
  function onSearch(email: string) {
    if (email) {
      setTeamMembersParams(params => ({
        ...params,
        limit: 10,
        offset: 0,
        email
      }));
    } else {
      onResetSearch();
    }
  }

  /**
   * Resets the search and the value in the search bar
   */
  function onResetSearch() {
    searchBarRef.current!.value = '';
    setTeamMembersParams(params => ({
      ...params,
      limit: 10,
      offset: 0,
      email: undefined
    }));
  }

  /**
   * Selects every team member on the current page
   */
  function onSelectPage() {
    selectionState.selectEach(members.map(x => x.user_id));
  }

  /**
   * Fetches the previous page of team members
   */
  function onPrevPage() {
    setTeamMembersParams(params => ({
      ...params,
      offset: params.offset - params.limit
    }));
  }

  /**
   * Fetches the next page of team members
   */
  function onNextPage() {
    setTeamMembersParams(params => ({
      ...params,
      offset: params.offset + params.limit
    }));
  }

  /**
   * Adds all selected team members to the chosen group
   */
  async function onAddSelectedTeamMembersToGroups(groupIds: number[] | null) {
    try {
      const members = userIds.reduce(
        (memo, current) => Object.assign(memo, { [current]: groupIds }),
        {}
      );
      await bulkUpdateGroupMemberships({ members });
      alert.show({
        type: 'success',
        title: 'Success',
        message: 'Successfully assigned team members to the selected groups.',
        version
      });
      selectionState.deselectAll();
    } catch (err) {
      const res = parseError(err);
      alert.show({
        type: 'error',
        title: 'Error Adding Team Members to Group',
        message: res.body?.error ?? res.error,
        version
      });
    }
  }

  /**
   * Adds a member type from the member types included in the table.
   */
  function includeMemberType(memberType: string) {
    const currentMemberTypes = [...selectedMemberTypes];
    currentMemberTypes.push(memberType as MemberFilterType);
    setSelectedMemberTypes(currentMemberTypes);
  }

  /**
   * Removes a member type from the member types included in the table.
   */
  function excludeMemberType(memberType: string) {
    const currentMemberTypes = [...selectedMemberTypes];
    const index = currentMemberTypes.indexOf(memberType as MemberFilterType);
    if (index > -1) {
      currentMemberTypes.splice(index, 1);
    }
    setSelectedMemberTypes(currentMemberTypes);
  }

  function isFilterOn() {
    return (
      selectedMemberTypes.length <
      props.memberFilters.map(group => group.items).flat().length
    );
  }

  function onResetFilter() {
    setSelectedMemberTypes(memberFilterTypes);
  }
}

const SearchList = styled('div', {
  background: '$carkol',
  borderRadius: '$medium',
  minHeight: '63rem',
  minWidth: '62rem'
});

const TableWrapper = styled('div', {
  padding: '0 $xxxlarge'
});

const StyledSection = styled(Section, { display: 'flex' });
const StyledForm = styled('form', { flex: 1 });

const ButtonWrapper = styled('div', {
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
  gap: '$medium',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const FilterButton = styled(Button, {
  variants: {
    isFilterOn: {
      true: {
        '&::after': {
          content: '""',
          backgroundColor: 'red',
          position: 'absolute',
          height: '0.8rem',
          width: '0.8rem',
          borderRadius: '0.8rem',
          display: 'block',
          top: '0.8rem',
          left: '2.8rem'
        }
      }
    },
    isOpen: {
      true: {
        background: '$consoleWhite',
        color: '$ultraDark',
        '&:hover': { backgroundColor: '$consoleWhite', color: '$ultraDark' }
      }
    }
  }
});
