import { type ReactNode, createContext, useContext, forwardRef } from 'react';

import { PortalProps } from '@radix-ui/react-select';

import { styled } from '@parsec/styles';

import Button from '../Button';
import DropdownSelect, {
  type DropdownSelectProps,
  Position
} from '../DropdownSelect';
import Icon from '../Icon';
import { StyledTrigger } from '../SharedDropdownStyles';
import { Align, Side, Size } from '../SharedType';

const ComparisonWrapper = styled('div', {
  fontFamily: '$newDefault',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 42.4rem)',
  gridColumnGap: '$xxlarge'
});

const Wrapper = styled('div', {
  fontFamily: 'inherit',
  display: 'grid',
  gridTemplateRows: 'min-content',
  border: '$space$xxsmall solid $nice',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  variants: {
    active: {
      true: {
        border: '$space$xsmall solid $warning500'
      }
    }
  }
});

const HeaderWrapper = styled('div', {
  fontFamily: 'inherit',
  display: 'grid',
  paddingTop: '2.2rem', // no token
  paddingBottom: '$xxlarge',
  color: '$consoleWhite',
  textAlign: 'center',
  borderRadius: '$large $large 0 0',
  borderBottom: '$space$xsmall solid $primary500',
  variants: {
    active: {
      true: {
        borderBottom: '$space$xsmall solid $warning500'
      }
    }
  }
});

const Heading = styled('h3', {
  fontFamily: 'inherit',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  paddingBottom: '$medium'
});

const Subheading = styled('div', {
  fontFamily: 'inherit',
  fontSize: '$info',
  fontWeight: '$normal',
  lineHeight: '$info'
});

const BlurbBox = styled('div', {
  display: 'grid',
  justifyContent: 'center',
  alignContent: 'center',
  backgroundColor: '$cereza',
  height: '7.3rem',
  padding: '0 8rem',
  fontFamily: '$newDefault',
  fontSize: '$info',
  fontWeight: '$bold',
  lineHeight: '$info',
  textAlign: 'center'
});

const FeaturesContainer = styled('div', {
  fontFamily: '$newDefault',
  padding: '$xxlarge',
  borderRadius: '0 0 $large $large'
});

const FeaturesList = styled('ul', {
  fontFamily: '$newDefault',
  padding: '0 8rem',
  listStyleType: 'disc',
  fontSize: '$newBody',
  lineHeight: '$body'
});

const FeatureItem = styled('li', {
  fontFamily: 'inherit',
  marginLeft: '0.5rem', // so marker aligns with the blurb a little better
  '& + &': { paddingTop: '$xxlarge' }
});

const PlanButton = styled(Button, {
  fontFamily: 'inherit',
  margin: '$xxlarge',
  alignSelf: 'flex-end'
});

const ValueFlex = styled('div', {
  fontFamily: 'inherit',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
});

const GreyedIcon = styled(Icon, {
  marginRight: '1rem',
  fill: '$nice',
  color: '$nice'
});

const CurrentPlanStatus = styled('div', {
  fontFamily: 'inherit',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution',
  color: '$nice',
  justifySelf: 'center',
  alignSelf: 'flex-end',
  padding: '2.6rem' // no token
});

const CardContext = createContext<{ active: boolean } | undefined>(undefined);

export const useCardContext = () => {
  const context = useContext(CardContext);
  if (!context) {
    throw new Error('useCardContext must be used within a <CardProvider>');
  }
  return context;
};

export interface PlanComparisonCardProps {
  active?: boolean;
  children: ReactNode;
}

const PlanComparisonCard = forwardRef<HTMLDivElement, PlanComparisonCardProps>(
  ({ active = false, children }, forwardedRef) => {
    return (
      <CardContext.Provider value={{ active }}>
        <Wrapper active={active} ref={forwardedRef}>
          {children}
        </Wrapper>
      </CardContext.Provider>
    );
  }
);

PlanComparisonCard.displayName = 'PlanComparisonCard';

interface CardHeaderProps {
  active?: boolean;
  children: ReactNode;
}

function CardHeader({ active: activeFromProps, children }: CardHeaderProps) {
  const { active: activeFrmCtx } = useCardContext();
  // can override with props
  const active = activeFromProps ?? activeFrmCtx;

  return <HeaderWrapper active={active}>{children}</HeaderWrapper>;
}

interface FeaturesProps {
  features: ReactNode[];
}

function Features({ features }: FeaturesProps) {
  return (
    <FeaturesContainer>
      <FeaturesList>
        {features.map((feat, idx) => (
          <FeatureItem key={idx}>{feat}</FeatureItem>
        ))}
      </FeaturesList>
    </FeaturesContainer>
  );
}

function EnterprisePlan() {
  return (
    <ValueFlex
      css={{
        paddingTop: '$xxxxlarge',
        paddingBottom: '0.9rem',
        fontSize: '$newBody',
        fontWeight: '$normal',
        lineHeight: '$info'
      }}
    >
      <GreyedIcon name="clock" role="presentation" />
      Enterprise Annual Plan
    </ValueFlex>
  );
}

const DropdownSelectTrigger = styled(DropdownSelect.Trigger, {
  ...StyledTrigger,
  width: '20rem',
  justifySelf: 'center',
  marginTop: '$xxlarge'
});

const SelectContent = styled(DropdownSelect.Content, { zIndex: 1 });

interface SelectProps extends DropdownSelectProps {
  containerRef?: PortalProps['container'];
}

function Select({ value, children, containerRef, ...rest }: SelectProps) {
  return (
    <DropdownSelect value={value} {...rest}>
      <DropdownSelectTrigger>
        <ValueFlex>
          <GreyedIcon name="clock" role="presentation" />
          <DropdownSelect.Value />
        </ValueFlex>
        <DropdownSelect.Icon>
          <Icon name="caret" />
        </DropdownSelect.Icon>
      </DropdownSelectTrigger>
      <DropdownSelect.Portal container={containerRef}>
        <SelectContent
          position={Position.Popover}
          side={Side.Bottom}
          sideOffset={8}
          align={Align.Center}
          alignOffset={0}
          size={Size.Small}
        >
          <DropdownSelect.Viewport>{children}</DropdownSelect.Viewport>
        </SelectContent>
      </DropdownSelect.Portal>
    </DropdownSelect>
  );
}

const PlanComparisonCardNamespace = Object.assign(PlanComparisonCard, {
  Blurb: BlurbBox,
  Button: PlanButton,
  ComparisonContainer: ComparisonWrapper,
  EnterprisePlan,
  Features,
  Header: CardHeader,
  Heading,
  Select,
  SelectItem: DropdownSelect.SelectItem,
  Subheading,
  CurrentPlanStatus
});

export { PlanComparisonCardNamespace as PlanComparisonCard };
