import type { ReactNode } from 'react';

import { useMultipleSelection, useSelect } from 'downshift';

interface MultiSelectArgs<T> {
  items: T[];
  initialSelectedItems: T[];
  onForceUpdate: (() => void) | null;
  onSelect?(item: T, nextSet: T[]): void;
  onDeselect?(item: T, nextSet: T[]): void;
  isItemDisabled(item: T): boolean;
}

export function useMultiSelect<V, T extends { value: V; text: ReactNode }>({
  items,
  initialSelectedItems,
  onForceUpdate,
  onSelect,
  onDeselect,
  isItemDisabled
}: MultiSelectArgs<T>) {
  const multipleSelect = useMultipleSelection({
    initialSelectedItems
  });

  const selectedValues = new Set(
    multipleSelect.selectedItems.map(item => item.value)
  );

  const select = useSelect<T>({
    selectedItem: null,
    items,
    itemToString: item => `${item?.text}`,
    onIsOpenChange: changes => {
      if (changes.isOpen) {
        multipleSelect.setSelectedItems(initialSelectedItems);
        onForceUpdate?.();
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          return {
            ...changes,
            highlightedIndex: state.highlightedIndex,
            isOpen: true // keep the menu open after selection.
          };
      }
      return changes;
    },
    onStateChange: ({ type, selectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
        case useSelect.stateChangeTypes.ItemClick:
          if (selectedItem) {
            if (!selectedValues.has(selectedItem.value)) {
              const nextSelectedItems = [
                ...multipleSelect.selectedItems,
                selectedItem
              ];
              multipleSelect.setSelectedItems(nextSelectedItems);
              onSelect?.(selectedItem, nextSelectedItems);
            } else {
              const nextSelectedItems = multipleSelect.selectedItems.filter(
                item => item.value !== selectedItem.value
              );
              multipleSelect.setSelectedItems(nextSelectedItems);
              onDeselect?.(selectedItem, nextSelectedItems);
            }
          }
          break;
        default:
          break;
      }
    },
    isItemDisabled
  });

  return {
    ...multipleSelect,
    ...select,
    resetSelections: () =>
      multipleSelect.setSelectedItems(initialSelectedItems),
    selectedValues
  };
}
