import { cookie, clear } from '@parsec/cookie';
import { configure } from '@parsec/kessel';
import { setAuthRedirect } from '@parsec/redirect';

import { KESSEL_URL, DASH_URL, DOWNTIME_URL } from 'lib/config';

/**
 * Kessel singleton
 */
export const kessel = configure({
  kessel: KESSEL_URL,
  downtimeURL: DOWNTIME_URL,
  token: () => cookie().token,
  middleware: res => {
    if (res.status === 401 || res.status === 412) {
      clear();
      const { location } = window;
      setAuthRedirect(location.href);
      window.location.assign(`${DASH_URL}/login/?invalidSession=${res.status}`);
    }
  }
});
