import type { ReactNode } from 'react';

import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { Token } from '@stripe/stripe-js';

import { config, styled } from '@parsec/styles';

import FieldLabel from '../FieldLabel';

export interface BillingCardFormProps {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  name?: string;
  onSubmit(token: Token, event: Event): void;
  onStartSubmit?(): void;
  onFinishSubmit?(): void;
  onSubmitError?(error: Error): void;
  onSubmitSuccess?(): void;
  id?: string;
  version?: 'newFont';
}

function BillingCardForm(props: BillingCardFormProps) {
  const {
    id,
    className,
    disabled,
    name = '',
    onSubmit,
    onStartSubmit,
    onFinishSubmit,
    onSubmitError,
    onSubmitSuccess,
    children,
    version
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  return (
    <StyledForm
      id={id}
      className={className}
      onSubmit={async e => {
        try {
          e.preventDefault();
          onStartSubmit?.();

          const card = elements?.getElement(CardElement);
          if (!stripe || !card) throw new Error("Couldn't load Stripe.");

          const { token, error } = await stripe.createToken(card, { name });
          if (error) throw error;
          else if (!token) throw new Error("Couldn't save token.");

          await onSubmit(token, e.nativeEvent);
          onSubmitSuccess?.();
        } catch (err) {
          onSubmitError?.(err as Error);
        } finally {
          onFinishSubmit?.();
        }
      }}
    >
      <FieldLabel version={version}>
        <FieldLabel.Label label="Card" required>
          <StyledCardElement
            disabled={disabled}
            options={{
              disabled,
              iconStyle: 'solid',
              style: {
                base: {
                  lineHeight: '36px',
                  fontFamily: 'Source Sans Pro',
                  color: config.theme.colors.consoleWhite.value,
                  ':disabled': {
                    color: config.theme.colors.nice.value,
                    backgroundColor: config.theme.colors.duskull.value
                  },

                  '::placeholder': {
                    color: config.theme.colors.rhyhorn.value
                  }
                }
              }
            }}
          />
        </FieldLabel.Label>
      </FieldLabel>
      {children}
    </StyledForm>
  );
}

export default BillingCardForm;

const StyledForm = styled('form', {
  width: '100%'
});

const StyledCardElement = styled(CardElement, {
  width: '100%',
  height: '3.6rem',
  backgroundColor: '$cereza',
  borderRadius: '$medium',
  transition: '125ms box-shadow ease',
  boxShadow: '0rem $space$xxsmall 0 rgba(255, 255, 255, 0.1)',
  padding: '0 1rem',
  color: '$consoleWhite',
  '& ~ svg': {
    color: '$nice'
  },
  '&::placeholder': {
    color: '$rhyhorn'
  },

  '&[type="number"]': {
    MozAppearance: 'textfield'
  },

  '&:-webkit-autofill:focus': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },
  '&:-webkit-autofill': {
    transition: 'background-color 600000s 0s, color 600000s 0s'
  },

  '&:disabled': {
    color: '$nice',
    backgroundColor: '$duskull',
    '&::placeholder': {
      color: '$nice'
    },
    '& ~ svg': {
      color: '$nice'
    }
  },

  '&::-webkit-inner-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },
  '&::-webkit-outer-spin-button': {
    WebkitAppearance: 'none',
    margin: 0
  },

  variants: {
    disabled: {
      true: {
        color: '$nice',
        backgroundColor: '$duskull',
        '&::placeholder': {
          color: '$nice'
        },
        '& ~ svg': {
          color: '$nice'
        }
      },
      false: {}
    }
  },

  '&.StripeElement--hover': {
    boxShadow:
      'inset 0 0 0 .1rem $colors$pangoro, 0px .1rem 0px rgba(255,255,255,0.1)'
  },
  '&.StripeElement--focus': {
    boxShadow: 'inset 0 0 0 .2rem $colors$primary500',
    color: '$consoleWhite',
    border: 'none',
    outlineStyle: 'none',
    '& ~ svg': {
      color: '$consoleWhite '
    }
  },
  '&.StripeElement--invalid': {
    boxShadow: 'inset 0 0 0 $space$xmall $colors$error500'
  }
});
