import { styled } from '@parsec/components';

export const Content = styled('div', {
  width: '98rem',
  margin: '0 auto',
  padding: '3.2rem $xxlarge $xxxlarge',
  rowGap: '$xxxlarge',
  display: 'grid',
  position: 'relative',

  variants: {
    header: {
      true: {
        paddingTop: '0',
        paddingBottom: '0',
        '@large': {
          gridAutoFlow: 'column'
        }
      }
    }
  }
});
