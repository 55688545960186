import { styled } from '@parsec/components';

export const Description = styled('p', {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    },
    info: {
      true: {
        margin: 0,
        fontSize: '$info',
        lineHeight: '$info',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    }
  },
  compoundVariants: [
    {
      version: 'newFont',
      info: 'true',
      css: {
        fontSize: '$newInfo'
      }
    }
  ]
});
