import { styled, AlertBanner, Time } from '@parsec/components';
import { SUPPORT_LINKS } from '@parsec/constants';

const Banner = styled(AlertBanner, {
  fontFamily: '$newDefault',
  margin: '0',
  '&:has(+ :not(&))': {
    marginBottom: '0'
  }
});

const Link = styled('a', {
  fontFamily: '$newDefault',
  color: 'inherit',
  fontWeight: '$bold'
});

export interface UsersToRemoveAlertBannerProps {
  onDownload: () => void;
  usersToRemove: number;
  endOfTermDate: string;
}
export function UsersToRemoveAlertBanner({
  onDownload,
  usersToRemove,
  endOfTermDate
}: UsersToRemoveAlertBannerProps) {
  return (
    <Banner level="warn" onClose={onDownload} isDismissible version="newFont">
      <AlertBanner.Icon type="warning" />
      <AlertBanner.Description>
        You have {usersToRemove} {usersToRemove > 1 ? 'users' : 'user'} at risk
        of removal due to pending subscription changes
        {endOfTermDate !== '' ? (
          <>
            {' '}
            on <Time date={new Date(endOfTermDate)} />
          </>
        ) : null}
        . Have questions?{' '}
        <Link
          href={SUPPORT_LINKS.DECREASING_SEATS}
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn more
        </Link>
      </AlertBanner.Description>
      <AlertBanner.Action>Download Pending Users & Invites</AlertBanner.Action>
    </Banner>
  );
}
