import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

export interface AlertProps {
  type?: 'success' | 'error';
  title?: ReactNode;
  message: string | JSX.Element;
  className?: string;
  version?: 'newFont';
}

export default function Alert(props: AlertProps) {
  const { className, type = 'success', title, message, version } = props;
  return (
    <Wrapper className={className} type={type} version={version}>
      <StyledIcon name={type === 'success' ? 'check' : 'ban'} />
      <Title version={version}>{title}</Title>
      <p>{message}</p>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateColumns: '2.4rem auto',
  gridTemplateRows: 'auto auto',
  alignItems: 'center',
  columnGap: '$xlarge',
  backgroundColor: '$success500',
  borderRadius: '$small',
  padding: '$large $xxlarge',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    },
    type: {
      success: {
        backgroundColor: '$success500'
      },
      error: {
        backgroundColor: '$error500'
      }
    }
  }
});

const Title = styled('h3', {
  variants: {
    version: {
      newFont: {
        fontSize: '$attribution'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  width: '2.4rem',
  height: '2.4rem',
  gridRow: '1 / 3'
});
