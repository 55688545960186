import { useState } from 'react';

export enum SelectionMode {
  Include = 'include',
  Exclude = 'exclude'
}

export interface SelectionState<T> {
  mode: SelectionMode;
  include: Set<T>;
  exclude: Set<T>;
}

export function useSelectionState<T>(totalResults: number) {
  const [selections, setSelections] = useState<SelectionState<T>>({
    mode: SelectionMode.Include,
    include: new Set(),
    exclude: new Set()
  });
  return {
    selections,
    selectedCount:
      selections.mode === SelectionMode.Include
        ? selections.include.size
        : totalResults - selections.exclude.size,
    isSelected(target: T) {
      return selections.mode === SelectionMode.Include
        ? selections.include.has(target)
        : !selections.exclude.has(target);
    },
    deselectAll() {
      setSelections({
        mode: SelectionMode.Include,
        include: new Set(),
        exclude: new Set()
      });
    },
    selectAll(allItems: T[]) {
      setSelections(selections => {
        const mode = SelectionMode.Exclude;
        const include = new Set(selections.include);
        include.clear();
        for (const d of allItems) {
          include.add(d);
        }
        const exclude = new Set(selections.exclude);
        exclude.clear();
        return { mode, include, exclude };
      });
    },
    selectEach(items: T[]) {
      setSelections(selections => {
        const mode = SelectionMode.Include;
        const include = new Set(selections.include);
        for (const id of items) {
          include.add(id);
        }
        const exclude = new Set(selections.exclude);
        exclude.clear();
        return { mode, include, exclude };
      });
    },
    selectPage(offset: number, limit: number, data: T[]) {
      setSelections(selections => {
        const mode = SelectionMode.Include;
        const include = new Set(selections.include);
        for (let i = offset; i < offset + limit; i++) {
          if (!Number.isInteger(data[i])) continue;
          include.add(data[i]);
        }
        const exclude = new Set(selections.exclude);
        exclude.clear();
        return { mode, include, exclude };
      });
    },
    selectOne(item: T) {
      switch (selections.mode) {
        case SelectionMode.Include: {
          setSelections(selections => {
            const include = new Set(selections.include);
            include.add(item);
            const exclude = new Set(selections.exclude);
            exclude.clear();
            const mode = selections.mode;
            return { mode, include, exclude };
          });
          break;
        }
        case SelectionMode.Exclude: {
          setSelections(selections => {
            const include = new Set(selections.include);
            include.clear();
            const exclude = new Set(selections.exclude);
            exclude.delete(item);

            const mode =
              exclude.size === totalResults // none selected
                ? SelectionMode.Include
                : SelectionMode.Exclude;
            return { mode, include, exclude };
          });
          break;
        }
      }
    },
    deselectOne(item: T) {
      switch (selections.mode) {
        case SelectionMode.Include: {
          setSelections(selections => {
            const include = new Set(selections.include);
            include.delete(item);
            const exclude = new Set(selections.exclude);
            exclude.clear();
            const mode = selections.mode;
            return { mode, include, exclude };
          });
          break;
        }
        case SelectionMode.Exclude: {
          setSelections(selections => {
            const include = new Set(selections.include);
            include.clear();
            const exclude = new Set(selections.exclude);
            exclude.add(item);

            const mode =
              exclude.size === totalResults // no selections
                ? SelectionMode.Include
                : SelectionMode.Exclude;
            return { mode, include, exclude };
          });
          break;
        }
      }
    }
  };
}
