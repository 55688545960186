import { useEffect, useState, ReactNode } from 'react';

import { useSelect } from 'downshift';
import { createPortal } from 'react-dom';
import { usePopper, PopperProps } from 'react-popper';

import { CSS, styled } from '@parsec/styles';

import Avatar from '../Avatar';
import { default as BaseIcon, IconNames } from '../Icon';

export interface FlyoutItemType {
  icon?: IconNames | number;
  iconStyle?: CSS;
  text: ReactNode;
  value?: string;
  key?: string;
  onSelect?(): void;
  type?: 'neutral' | 'negative'; // not used in the component
  disabled?: boolean;
  action?: { text: string; onClick(): void } | null;
}

type FlyoutChildrenFunction = (options: {
  isOpen: boolean;
  props: object;
  selectedItem: FlyoutItemType | null;
}) => ReactNode;

export interface FlyoutProps {
  className?: string; // not used in the component
  y?: number;
  x?: number;
  placement?: PopperProps<unknown>['placement'];
  items: FlyoutItemType[] | FlyoutItemType[][];
  defaultValue?: string;
  children: FlyoutChildrenFunction;
  version?: 'newFont';
}

export default function Flyout(props: FlyoutProps) {
  const {
    x = 0,
    y = 8,
    placement = 'bottom-end',
    items,
    defaultValue,
    children,
    version
  } = props;

  ///This component is a standin during the Design Audit. The end goal being to get items that will be used as 'Flyouts' off of the Dropdown component so as not to clutter that component with unneccesary conditoinal rendering.

  const [toggle, setToggle] = useState<HTMLElement | null>(null);
  const [popover, setPopover] = useState<HTMLElement | null>(null);

  const offset: [number, number] =
    placement.startsWith('left') || placement.startsWith('right')
      ? [y, x]
      : [x, y];

  const { styles, attributes, forceUpdate } = usePopper(toggle, popover, {
    placement,
    modifiers: [
      {
        name: 'flip'
      },
      {
        name: 'offset',
        options: { offset }
      }
    ]
  });

  const {
    isOpen,
    toggleMenu,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    setHighlightedIndex,
    selectedItem,
    selectItem
  } = useSelect<FlyoutItemType>({
    defaultSelectedItem: items
      .flat()
      .find(item => (!defaultValue ? false : item.value === defaultValue)),
    items: items.flat(),
    itemToString: item => `${item?.text}`,
    onIsOpenChange: changes => {
      if (changes.isOpen && forceUpdate) forceUpdate();
    },
    onSelectedItemChange: state => {
      state.selectedItem?.onSelect?.();
      // Note: Clear selection immediately because
      // onChange callback gets triggered only
      // when the selectedItem value changes
      selectItem(null);
    }
  });

  const toggleProps = getToggleButtonProps({
    ref: setToggle
  });

  let index = 0;

  const menus = Array.isArray(items[0])
    ? (items as FlyoutItemType[][])
    : [items as FlyoutItemType[]];

  const isIcon = menus.flat().some(item => item.icon !== undefined);

  useEffect(() => {
    if (forceUpdate) forceUpdate();
  }, [forceUpdate, isOpen]);

  useEffect(() => {
    if (isOpen) window.addEventListener('scroll', toggleMenu);
    return () => window.removeEventListener('scroll', toggleMenu);
  }, [isOpen, toggleMenu]);

  return (
    <>
      {children({
        isOpen,
        props: toggleProps,
        selectedItem
      })}

      {createPortal(
        <FlyoutMenuWrapper
          style={styles.popper}
          {...attributes.popper}
          {...getMenuProps({ ref: setPopover })}
        >
          {isOpen && (
            <FlyoutMenu onMouseLeave={() => setHighlightedIndex(-1)}>
              {menus.map((items, i) => {
                return (
                  <FlyoutUl key={i}>
                    {items.map(item => {
                      const itemIndex = index;
                      index += 1;

                      const highlighted = Boolean(
                        highlightedIndex >= 0
                          ? highlightedIndex === itemIndex
                          : selectedItem?.text === item.text
                      );

                      return (
                        // eslint-disable-next-line react/jsx-key
                        <FlyoutLi
                          {...getItemProps({
                            key: item.key || `${item.text}`,
                            disabled: item.disabled,
                            item,
                            index: itemIndex
                          })}
                          highlighted={highlighted}
                          action={!!item.action}
                          isIcon={isIcon}
                          version={version}
                        >
                          {item.icon && typeof item.icon === 'string' ? (
                            <Icon
                              name={item.icon as IconNames}
                              css={item.iconStyle}
                            />
                          ) : item.icon ? (
                            <Avatar size={16} userId={item.icon} />
                          ) : null}

                          <span>{item.text}</span>
                          {item.action && (
                            <Action
                              version={version}
                              onClick={item.action.onClick}
                            >
                              {item.action.text}
                            </Action>
                          )}
                        </FlyoutLi>
                      );
                    })}
                  </FlyoutUl>
                );
              })}
            </FlyoutMenu>
          )}
        </FlyoutMenuWrapper>,
        document.getElementById('popovers') ?? document.body
      )}
    </>
  );
}

export const FlyoutMenuWrapper = styled('div', {
  minWidth: '19.2rem',
  '&:focus': {
    outlineStyle: 'none'
  }
});

export const FlyoutMenu = styled('div', {
  backgroundColor: '$cereza',
  zIndex: 1000,
  padding: '$medium 0rem 0rem',
  display: 'flex',
  flexDirection: 'column',
  minHeight: '5.6rem',
  border: '0.1rem solid rgba(249, 249, 249, 0.1)',
  boxShadow: '0rem .2rem 2rem 2rem rgba(0, 0, 0, 0.1)',
  borderRadius: '$medium',
  overflowY: 'auto',
  overflowX: 'hidden'
});

export const FlyoutUl = styled('ul', {
  height: 'fit-content',
  paddingBottom: '$medium',

  '& + &': {
    borderTop: 'solid 0.1rem $pancham',
    paddingTop: '$medium'
  }
});

export const FlyoutLi = styled('li', {
  padding: '1rem $large', //not a token
  borderRadius: '$small',
  cursor: 'pointer',
  fontSize: '1.4rem', //not a token
  lineHeight: '$info',
  whiteSpace: 'pre-line',
  display: 'grid',
  gridTemplateColumns: '1.6rem 1fr',
  outline: 'none',
  gap: '1.7rem', //not a token
  alignItems: 'center',
  color: '$consoleWhite',
  '&:hover': {
    backgroundColor: 'rgba(249, 249, 249, 0.05)'
  },

  variants: {
    highlighted: {
      true: {
        backgroundColor: 'rgba(249, 249, 249, 0.05)'
      }
    },
    disabled: {
      true: {
        color: 'grey',
        cursor: 'default',
        '&:hover': {
          backgroundColor: '$transparent',
          color: '$rhyhorn'
        }
      }
    },
    action: {
      true: {
        gridTemplateColumns: '1.6rem 1fr auto'
      }
    },
    isIcon: {
      true: {
        '& span': {
          gridColumn: 2
        }
      },
      false: {
        '& span': {
          gridColumn: '1 / span 2'
        }
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const Icon = styled(BaseIcon, {
  width: '1.6rem'
});

const Action = styled('button', {
  color: '$error500',
  cursor: 'pointer',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
