import { styled } from '@parsec/styles';

export const ErrorMessage = styled('p', {
  color: '$error500',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

export default ErrorMessage;
