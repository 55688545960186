import {
  ComponentPropsWithoutRef,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState
} from 'react';

import { Query } from 'react-query';

import { Alert } from '@parsec/components';
import * as time from '@parsec/time';

const DEFAULT_DURATION = time.seconds(8);

export interface AlertOptions extends ComponentPropsWithoutRef<typeof Alert> {}

interface AlertContext {
  show: (_options: AlertOptions, duration?: number) => void;
  clear: () => void;
  onQueryCacheError: (error: unknown, query: Query) => void;
  setInPlace: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AlertContext = createContext<AlertContext>({} as AlertContext);

type AlertDataContext = {
  alerts: AlertOptions[];
  inPlace: boolean;
};

export const AlertDataContext = createContext<AlertDataContext>(
  {} as AlertDataContext
);

export function AlertProvider(props: { children?: ReactNode }) {
  const [alerts, setAlerts] = useState<AlertOptions[]>([]);
  const [inPlace, setInPlace] = useState(false);

  const alertMethods = useMemo(() => {
    const show = (alert: AlertOptions, duration: number = DEFAULT_DURATION) => {
      setAlerts(alerts => {
        const hasDuplicate = alerts.some(a => a.message == alert.message);
        if (hasDuplicate) {
          return [...alerts];
        } else {
          return [alert, ...alerts];
        }
      });

      setTimeout(() => {
        setAlerts(alerts => alerts.filter(a => a !== alert));
      }, duration);
    };

    const clear = () => {
      setAlerts([]);
    };

    const onQueryCacheError = (error: unknown, query: Query) => {
      const message = query.meta?.errorMessage;
      if (message && error) {
        const alert: AlertOptions = {
          type: 'error',
          title: 'API Error',
          message: query.meta?.errorMessage as string
        };

        show(alert);
      }
    };

    return {
      show,
      clear,
      onQueryCacheError,
      setInPlace
    };
  }, []);

  return (
    <AlertContext.Provider value={alertMethods}>
      <AlertDataContext.Provider value={{ alerts, inPlace }}>
        {props.children}
      </AlertDataContext.Provider>
    </AlertContext.Provider>
  );
}

export function useAlertDataContext() {
  const context = useContext(AlertDataContext);

  if (!context) {
    throw new Error(
      'useAlertDataContext must be used within an AlertDataProvider. Wrap a parent component in <AlertDataContext.Provider> to fix this error.'
    );
  }
  return context;
}

export function useAlertContext() {
  const context = useContext(AlertContext);
  if (!context) {
    throw new Error(
      'useAlert must be used within an AlertProvider. Wrap a parent component in <AlertContext.Provider> to fix this error.'
    );
  }
  return context;
}
