import { GroupPermissions } from '@parsec/kessel';
import { useGetTeamRolePermissionSummary, useGetTeam } from '@parsec/queries';

export function useEnabledPages(): {
  members: boolean;
  invites: boolean;
  groups: {
    members: boolean;
    edit: boolean;
  };
  roles: {
    permissions: boolean;
    edit: boolean;
    admins: boolean;
  };
  guestAccess: boolean;
  computers: boolean;
  relays: boolean;
  security: boolean;
  appRules: boolean;
  apiKeys: boolean;
  subscription: boolean;
  billing: boolean;
  auditLogs: boolean;
  help: boolean;
  isLoading: boolean;
} | null {
  const getTeamQuery = useGetTeam();
  const team = getTeamQuery.data;
  const isTeamActive = team?.is_active ?? false;

  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();

  const isLoading =
    getTeamQuery.isLoading || getAdminPermissionsQuery.isLoading;
  const perms = getAdminPermissionsQuery.data;
  const groupPerms = perms?.groups ?? {};
  const groupPermissions = Object.values(groupPerms);

  const defaultGroupPerms: GroupPermissions = {
    assign_team_members_to_groups: false,
    invite_team_members: false,
    remove_team_members: false,
    reset_team_members_tfa: false,
    manage_team_computers: false
  };

  const flattenedGroupPerms = groupPermissions.reduce((prev, next) => {
    return {
      assign_team_members_to_groups:
        prev.assign_team_members_to_groups ||
        next.assign_team_members_to_groups,
      invite_team_members: prev.invite_team_members || next.invite_team_members,
      remove_team_members: prev.remove_team_members || next.remove_team_members,
      reset_team_members_tfa:
        prev.reset_team_members_tfa || next.reset_team_members_tfa,
      manage_team_computers:
        prev.manage_team_computers || next.manage_team_computers
    };
  }, defaultGroupPerms);

  if (!perms) return null;

  // Members & Invites
  const canInviteTeamMembers =
    perms.team.invite_team_members || flattenedGroupPerms.invite_team_members;
  const canUpdateTeamMembers = perms.team.update_team_members;

  const canUpdateTeamGroupMembership =
    perms.team.assign_team_members_to_groups ||
    flattenedGroupPerms.assign_team_members_to_groups;

  const canRemoveTeamMembers =
    perms.team.remove_team_members || flattenedGroupPerms.remove_team_members;
  // Groups
  const canManageGroups = perms.team.manage_groups;

  const canManageApiKeys =
    perms.team.manage_api_keys || perms.team.regenerate_team_computer_key;

  const canManageAppRules =
    perms.team.assign_team_members_to_app_rules ||
    perms.team.manage_app_rules ||
    perms.team.manage_app_rules_app_config ||
    perms.team.manage_app_rules_security_config ||
    perms.team.manage_default_app_rules_app_config ||
    perms.team.manage_default_app_rules_security_config;

  // Team Machines
  const canManageTeamMachines =
    perms.team.manage_team_computers ||
    flattenedGroupPerms.manage_team_computers;

  // Team Relay
  const relayFeatureFlagged = Boolean(
    team?.capabilities.release_toggles.rt_hpr_v2
  );

  const canManageRelays =
    Boolean(team?.capabilities.features.is_relay_enabled) &&
    perms.team.manage_team_relays;
  const shouldDisplayRelays = relayFeatureFlagged && canManageRelays;

  // Subscription & Billing

  // Reseller Team is a team whose billing and subscription details
  // are managed by a third party and the actual user should
  // not see any details.
  const isResellerTeam =
    !team?.capabilities.features.is_self_serve_enabled &&
    !team?.capabilities.features.is_billing_enabled;

  const canManageBilling =
    !isResellerTeam &&
    perms.team.manage_billing_info &&
    Boolean(team?.capabilities.features.is_billing_enabled);

  const hasManageSubscriptionPermission =
    perms.team.manage_subscription || perms.team.purchase_guest_access_credits;

  const canManageSubscription =
    !isResellerTeam && hasManageSubscriptionPermission;

  return !isTeamActive
    ? {
        members: false,
        invites: false,
        groups: {
          members: false,
          edit: false
        },
        roles: {
          permissions: false,
          edit: false,
          admins: false
        },
        guestAccess: false,
        computers: false,
        relays: false,
        security: false,
        appRules: false,
        apiKeys: false,

        // Reseller teams will be redirected to /inactive-team
        subscription: canManageSubscription,
        billing: canManageBilling,

        auditLogs: false,
        help: false,
        isLoading
      }
    : // Active teams see pages based on the user's permissions
      {
        members:
          canInviteTeamMembers ||
          canUpdateTeamMembers ||
          canRemoveTeamMembers ||
          canUpdateTeamGroupMembership,
        invites: canInviteTeamMembers,
        groups: {
          members:
            canUpdateTeamGroupMembership ||
            canUpdateTeamMembers ||
            groupPermissions.length > 0,
          edit: canManageGroups
        },
        // Super admin only pages
        roles: {
          permissions: perms.team.manage_admin_roles,
          edit: perms.team.manage_admin_roles,
          admins: perms.team.manage_admin_roles
        },
        guestAccess: perms.team.manage_guest_access_invites,
        computers: canManageTeamMachines,
        relays: shouldDisplayRelays,
        security: perms.team.manage_security_settings,
        appRules: canManageAppRules,
        apiKeys: canManageApiKeys,
        subscription: canManageSubscription,
        billing: canManageBilling,
        auditLogs: perms.team.download_audit_log,
        help: true,
        isLoading
      };
}
