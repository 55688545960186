import React, { ReactNode, useCallback, useEffect } from 'react';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { DataLayer, GoogleTagManager } from '@parsec/analytics';
import {
  global,
  injectGlobalStyles,
  setModalAppElement,
  ErrorBoundary,
  IncidentNotificationProvider,
  INCIDENT_CONFIG
} from '@parsec/components';
import { clear, cookie } from '@parsec/cookie';
import { configure } from '@parsec/kessel';
import { QueryProvider } from '@parsec/queries';
import { setAuthRedirect } from '@parsec/redirect';
import { initSentry } from '@parsec/sentry';

import { QueryErrorProvider, AlertProvider } from 'context';
import { Inside } from 'layouts/Inside';

import {
  RELEASE,
  KESSEL_URL,
  DASH_URL,
  STRIPE_KEY,
  ENV,
  SENTRY_DSN,
  DOWNTIME_URL
} from 'lib/config';

import { Alerts } from 'components';

import 'public/fonts/fonts.css';

initSentry({ env: ENV, dsn: SENTRY_DSN, release: RELEASE });

setModalAppElement('#__next');

const setStyles = global({
  '#root': {
    position: 'relative',
    zIndex: 1
  },
  '#modals': {
    position: 'relative',
    zIndex: 2
  },
  '#alerts': {
    position: 'fixed',
    zIndex: 3
  },
  '#popovers': {
    position: 'fixed',
    zIndex: 4
  }
});

const stripePromise = loadStripe(STRIPE_KEY);

export default function Root({ Component, pageProps }: AppPropsWithLayout) {
  injectGlobalStyles();
  setStyles();

  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? defaultGetLayout;
  const router = useRouter();

  useEffect(() => {
    function trackPageview(url: string) {
      window.dataLayer?.push({
        event: 'pageview',
        page: url
      });
    }

    router.events.on('routeChangeComplete', trackPageview);
    return () => {
      router.events.off('routeChangeComplete', trackPageview);
    };
  }, [router.events]);

  const onInvalidSession = useCallback((status: string) => {
    clear();
    setAuthRedirect(window.location.href);
    if (status === 'NO_COOKIE') {
      window.location.assign(`${DASH_URL}/login`);
    } else if (status === '401' || status === '412') {
      window.location.assign(`${DASH_URL}/login/?invalidSession=${status}`);
    } else {
      window.location.assign(`${DASH_URL}/login/?invalidSession=true`);
    }
  }, []);

  return (
    <ErrorBoundary name="root">
      <Elements stripe={stripePromise}>
        <AlertProvider>
          <QueryErrorProvider
            render={({ onQueryCacheError }) => (
              <QueryProvider
                kessel={kessel}
                onInvalidSession={onInvalidSession}
                onQueryCacheError={onQueryCacheError}
              >
                <DataLayer />
                <GoogleTagManager />

                <IncidentNotificationProvider
                  {...INCIDENT_CONFIG}
                  externalDowntimeURL={DOWNTIME_URL}
                >
                  <Head>
                    <title>Parsec for Teams</title>
                  </Head>
                  {getLayout(<Component {...pageProps} />)}

                  <Alerts />
                </IncidentNotificationProvider>
              </QueryProvider>
            )}
          />
        </AlertProvider>
      </Elements>
    </ErrorBoundary>
  );
}

const kessel = configure({
  kessel: KESSEL_URL,
  downtimeURL: DOWNTIME_URL,
  token: () => cookie().token
});

function defaultGetLayout(children: ReactNode) {
  return <Inside>{children}</Inside>;
}
