import { ComponentPropsWithoutRef } from 'react';

import DatePicker from 'react-datepicker';

import { styled } from '@parsec/styles';
import * as time from '@parsec/time';

import Input from '../Input';

export interface DateTimeInputProps
  extends ComponentPropsWithoutRef<typeof DatePicker> {
  className?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange(selected: Date, e?: any): void;
  // Note: explicitly add DatePickerProps so they appear in Storybook docs
  selected: Date;
  timeIntervals: number;
}

export default function DateTimeInput(props: DateTimeInputProps) {
  const { selected, minDate, maxDate, timeIntervals, showTimeInput } = props;
  let { minTime, maxTime, onChange } = props;
  const isTimeInput = !!(timeIntervals || showTimeInput);
  if (isTimeInput && selected && minDate && maxDate) {
    const now = time.now();
    minTime =
      minDate.getDate() === selected.getDate()
        ? time.setTime(now, minDate)
        : time.setTime(now, 0, 0, 0);
    maxTime =
      maxDate.getDate() === selected.getDate()
        ? time.setTime(now, maxDate)
        : time.setTime(now, 23, 59, 0);
  }
  if (minDate && maxDate) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const _onChange: any = onChange;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onChange = (d: Date, e: any) => {
      const date = time.clamp(d, minDate, maxDate);
      _onChange(date, e);
    };
  }

  return (
    <Wrapper>
      <DatePicker
        showPopperArrow={false}
        {...props}
        dateFormat="EEEEEEE, MMMM dd"
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        customInput={
          <Input
            version="newFont"
            size={29}
            css={{
              backgroundColor: '$chandelure',
              boxShadow:
                '0 $space$xxsmall $space$xsmall rgba(0, 0, 0, 0.18), inset 0px $space$xxsmall 0px rgba(255, 255, 255, 0.07)',
              border: 'none',
              textOverflow: 'ellipsis'
            }}
          />
        }
      />
      <TimeWrapper>
        <DatePicker
          showPopperArrow={false}
          showTimeSelect
          showTimeSelectOnly
          dateFormat="h:mm aa"
          {...props}
          minTime={minTime}
          maxTime={maxTime}
          onChange={onChange}
          customInput={
            <Input
              version="newFont"
              size={10}
              css={{
                backgroundColor: '$chandelure',
                boxShadow:
                  '0 $space$xxsmall $space$xsmall rgba(0, 0, 0, 0.18), inset 0px $space$xxsmall 0px rgba(255, 255, 255, 0.07)',
                border: 'none',
                textOverflow: 'ellipsis'
              }}
            />
          }
        />
      </TimeWrapper>
    </Wrapper>
  );
}

const TimeWrapper = styled('div', {
  marginLeft: '1rem'
});

const Wrapper = styled('div', {
  display: 'inline-flex',
  margin: '1rem'
});
