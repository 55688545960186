import { useState } from 'react';

import { styled, Button, Input } from '@parsec/components';

import {
  BUNDLE_DISCOUNT,
  ACCESS_LINK_COST,
  ACCESS_LINK_BUNDLE_COST
} from 'constants/payment';

import { Bread } from './Bread';

export enum AccessLinkPriceType {
  Package = 'package',
  Standard = 'standard'
}

export const AccessLinkIncrement = {
  [AccessLinkPriceType.Package]: 100,
  [AccessLinkPriceType.Standard]: 1
};

interface AccessLinkBillingProps {
  hasCardError: boolean;
  onPurchase(quantity: number, priceType: AccessLinkPriceType): void;
}

export function AccessLinkBilling(props: AccessLinkBillingProps) {
  const { hasCardError = false } = props;
  const { onPurchase } = props;
  const [quantity, setQuantity] = useState(1);
  return (
    <PurchaseOptions>
      <PurchaseOptionWrapper>
        <div>
          <Subtitle>
            {AccessLinkIncrement[AccessLinkPriceType.Package]} Guest Credits
            Bundle
          </Subtitle>
          <Description>
            Purchase in a bundle and get{' '}
            <WowDiscount>{BUNDLE_DISCOUNT * 100}% Off</WowDiscount>
          </Description>
        </div>
        <PurchaseOption>
          <p style={{ fontSize: '1.2rem' }}>
            {AccessLinkIncrement[AccessLinkPriceType.Package]} Guest Credits x{' '}
            <strong>
              <Strike>
                <Bread>{ACCESS_LINK_COST}</Bread>
              </Strike>{' '}
              <Bread>{ACCESS_LINK_BUNDLE_COST}</Bread>
            </strong>
            /credit (billed once)
          </p>
          <Info>Credits never expire.</Info>
          <ActionWrapper>
            <Input
              value={AccessLinkIncrement[AccessLinkPriceType.Package]}
              readOnly
              disabled
            />
            <Label htmlFor="credits_bundle">Credits</Label>
            <Actions>
              <StyledButton
                disabled={hasCardError}
                onClick={() => onPurchase(1, AccessLinkPriceType.Package)}
              >
                Purchase for{' '}
                <Bread decimal={0}>
                  {ACCESS_LINK_BUNDLE_COST *
                    AccessLinkIncrement[AccessLinkPriceType.Package]}
                </Bread>
              </StyledButton>
            </Actions>
          </ActionWrapper>
        </PurchaseOption>
      </PurchaseOptionWrapper>
      <PurchaseOptionWrapper>
        <div>
          <Subtitle>Guest Credits</Subtitle>
          <Description>
            With Guest Credits invite guests to your computers and events.
          </Description>
        </div>
        <PurchaseOption>
          <p style={{ fontSize: '1.2rem' }}>
            1 Guest Credits x{' '}
            <strong>
              <Bread>{ACCESS_LINK_COST}</Bread>
            </strong>
            /credit (billed once)
          </p>
          <Info>Pick your desired amount. Credits never expire.</Info>
          <ActionWrapper>
            <Input
              name="quantity"
              type="numeric"
              value={quantity}
              min={1}
              max={99}
              disabled={hasCardError}
              onFocus={e => e.target.select()}
              onChange={e => {
                if (isNaN(Number(e.target.value))) return;
                const num = Math.max(1, Math.min(Number(e.target.value), 99));
                setQuantity(num);
              }}
            />
            <Label htmlFor="credits_count">Credit(s)</Label>
            <Actions>
              <StyledButton
                disabled={hasCardError}
                onClick={() =>
                  onPurchase(quantity, AccessLinkPriceType.Standard)
                }
              >
                Purchase for{' '}
                <Bread decimal={0}>{ACCESS_LINK_COST * quantity}</Bread>
              </StyledButton>
            </Actions>
          </ActionWrapper>
        </PurchaseOption>
      </PurchaseOptionWrapper>
    </PurchaseOptions>
  );
}

const Subtitle = styled('h4', {
  fontSize: '$header',
  lineHeight: '$header',
  textTransform: 'uppercase'
});

const Description = styled('p', {
  marginBottom: '$xlarge'
});

const Info = styled('p', {
  color: '$rhyhorn',
  margin: '0',
  fontSize: '1.2rem'
});

const FormWrapper = styled('div', {
  backgroundColor: '$carkol',
  borderRadius: '$medium'
});

const Actions = styled('div', {
  stack: {
    gap: '$xxlarge',
    hdistribute: 'start',
    valign: 'center'
  },
  marginLeft: 'auto'
});

const StyledButton = styled(Button, {
  marginLeft: '1rem'
});

const PurchaseOptions = styled('div', {
  display: 'grid',
  gap: '2%',
  gridAutoFlow: 'column',
  gridTemplateColumns: '49% 49%'
});

const PurchaseOptionWrapper = styled(FormWrapper, {
  flexBasis: '0%',
  flexGrow: 1,
  padding: '$xxlarge',
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column'
});

const PurchaseOption = styled('div', {
  padding: `$medium 0 0`,
  boxShadow: 'inset 0 0.1rem 0 $colors$zekrom'
});

const Strike = styled('span', {
  textDecoration: 'line-through'
});

const ActionWrapper = styled('div', {
  display: 'flex',
  padding: '$large 0 0'
});

const Label = styled('label', {
  marginLeft: '$large',
  fontSize: '$body',
  alignSelf: 'center'
});

const WowDiscount = styled('span', {
  backgroundColor: '$colors$computerBlack',
  borderRadius: '0.8rem',
  fontWeight: 'bold',
  padding: '0 0.8rem'
});
