import { GroupPermissions, TeamGroup, TeamPermissions } from '@parsec/kessel';
import {
  useGetTeamGroups,
  useGetTeamRolePermissionSummary
} from '@parsec/queries';

type TeamPermissionsKey = keyof TeamPermissions;
type GroupPermissionsKey = keyof GroupPermissions;

interface AllGroupPermissions {
  [key: string]: GroupPermissions;
}

// Returns groups where any of the given group scoped permissions are true
export function useGetGroupsFromAnyGroupPermissions(
  permissions: GroupPermissionsKey[],
  groupPerms?: AllGroupPermissions
) {
  const getAllGroupsQuery = useGetTeamGroups();
  if (!groupPerms) return [];

  const allGroups: TeamGroup[] = getAllGroupsQuery.data?.data ?? [];
  const groupsMap = new Map(allGroups.map(group => [group.id, group]));
  const groupIds = Object.keys(groupPerms);

  const filteredGroupIds = groupIds.filter(id =>
    permissions.some(perm => {
      return groupPerms[id][perm];
    })
  );

  const groups = filteredGroupIds
    .map((id: string | number) => groupsMap.get(Number(id)))
    .filter(Boolean) as TeamGroup[];
  if (!groupPerms) return [];
  return groups;
}

// For given permissions, which groups can this admin act on?
// If the admin has permissions for any of the perms in the array, return all groups.
export function useGetGroupsFromTeamPermissions(
  permissions: TeamPermissionsKey[],
  teamPerms?: TeamPermissions
) {
  const getAllGroupsQuery = useGetTeamGroups();
  const allGroups: TeamGroup[] = getAllGroupsQuery.data?.data ?? [];
  if (!teamPerms) return [];
  if (permissions.some(perm => teamPerms[perm])) {
    return allGroups;
  } else {
    return [];
  }
}

export function useGetGroupsFromPerms(
  permissions: (GroupPermissionsKey | TeamPermissionsKey)[]
) {
  const permsQuery = useGetTeamRolePermissionSummary();
  const globalPermKeys = permissions.filter(isGlobalPermKey);

  const groupsFromGlobalPerm = useGetGroupsFromTeamPermissions(
    globalPermKeys,
    permsQuery.data?.team
  );

  const groupPermKeys = permissions.filter(isGroupPermKey);

  const groupsFromGroupPerm = useGetGroupsFromAnyGroupPermissions(
    groupPermKeys,
    permsQuery.data?.groups
  );

  const groupsSet = new Set([...groupsFromGlobalPerm, ...groupsFromGroupPerm]);
  const filteredGroups = Array.from(groupsSet);
  return filteredGroups;
}

function isGlobalPermKey(
  perm: GroupPermissionsKey | TeamPermissionsKey
): perm is TeamPermissionsKey {
  return [
    'assign_team_members_to_groups',
    'download_audit_log',
    'download_guest_access_ledger',
    'invite_team_members',
    'manage_api_keys',
    'manage_admin_roles',
    'manage_billing_info',
    'manage_groups',
    'purchase_guest_access_credits',
    'manage_guest_access_invites',
    'manage_security_settings',
    'manage_subscription',
    'manage_team_computers',
    'regenerate_team_computer_key',
    'remove_team_members',
    'reset_team_members_tfa',
    'update_team_info',
    'update_team_members',
    'assign_team_members_to_app_rules',
    'manage_app_rules_app_config',
    'manage_default_app_rules_app_config',
    'manage_default_app_rules_security_config',
    'manage_app_rules_security_config',
    'manage_app_rules'
  ].includes(perm);
}

function isGroupPermKey(
  perm: GroupPermissionsKey | TeamPermissionsKey
): perm is GroupPermissionsKey {
  return [
    'assign_team_members_to_groups',
    'invite_team_members',
    'remove_team_members',
    'reset_team_members_tfa',
    'manage_team_computers'
  ].includes(perm);
}

/**
 * For example, TeamMemberList needs to display members if an admin has permissions for the following: reset_members_tfa or remove_team_members.
 */
