import { type ReactNode, useState } from 'react';

import * as Collapsible from '@radix-ui/react-collapsible';

import { styled, keyframes } from '@parsec/styles';

import Button from '../Button';
import Icon from '../Icon';

const slideDown = keyframes({
  from: {
    height: 0
  },
  to: {
    height: `var(--radix-collapsible-content-height)`
  }
});

const slideUp = keyframes({
  from: {
    height: `var(--radix-collapsible-content-height)`
  },
  to: {
    height: 0
  }
});

const CollapsibleRoot = styled(Collapsible.Root, {
  width: '90.8rem'
});

const CollapsibleContent = styled(Collapsible.Content, {
  justifySelf: 'center',
  overflow: 'hidden',
  "&[data-state='open']": {
    animation: `${slideDown} 300ms ease-out`
  },
  "&[data-state='closed']": {
    animation: `${slideUp} 300ms ease-out`
  }
});

const Flex = styled('div', {
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  backgroundColor: '$cereza',
  borderRight: '$space$xxsmall solid $samehada',
  borderBottom: '$space$xxsmall solid $samehada',
  borderLeft: '$space$xxsmall solid $samehada',
  borderRadius: '0 0 $large $large',
  variants: {
    open: {
      true: {
        padding: '$large $xxlarge'
      },
      false: {
        padding: '$xxlarge'
      }
    }
  }
});

const ContentWrapper = styled('div', {
  padding: '1.8rem $xxlarge 0',
  backgroundColor: '$cereza',
  borderRight: '$space$xxsmall solid $samehada',
  borderLeft: '$space$xxsmall solid $samehada',
  maxWidth: '90.8rem'
});

const CollapseButton = styled(Button, {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  padding: '0 3.2rem'
});

export interface PlanComparisonProps {
  children: ReactNode;
  defaultOpen?: boolean;
  onOpenChange?(open: boolean): void;
  onTriggerClick?(): void;
}

export function PlanComparisonCollapsible({
  children,
  defaultOpen = false,
  onOpenChange,
  onTriggerClick
}: PlanComparisonProps) {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const handleOpenChange = (open: boolean) => {
    onOpenChange?.(open);
    setIsOpen(open);
  };

  return (
    <CollapsibleRoot open={isOpen} onOpenChange={handleOpenChange}>
      <CollapsibleContent>
        <ContentWrapper>{children}</ContentWrapper>
      </CollapsibleContent>
      <Flex open={isOpen}>
        <Collapsible.Trigger asChild>
          <CollapseButton
            kind={isOpen ? undefined : 'primary'}
            level={isOpen ? 'secondary' : 'primary'}
            icon={<Icon name={isOpen ? 'closedEye' : 'eye'} />}
            iconPosition="left"
            onClick={onTriggerClick}
          >
            {isOpen ? 'Hide Plans' : 'View Plans'}
          </CollapseButton>
        </Collapsible.Trigger>
      </Flex>
    </CollapsibleRoot>
  );
}
