import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import { default as BaseIconButton } from '../IconButton';

// We insert a right-facing chevron icon between breadcrumb items.
// This icon needs to be base64-encoded so that we can render it
// from within css sibling selectors instead of from the javascript.
const chevronRight =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSIxMCIgdmlld0JveD0iMCAwIDYgMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xIDFMNSA1TDEgOSIgc3Ryb2tlPSIjN0E3QTdBIiBzdHJva2Utd2lkdGg9IjIiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8L3N2Zz4K';

export interface BreadcrumbProps {
  children: ReactNode;
}
export function Breadcrumb(props: BreadcrumbProps) {
  return <Container>{props.children}</Container>;
}

interface BreadcrumbHeaderProps {
  disabled: boolean;
  onClickBack: () => void;
  children: ReactNode;
}

function BreadcrumbHeader(props: BreadcrumbHeaderProps) {
  return (
    <HeaderTextContainer>
      <BackIconButton
        icon="arrowLeft"
        title="back"
        onClick={props.onClickBack}
        disabled={props.disabled}
      />
      <BreadcrumbHeaderText>{props.children}</BreadcrumbHeaderText>
    </HeaderTextContainer>
  );
}

interface BreadcrumbItemsProps {
  children: ReactNode;
}

function BreadcrumbItems(props: BreadcrumbItemsProps) {
  return <BreadcrumbOrderedList>{props.children}</BreadcrumbOrderedList>;
}

interface BreadcrumbItemProps {
  isActive?: boolean;
  children: ReactNode;
}

function BreadcrumbItem(props: BreadcrumbItemProps) {
  return (
    <BreadcrumbListItem
      isActive={props.isActive ?? false}
      aria-current={props.isActive ? 'step' : 'false'}
    >
      {props.children}
    </BreadcrumbListItem>
  );
}

Breadcrumb.Header = BreadcrumbHeader;
Breadcrumb.Items = BreadcrumbItems;
Breadcrumb.Item = BreadcrumbItem;

export default Breadcrumb;

const Container = styled('div', {
  width: '100%'
});

const HeaderTextContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  columnGap: '$xlarge',
  paddingBottom: '$xlarge',
  paddingLeft: '10rem'
});

const BreadcrumbHeaderText = styled('h2', {
  fontFamily: '$newDefault',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  textTransform: 'capitalize'
});

const BackIconButton = styled(BaseIconButton, {
  color: '$primary500',
  backgroundColor: '$chandelure',
  height: '3.6rem'
});

const BreadcrumbOrderedList = styled('ol', {
  display: 'flex',
  width: '100%',
  padding: '$medium 0',
  backgroundColor: '$samehada',
  paddingLeft: '10rem'
});

const BreadcrumbListItem = styled('li', {
  display: 'flex',
  alignItems: 'center',
  color: '$rhyhorn',
  fontWeight: '$bold',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  variants: {
    isActive: {
      true: { color: '$consoleWhite' }
    }
  },
  // Inserts the chevron separator between each breadcrumb item.
  '& + &::before': {
    content: `url(${chevronRight})`,
    margin: '0 $large'
  }
});
