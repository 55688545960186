import { useCallback } from 'react';

import { styled } from '@parsec/components';
import { Machine } from '@parsec/kessel';
import {
  useAssignAppRule,
  useGetAllAppRules,
  useDeleteTeamComputer,
  useUnassignTeamComputer,
  useUpdateTeamComputer
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { useSelectionState } from 'lib/hooks';

import { ListMessage } from './ListMessage';
import { TableBuilder } from './TableBuilder';
import {
  TeamComputerRow,
  TeamComputerTableColumnType
} from './TeamComputerRow';

const COMPUTERS_TABLE_PRESET: {
  [key in TeamComputerTableColumnType]: string;
} = {
  select: 'minmax(0, 2rem)',
  computerAndStatus: 'minmax(0, 3fr)',
  guestAccess: 'minmax(0, 1fr)',
  assignee: 'minmax(0, 3fr)',
  connections: 'minmax(0, 1fr)',
  ruleset: 'minmax(0, 2fr)',
  assign: 'minmax(0, 1.3fr)',
  more: 'minmax(0, 4rem)'
};

function computersPresetFromColumns(cols: Array<TeamComputerTableColumnType>) {
  return Object.fromEntries(
    cols.map(col => [col, COMPUTERS_TABLE_PRESET[col]])
  );
}

interface TeamComputerTableProps {
  selectionState?: ReturnType<typeof useSelectionState>;
  columns: TeamComputerTableColumnType[];
  computers: Machine[];
  viewOnly?: boolean;
  isLoading?: boolean;
  filterName?: string;
  onOpenEditModal?(computer: Machine): void;
  onResetSearch?(): void;
  version?: 'newFont';
}

export function TeamComputerTable(props: TeamComputerTableProps) {
  const {
    isLoading,
    filterName,
    columns,
    computers,
    selectionState,
    viewOnly = false,
    onOpenEditModal,
    onResetSearch,
    version
  } = props;
  const alert = useAlertContext();

  const { mutateAsync: onComputerUpdate } = useUpdateTeamComputer();
  const { mutateAsync: onComputerDelete } = useDeleteTeamComputer();
  const { mutateAsync: onComputerUnassign } = useUnassignTeamComputer();
  const { mutateAsync: assignAppRule } = useAssignAppRule();

  const allAppRulesQuery = useGetAllAppRules();
  const allAppRules = allAppRulesQuery.data?.data ?? [];

  const onFailure = useCallback(
    (err: unknown, fallbackMsg: string) => {
      const res = parseError(err);
      alert.show({
        type: 'error',
        title: fallbackMsg,
        message: res.body?.error ?? res.error,
        version: version
      });
    },
    [alert, version]
  );

  const onSuccess = useCallback(
    (msg: string) =>
      alert.show({
        type: 'success',
        title: 'Success',
        message: msg,
        version: version
      }),
    [alert, version]
  );

  if (isLoading) return <ListMessage version={version}>Loading...</ListMessage>;
  return (
    <TableBuilder
      version={version}
      preset={computersPresetFromColumns(columns)}
      emptyState={
        <ListMessage version={version}>
          {!!filterName ? `No Results in ${filterName}` : 'No Results'}
          <br />
          {!!onResetSearch && !!filterName && (
            <ResetSearchButton onClick={onResetSearch} version={version}>
              Reset your search
            </ResetSearchButton>
          )}
        </ListMessage>
      }
    >
      {computers.map(computer => {
        const groups = computer.team_groups ?? [];

        const assigneeType = computer.user
          ? 'user'
          : groups.length
            ? 'group'
            : computer.res_email
              ? 'reserved'
              : 'none';

        const groupName =
          groups.length === 1 ? groups[0].name : `${groups.length} Groups`;
        const assigneeName = computer.user?.email ?? groupName ?? '';

        const selected = selectionState?.isSelected(computer.id);
        return (
          <TeamComputerRow
            version={version}
            key={computer.peer_id}
            id={computer.id}
            columns={columns}
            viewOnly={viewOnly}
            selected={selected}
            onSelect={() => selectionState?.selectOne(computer.id)}
            onDeselect={() => selectionState?.deselectOne(computer.id)}
            name={computer.name}
            isOnline={computer.is_online}
            isGuestAccessEnabled={computer.is_guest_access}
            assigneeType={assigneeType}
            assigneeName={assigneeName}
            groups={computer.team_groups}
            reservationEmail={computer.res_email}
            guests={computer.guests}
            appRuleName={computer.team_app_rule.name}
            allAppRules={allAppRules}
            onAssignAppRule={async (ruleId: string) => {
              try {
                await assignAppRule({
                  app_rule_id: ruleId,
                  team_machine_ids: [computer.id]
                });
                onSuccess('ruleset assignment updated.');
              } catch (err) {
                onFailure(err, 'Could not update ruleset assignment.');
              }
            }}
            onToggleGuestAccess={async () => {
              alert.clear();
              try {
                await onComputerUpdate({
                  machine_id: computer.id,
                  is_guest_access: !computer.is_guest_access
                });
                onSuccess('Successfully updated guest access setting.');
              } catch (err) {
                onFailure(err, 'Could not change guest access setting');
              }
            }}
            onEdit={() => onOpenEditModal?.(computer)}
            onRemove={async () => {
              try {
                await onComputerDelete({
                  machine_id: computer.id
                });
                onSuccess('Successfully deleted computer.');
              } catch (err) {
                onFailure(err, 'Could not delete computer.');
              }
            }}
            onUnassign={async () => {
              try {
                await onComputerUnassign({
                  machine_id: computer.id
                });
                onSuccess('Successfully unassigned computer.');
              } catch (err) {
                onFailure(err, 'Could not unassign computer.');
              }
            }}
          />
        );
      })}
    </TableBuilder>
  );
}

const ResetSearchButton = styled('button', {
  cursor: 'pointer',
  color: '$primary500',
  transition: 'all 125ms ease',
  '&focus, &:hover': {
    color: '$primary400'
  },
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
