// @parsec
import { Card } from '@parsec/kessel';
import { styled } from '@parsec/styles';

export interface BillingCardPreviewProps {
  className?: string;
  card: Card;
  name?: string;
  version?: 'newFont';
}

function BillingCardPreview(props: BillingCardPreviewProps) {
  const { className, card, name, version } = props;

  return (
    <Wrapper withName={!!name} className={className}>
      {name ? (
        <>
          <CardLabel bottom css={{ gridArea: 'nameHeader' }} version={version}>
            Name
          </CardLabel>
          <CardInfo css={{ gridArea: 'nameValue' }} version={version}>
            {name}
          </CardInfo>
        </>
      ) : null}

      <CardLabel bottom css={{ gridArea: 'cardHeader' }} version={version}>
        Card Number
      </CardLabel>
      <CardInfo css={{ gridArea: 'cardValue' }} version={version}>
        •••• •••• •••• {card.last4}
      </CardInfo>

      <CardLabel bottom css={{ gridArea: 'expiryHeader' }} version={version}>
        Expiration
      </CardLabel>
      <CardInfo css={{ gridArea: 'expiryValue' }} version={version}>
        {card.expiry_month}/{card.expiry_year}
      </CardInfo>

      <CardLabel
        bottom
        right
        css={{ gridArea: 'postalHeader' }}
        version={version}
      >
        Zip Code
      </CardLabel>
      <CardInfo
        right
        css={{
          gridArea: 'postalValue'
        }}
        version={version}
      >
        {card.zip}
      </CardInfo>
    </Wrapper>
  );
}

export default BillingCardPreview;

const Wrapper = styled('dl', {
  display: 'grid',
  gridAutoFlow: 'column',
  height: 'min-content',
  width: '100%',
  variants: {
    withName: {
      true: {
        gridTemplateColumns: '3fr 3fr 2fr 1fr',
        gridTemplateAreas: `"nameHeader cardHeader expiryHeader postalHeader" "nameValue cardValue expiryValue postalValue"`
      },
      false: {
        gridTemplateColumns: '6fr 2fr 1fr',
        gridTemplateAreas: `"cardHeader expiryHeader postalHeader" "cardValue expiryValue postalValue"`
      }
    }
  }
});

const CardLabel = styled('dt', {
  color: '$rhyhorn',
  fontSize: '$info',
  variants: {
    bottom: {
      true: {
        marginTop: '$large'
      }
    },
    right: {
      true: {
        textAlign: 'right'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const CardInfo = styled('dd', {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    },
    right: {
      true: {
        textAlign: 'right'
      }
    }
  }
});
