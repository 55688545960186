import { Button, IconButton, styled, Tooltip } from '@parsec/components';
import { useGetTeamRolePermissionSummary } from '@parsec/queries';

import GuestCreditCoin from 'lib/images/guest-credit-coin.svg';

const version = 'newFont';

export interface AccessCreditBalanceProps {
  balance: number;
  showHelp?: boolean;
  showButton?: boolean;
  onShowHelp?(): void;
  onClick?(): void;
}

export function AccessCreditBalance(props: AccessCreditBalanceProps) {
  const {
    balance,
    showHelp = false,
    showButton = false,
    onShowHelp = () => {},
    onClick = () => {}
  } = props;
  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data?.team;

  const canPurchaseGuestAccessCredits =
    perms?.purchase_guest_access_credits ?? false;

  const tooltipText = !canPurchaseGuestAccessCredits
    ? 'You do not have permission to purchase Guest Access Credits'
    : '';
  return (
    <Wrapper>
      <CoinIcon />
      <CreditsBalance>
        Balance{' '}
        <CreditsBalanceBold>
          {balance < 0 ? '...' : balance} Guest Credit{balance !== 1 && 's'}
        </CreditsBalanceBold>
      </CreditsBalance>
      {showHelp && (
        <StyledHelp
          icon="help"
          title="info"
          onClick={onShowHelp}
          css={{ color: '$rhyhorn' }}
        />
      )}
      {showButton && (
        <StyledButton
          version={version}
          disabled={!canPurchaseGuestAccessCredits}
          onClick={onClick}
        >
          <Tooltip placement="top" tooltipText={tooltipText} version={version}>
            Purchase Guest Credits
          </Tooltip>
        </StyledButton>
      )}
    </Wrapper>
  );
}

const StyledButton = styled(Button, {
  marginLeft: 'auto'
});

const Wrapper = styled('div', {
  display: 'flex',
  padding: '0 $xxlarge',
  backgroundColor: '$colors$computerBlack',
  color: '$colors$consoleWhite',
  borderRadius: '0.8rem'
});

const CoinIcon = styled(GuestCreditCoin, {
  width: '3.2rem',
  height: '3.2rem',
  color: '$warning500',
  margin: 'auto 0'
});

const CreditsBalanceBold = styled('strong', {
  marginLeft: '1rem'
});

const CreditsBalance = styled('h3', {
  display: 'flex',
  alignItems: 'center',
  fontWeight: 'normal',
  fontFamily: '$newDefault',
  fontSize: '$attribution',
  marginLeft: '$xlarge'
});

const StyledHelp = styled(IconButton, {
  margin: 'auto 0',
  color: '$rhyhorn',
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent !important'
  }
});
