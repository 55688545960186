import { ReactNode } from 'react';

import { styled } from '@parsec/components';

export interface ListMessageProps {
  children?: ReactNode;
  version?: 'newFont';
}

export function ListMessage(props: ListMessageProps) {
  return (
    <ListMessageWrapper>
      <ListMessageP version={props.version}>{props.children}</ListMessageP>
    </ListMessageWrapper>
  );
}

const ListMessageWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '52rem',
  paddingBottom: '5rem'
});

const ListMessageP = styled('p', {
  textAlign: 'center',
  padding: '4rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
