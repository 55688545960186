import {
  FormEvent,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';

import { useRouter } from 'next/router';

import {
  Avatar,
  Button,
  Checkbox,
  Dropdown,
  FieldLabel,
  Flyout,
  Icon,
  IconButton,
  Input,
  MultiSelect,
  MultiSelectItem,
  Tooltip,
  styled
} from '@parsec/components';
import { formValues } from '@parsec/form';
import { useModals } from '@parsec/hooks';
import { AppRule, TeamGroup, TeamMember, TeamRole } from '@parsec/kessel';
import { useGetTeamRolePermissionSummary } from '@parsec/queries';

import { ConfirmModal } from './ConfirmModal';

const MAX_CHAR = 12;

// STYLES
const Info = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: '1fr 1fr',
  columnGap: '$large',
  overflow: 'hidden'
});

const InfoRow = styled('div', {
  alignContent: 'stretch',
  alignItems: 'center',
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '$medium',
  justifyContent: 'start',
  justifyItems: 'stretch',
  overflow: 'hidden',

  '& div': {
    overflow: 'hidden'
  }
});

const EmailRow = styled(InfoRow, {
  gridGap: '$small'
});

const StyledAvatar = styled(Avatar, {
  gridRow: '1 / 3',
  width: '3.6rem',
  height: '3.6rem'
});

const Name = styled('div', {
  alignContent: 'stretch',
  alignItems: 'stretch',
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '$none',
  justifyContent: 'start',
  justifyItems: 'stretch',
  fontSize: '$info',
  lineHeight: '$info',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  variants: {
    cursor: {
      true: {
        cursor: 'default'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const Id = styled('span', {
  color: '$rhyhorn'
});

const Subtext = styled('div', {
  position: 'relative',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',

  '&:not(:first-child)': {
    paddingLeft: '$medium',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      width: '0.1rem',
      height: '100%',
      backgroundColor: '$pancham'
    }
  },
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.1rem'
      }
    }
  }
});

const Badge = styled('div', {
  alignContent: 'stretch',
  alignItems: 'center',
  display: 'grid',
  gridAutoFlow: 'column',
  gridGap: '$small',
  justifyContent: 'stretch',
  justifyItems: 'stretch',
  fontSize: '$info',
  lineHeight: '$info',
  fontWeight: 'bold',
  backgroundColor: '$pangoro',
  borderRadius: '$small',
  padding: '0 $small',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.1rem'
      }
    }
  }
});

const LinkButton = styled(Button, {
  fontWeight: 'normal',
  variants: {
    isHidden: {
      true: { display: 'none' }
    }
  }
});

const StyledButton = styled(Button, {
  justifyContent: 'space-between',
  textAlign: 'left',
  width: '100%',
  backgroundColor: '$carkol',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const RolesBtn = styled(Button, {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '1.1rem'
      }
    }
  }
});

const RuleName = styled('span', {
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: 'block'
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const IconsWrapper = styled('div', {
  display: 'flex',
  gap: '$xsmall'
});

const ShieldIcon = styled(Icon, {
  color: '$success500',
  height: '1.2rem'
});

const ScimIcon = styled(Icon, {
  color: '$primary500',
  height: '1.2rem'
});

const User = styled('p', {
  fontWeight: 'bold',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
});

const ColumnWrapper = styled('div', {
  maxWidth: '14rem',
  justifyContent: 'space-between',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  gap: '$large'
});

const ViewOnlyItem = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '$large',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const ViewLink = styled('span', {
  color: '$primary500'
});

const Span = styled('span', {
  maxWidth: '16.8rem',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  display: 'block'
});

const StyledP = styled('p', {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

// COMPONENTS
export type TeamMemberTableColumnType =
  | 'select'
  | 'nameAndEmail'
  | 'roles'
  | 'group'
  | 'ruleset'
  | 'more';

interface TeamMemberRowProps {
  selected?: boolean;
  onSelect(): void;
  onDeselect(): void;
  columns: TeamMemberTableColumnType[];
  viewOnly: boolean;
  member: TeamMember;
  isSelf: boolean;

  isScimTeam: boolean;
  // If true - allows the caller to select multiple Scim Members
  // Temporary work around to enable multi-select certain pages (app rule)
  // while leaving other pages (members) alone.
  canSelectScimMember: boolean;

  groups: TeamGroup[];
  appRules: AppRule[];
  memberRoles: TeamRole[];
  onSetTag(tag: string): void;
  onRemove(): void;
  onResetTfa(): void;
  onResendConfirmationEmail(): void;
  onAddToGroups?(groupIds: number[] | null): void;
  onAssignAppRule?(appRuleId: string): void;
  version?: 'newFont';
}

function useGetGroupPerms(groupId: number) {
  const perms = useGetTeamRolePermissionSummary();

  const teamPerms = perms.data?.team;
  const groupPerms = perms.data?.groups;

  const canResendConfirmationEmail = Boolean(
    teamPerms?.resend_team_members_confirmation_email
  );

  const canAssignTeamMembersToAppRule = Boolean(
    teamPerms?.assign_team_members_to_app_rules
  );
  const canUpdateTeamMembers = Boolean(teamPerms?.update_team_members);

  const hasTeamUpdateGroupMembership = Boolean(
    teamPerms?.assign_team_members_to_groups
  );

  const currentGroupPerms = groupPerms?.[groupId];

  const canRemoveTeamMembers = Boolean(
    teamPerms?.remove_team_members || currentGroupPerms?.remove_team_members
  );
  const canResetTFA = Boolean(currentGroupPerms?.reset_team_members_tfa);

  const hasAnyUpdateGroupMembershipPerm = Boolean(
    hasTeamUpdateGroupMembership ||
      Object.keys(groupPerms ?? {}).some(
        groupId => groupPerms?.[groupId]?.assign_team_members_to_groups
      )
  );

  return {
    groupPerms,
    canAssignTeamMembersToAppRule,
    canUpdateTeamMembers,
    canRemoveTeamMembers,
    canResetTFA,
    hasTeamUpdateGroupMembership,
    hasAnyUpdateGroupMembershipPerm,
    canResendConfirmationEmail
  };
}

export function TeamMemberRow(props: TeamMemberRowProps) {
  const {
    selected = false,
    onSelect,
    onDeselect,
    viewOnly,
    groups,
    appRules,
    isSelf,
    memberRoles,
    member,
    columns,
    isScimTeam,
    version
  } = props;

  const {
    user,
    user_id: userId,
    is_saml: isSaml,
    is_active: isActive,
    tag,
    last_connected_at: lastConnectedAt,
    tfa_enabled: tfaEnabled,
    team_group_ids: memberGroupIds
  } = member;
  const isScimMember = isScimTeam && member.is_scim;
  const canSelectMember = !isScimMember || props.canSelectScimMember;

  const { name, email, is_confirmed: isConfirmedMember } = user;
  const { onAddToGroups = () => {}, onAssignAppRule = () => {} } = props;
  const router = useRouter();

  // Groups
  const groupId = Number(router.query?.groupId ?? '0');
  const membersGroups = new Set(memberGroupIds ?? []);
  const teamHasNoGroups = !groups?.length;

  // RBAC
  const perms = useGetGroupPerms(groupId);

  // App Rules
  const appRuleNameRef = useRef<HTMLSpanElement>(null);
  const ruleNameBtnRef = useRef<HTMLSpanElement>(null);
  const appRuleName = member.team_app_rule.name;

  // Columns
  const showSelect = columns.includes('select');
  const showGroup = columns.includes('group');
  const showRoles = columns.includes('roles');
  const showAppRule = columns.includes('ruleset');
  const showMore = columns.includes('more');

  const isGroupDisabled = !perms.hasAnyUpdateGroupMembershipPerm;

  const onUpdateGroups = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (_: any, selection: MultiSelectItem<number>[]) => {
      const groupIds = selection.map(item => item.value as number);
      const update = groupIds.length > 0 ? groupIds : null;
      onAddToGroups(update);
    },
    [onAddToGroups]
  );

  const onRemoveFromGroup = useCallback(() => {
    // Disallow if member belongs to no group already, or if group isn't specified in route
    const numberGroupId = Number(groupId);
    if (!memberGroupIds || !numberGroupId) return;
    onAddToGroups(memberGroupIds.filter(gId => gId !== numberGroupId));
  }, [onAddToGroups, memberGroupIds, groupId]);

  return (
    <>
      <Column gridArea="select" show={showSelect}>
        <Tooltip
          placement="top"
          version={version}
          tooltipText={
            !canSelectMember ? (
              <>
                You cannot make this selection. This user is being managed
                <br />
                externally with SCIM.
              </>
            ) : (
              ''
            )
          }
        >
          <Checkbox
            readOnly
            disabled={!canSelectMember}
            checked={selected}
            kind="solid"
            onChange={() => {
              if (!canSelectMember) return;
              if (selected) {
                onDeselect();
              } else {
                onSelect();
              }
            }}
          />
        </Tooltip>
      </Column>

      <Column gridArea="nameAndEmail" show={true}>
        <MemberBasicInfo
          version={version}
          name={name}
          userId={userId}
          lastConnectedAt={lastConnectedAt}
          tag={tag}
          isSaml={isSaml}
          isActive={isActive}
          email={email}
          isScimMember={isScimMember}
          isConfirmedMember={
            perms.canResendConfirmationEmail ? isConfirmedMember : false
          }
        />
      </Column>

      <Column gridArea="roles" show={showRoles}>
        <ViewOnlyWrapper
          version={version}
          viewOnly={viewOnly}
          preview={
            <>
              <span>{memberRoles.length} Roles</span>
              {memberRoles.length > 0 && (
                <Tooltip
                  placement="bottom"
                  version={version}
                  tooltipText={
                    <ul>
                      {memberRoles.map(r => (
                        <span key={r.name}>{r.name}</span>
                      ))}
                    </ul>
                  }
                >
                  <ViewLink>View</ViewLink>
                </Tooltip>
              )}
            </>
          }
        >
          {memberRoles.length > 1 ? (
            <Flyout
              version={version}
              items={memberRoles.map(role => ({
                text: role.name,
                onSelect: () => {
                  router.push(`/roles/${role.id}/admins`);
                }
              }))}
            >
              {({ props, isOpen: _ }) => {
                return (
                  <RolesBtn
                    version={version}
                    {...props}
                    level="secondary"
                    size="small"
                  >
                    {memberRoles.length === 1
                      ? memberRoles[0].name
                      : memberRoles.length === 0
                        ? 'No Roles'
                        : `${memberRoles.length} Roles`}
                  </RolesBtn>
                );
              }}
            </Flyout>
          ) : (
            <RolesBtn
              version={version}
              style={{
                display: !memberRoles.length ? 'none' : ''
              }}
              level="secondary"
              size="small"
              onClick={() => {
                router.push(`/roles/${memberRoles[0].id}/admins`);
              }}
            >
              {/* button hidden above if there are no roles */}
              {memberRoles.length === 0 ? '' : memberRoles[0].name}
            </RolesBtn>
          )}
        </ViewOnlyWrapper>
      </Column>

      <Column gridArea="group" show={showGroup}>
        <MultiSelect
          version={version}
          onSelect={onUpdateGroups}
          onDeselect={onUpdateGroups}
          showActions={false}
          showSearchbar={groups.length >= 8}
          // Show groups in either category:
          // (1) Admin has assign or remove permission for the group
          // (2) Member belongs to the group
          items={groups
            .filter(group => {
              if (perms.hasTeamUpdateGroupMembership) return true;
              return (
                membersGroups.has(group.id) ||
                perms.groupPerms?.[group.id]?.assign_team_members_to_groups
              );
            })
            .map(group => {
              // SCIM members may be added to non-SCIM groups from Parsec.
              // SCIM members may only be added to SCIM groups from the IdP.
              // Non-SCIM members may be added to both SCIM and non-SCIM groups from Parsec.
              const canUpdate =
                member.is_scim && group.is_scim
                  ? false
                  : perms.hasTeamUpdateGroupMembership ||
                    Boolean(
                      perms.groupPerms?.[group.id]
                        ?.assign_team_members_to_groups
                    );
              return {
                text: group.name,
                value: group.id,
                disabled: !canUpdate,
                defaultSelected: membersGroups.has(group.id)
              };
            })}
        >
          {({ isOpen: _, props }) => (
            <ColumnWrapper>
              <StyledP version={version}>
                {groupsCount(memberGroupIds ?? [])}
              </StyledP>
              <LinkButton
                {...props}
                version={version}
                disabled={isGroupDisabled || teamHasNoGroups}
                level="link"
                kind="primary"
              >
                Edit
              </LinkButton>
            </ColumnWrapper>
          )}
        </MultiSelect>
      </Column>

      <Column gridArea="ruleset" show={showAppRule}>
        <ViewOnlyWrapper
          version={version}
          preview={
            <Tooltip
              placement="bottom"
              triggerRef={appRuleNameRef}
              showOnOverflow={true}
              tooltipText={appRuleName}
              version={version}
            >
              <Span ref={appRuleNameRef}>{appRuleName}</Span>
            </Tooltip>
          }
          viewOnly={viewOnly}
        >
          <Dropdown
            items={appRules.map(appRule => ({
              text: appRule.name,
              onSelect: () => {
                if (perms.canAssignTeamMembersToAppRule)
                  onAssignAppRule(appRule.id);
              }
            }))}
            placeholder="Find a Ruleset"
            showSearchBar={true}
            defaultValue={appRuleName}
            version={version}
          >
            {({ isOpen, props }) => (
              <Tooltip
                placement="top"
                triggerRef={ruleNameBtnRef}
                showOnOverflow
                tooltipText={appRuleName}
                version={version}
              >
                <StyledButton
                  version={version}
                  {...props}
                  level="secondary"
                  disabled={!perms.canAssignTeamMembersToAppRule || viewOnly}
                  size="small"
                  icon={<ArrowIcon name="caret" open={isOpen} />}
                >
                  <RuleName ref={ruleNameBtnRef}>{appRuleName}</RuleName>
                </StyledButton>
              </Tooltip>
            )}
          </Dropdown>
        </ViewOnlyWrapper>
      </Column>

      <Column gridArea="more" show={showMore}>
        {(!isSelf || perms.canResendConfirmationEmail) && (
          <MoreActions
            version={version}
            onSetTag={props.onSetTag}
            onRemove={props.onRemove}
            onResetTfa={props.onResetTfa}
            onResendConfirmationEmail={props.onResendConfirmationEmail}
            canUpdateTeamMembers={perms.canUpdateTeamMembers}
            canResendConfirmationEmail={perms.canResendConfirmationEmail}
            canRemoveGroupMembers={Boolean(
              groupId &&
                (perms.hasTeamUpdateGroupMembership ||
                  perms.groupPerms?.[groupId]?.assign_team_members_to_groups)
            )}
            onRemoveFromGroup={onRemoveFromGroup}
            tag={tag}
            canResetTFA={perms.canResetTFA}
            tfaEnabled={tfaEnabled}
            canRemoveTeamMembers={perms.canRemoveTeamMembers}
            isSelf={isSelf}
            isScimMember={isScimMember}
            isConfirmedMember={isConfirmedMember}
            email={email}
            groupName={
              groups.find(g => g.id === Number(groupId))?.name ?? 'this group'
            }
          />
        )}
      </Column>
    </>
  );
}

function groupsCount(groupIds: number[] | null) {
  const count = groupIds?.length ?? 0;
  if (count > 1) return `${count} Groups`;
  if (count === 1) return `${count} Group`;
  return `0 Groups`;
}

function ViewOnlyWrapper(props: {
  viewOnly: boolean;
  preview?: ReactNode;
  children: ReactNode;
  version?: 'newFont';
}) {
  return props.viewOnly ? (
    <ViewOnlyItem version={props.version}>{props.preview}</ViewOnlyItem>
  ) : (
    props.children
  );
}

function MoreActions(props: {
  onRemove(): void;
  onRemoveFromGroup(): void;
  onResetTfa(): void;
  onResendConfirmationEmail(): void;
  canUpdateTeamMembers: boolean;
  canRemoveGroupMembers: boolean;
  canResendConfirmationEmail: boolean;
  tag: string;
  onSetTag(v: string): void;
  canResetTFA: boolean;
  tfaEnabled: boolean;
  canRemoveTeamMembers: boolean;
  isSelf: boolean;
  isScimMember: boolean;
  email: string;
  groupName: string;
  isConfirmedMember: boolean;
  version?: 'newFont';
}) {
  const {
    onSetTag,
    onRemove,
    onRemoveFromGroup,
    onResetTfa,
    onResendConfirmationEmail,
    canUpdateTeamMembers,
    canRemoveGroupMembers,
    canResendConfirmationEmail,
    tag,
    canResetTFA,
    tfaEnabled,
    canRemoveTeamMembers,
    isSelf,
    isScimMember,
    email,
    groupName,
    isConfirmedMember,
    version
  } = props;

  const actions: Array<{
    text: string;
    type?: 'negative'; // not used in the Flyout component
    onSelect(): void;
  }> = [];

  const modals = useModals({ confirm: ConfirmModal });

  function handleTag(e: FormEvent) {
    e.preventDefault();
    const { tag } = formValues<{ tag: string }>(e.nativeEvent);
    onSetTag(tag);
    modals.close();
  }

  // Add, remove edit tag
  if (canUpdateTeamMembers) {
    if (tag) {
      const edit = {
        text: 'Edit tag',
        onSelect() {
          modals.open('confirm', {
            title: 'Edit Tag',
            form: 'edit_tag',
            children: (
              <EditTag handleTag={handleTag} tag={tag} version={version} />
            ),
            version: version
          });
        }
      };

      const remove = {
        text: 'Remove tag',
        kind: 'error' as const, // not a Flyout props
        onSelect() {
          onSetTag('');
        }
      };
      actions.push(edit, remove);
    } else {
      const add = {
        text: 'Add tag',
        onSelect() {
          modals.open('confirm', {
            title: 'Enter a Tag',
            form: 'add_tag',
            children: (
              <AddTag handleTag={handleTag} tag={tag} version={version} />
            ),
            version: version
          });
        }
      };
      actions.push(add);
    }
  }

  // Resend confirmation email
  if (canResendConfirmationEmail && !isConfirmedMember) {
    const resend = {
      text: 'Resend confirmation email',
      onSelect: onResendConfirmationEmail
    };
    actions.push(resend);
  }

  // Send 2FA reset email
  if ((canUpdateTeamMembers || canResetTFA) && tfaEnabled) {
    const reset = {
      text: 'Send 2FA reset email',
      onSelect: onResetTfa
    };
    actions.push(reset);
  }

  // Remove from current page's group
  if (!isSelf && !isScimMember && canRemoveGroupMembers) {
    const remove = {
      text: 'Remove user from group',
      type: 'negative' as const,
      onSelect() {
        modals.open('confirm', {
          title: 'Remove User from Group',
          onSubmit: onRemoveFromGroup,
          children: (
            <>
              Are you sure you want to remove {email} from {groupName}?
            </>
          )
        });
      }
    };
    actions.push(remove);
  }

  // Remove from team
  if (!isSelf && canRemoveTeamMembers && !isScimMember) {
    const remove = {
      text: 'Remove user from team',
      type: 'negative' as const,
      onSelect() {
        modals.open('confirm', {
          title: 'Remove User From Team',
          onSubmit: onRemove,
          children: <>Are you sure you want to remove {email} from this team?</>
        });
      }
    };
    actions.push(remove);
  }

  return (
    <>
      {modals.dom}
      <Flyout items={actions} version={version}>
        {({ props }) => (
          <IconButton
            title="More actions"
            icon="ellipsis"
            disabled={!actions.length}
            {...props}
          />
        )}
      </Flyout>
    </>
  );
}

function MemberBasicInfo(props: {
  name: string;
  userId: number;
  isSaml: boolean;
  isActive: boolean;
  tag?: string;
  email: string;
  isScimMember: boolean;
  lastConnectedAt: string | null;
  isConfirmedMember: boolean;
  version?: 'newFont';
}) {
  const {
    name,
    userId,
    isSaml,
    isActive,
    isScimMember,
    tag,
    email,
    lastConnectedAt,
    isConfirmedMember,
    version
  } = props;

  const activeStyle =
    isScimMember && !isActive
      ? { filter: 'grayscale(100%)', color: 'grey' }
      : {};
  const isInactiveScimMember = isScimMember && !isActive;

  // overflowing text ref
  const userEmailRef = useRef<HTMLDivElement>(null);

  return (
    <Info>
      <StyledAvatar userId={userId} size={36} css={activeStyle} />
      <InfoRow>
        <Tooltip
          placement="bottom"
          disabled={!lastConnectedAt}
          version={version}
          tooltipText={
            lastConnectedAt
              ? `Last Connected: ${new Date(lastConnectedAt).toLocaleDateString(
                  'en-US'
                )}`
              : ''
          }
        >
          <Name cursor={!lastConnectedAt} version={version}>
            <User css={activeStyle}>{name}</User>
            <Id css={activeStyle}>#{userId}</Id>
          </Name>
        </Tooltip>
        <IconsWrapper>
          {isSaml && (
            <Tooltip
              placement="bottom"
              version={version}
              tooltipText="SAML Enabled"
            >
              <ShieldIcon name="shield" />
            </Tooltip>
          )}
          {isScimMember && (
            <Tooltip
              placement="top"
              version={version}
              tooltipText={
                <>
                  This user is being managed
                  <br />
                  externally with SCIM.
                </>
              }
            >
              <ScimIcon name="scim" />
            </Tooltip>
          )}
        </IconsWrapper>

        {tag && <Badge version={version}>{tag}</Badge>}
      </InfoRow>
      <EmailRow>
        {isConfirmedMember ? (
          <IconsWrapper>
            <ShieldIcon name="checkedShield" />
          </IconsWrapper>
        ) : null}
        <Tooltip
          placement="bottom"
          tooltipText={email}
          version={version}
          showOnOverflow
          triggerRef={userEmailRef}
        >
          <Subtext ref={userEmailRef} version={version}>
            {isInactiveScimMember && 'Inactive • '}
            {email}
          </Subtext>
        </Tooltip>
      </EmailRow>
    </Info>
  );
}

const EditTag = (props: {
  handleTag(e: FormEvent): void;
  tag: string;
  version?: 'newFont';
}) => {
  const { handleTag, tag } = props;
  const [count, setCount] = useState(-1);

  const isValid = useMemo(() => {
    if (MAX_CHAR && count > 0) {
      return count <= MAX_CHAR;
    }
    return false;
  }, [count]);

  return (
    <form id="edit_tag" onSubmit={handleTag}>
      <FieldLabel isValid={isValid} version={props.version}>
        <Input
          id="tag_name"
          name="tag"
          type="text"
          form="edit_tag"
          defaultValue={tag}
          maxLength={MAX_CHAR}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: { target: { value: string | any[] } }) =>
            setCount(e.target.value.length)
          }
          placeholder="Enter a tag"
          accessory={isValid ? 'check' : undefined}
          version={props.version}
        />
        <FieldLabel.HelperTextContainer>
          <FieldLabel.Count
            match={count >= 0}
            count={count}
            maxCount={MAX_CHAR}
          />
        </FieldLabel.HelperTextContainer>
      </FieldLabel>
    </form>
  );
};

const AddTag = (props: {
  handleTag(e: FormEvent): void;
  tag: string;
  version?: 'newFont';
}) => {
  const { handleTag, tag } = props;
  const [count, setCount] = useState(-1);

  const isValid = useMemo(() => {
    if (MAX_CHAR && count > 0) {
      return count <= MAX_CHAR;
    }
    return false;
  }, [count]);
  return (
    <form id="add_tag" onSubmit={handleTag}>
      <FieldLabel isValid={isValid} version={props.version}>
        <Input
          id="tag_name"
          name="tag"
          form="add_tag"
          defaultValue={tag}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: { target: { value: string | any[] } }) =>
            setCount(e.target.value.length)
          }
          placeholder="Enter a tag"
          maxLength={MAX_CHAR}
          accessory={isValid ? 'check' : undefined}
          version={props.version}
        />
        <FieldLabel.HelperTextContainer>
          <FieldLabel.Count
            match={count >= 0}
            count={count}
            maxCount={MAX_CHAR}
          />
        </FieldLabel.HelperTextContainer>
      </FieldLabel>
    </form>
  );
};

function Column(props: {
  gridArea: TeamMemberTableColumnType;
  show: boolean;
  children: ReactNode;
}) {
  return (
    <div
      style={{
        gridArea: props.gridArea,
        display: props.show ? 'block' : 'none'
      }}
    >
      {props.children}
    </div>
  );
}
