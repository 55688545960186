import { styled } from '@parsec/components';

export const Title = styled('h3', {
  fontSize: '$header',
  lineHeight: '$header',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$attribution'
      }
    },
    transform: {
      uppercase: {
        textTransform: 'uppercase'
      },
      capitalize: {
        textTransform: 'capitalize'
      }
    }
  }
});
