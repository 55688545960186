import { Avatar, Icon, styled } from '@parsec/components';

export interface UserInfoSnippetProps {
  userId: number;
  name: string;
  email: string;
  admin?: boolean;
  saml?: boolean;
  accessLinkAdmin?: boolean;
  tag?: string;
  lastConnectedAt?: string;
}

export function UserInfoSnippet(props: UserInfoSnippetProps) {
  const {
    userId,
    name,
    admin,
    saml,
    accessLinkAdmin,
    tag,
    email,
    lastConnectedAt
  } = props;
  return (
    <Info>
      <StyledAvatar userId={userId} size={36} />
      <InfoRow>
        <Name>
          <strong>{name}</strong>
          <Id>#{userId}</Id>
        </Name>
        {(admin || accessLinkAdmin) && <AdminIcon name="star" />}
        {saml ? (
          <Badge color="green">
            <Icon name="shield" /> <span>SAML</span>
          </Badge>
        ) : null}
        {tag ? <Badge color="gray">{tag}</Badge> : null}
      </InfoRow>
      <InfoRow>
        <Subtext>{email}</Subtext>
        {lastConnectedAt && (
          <Subtext>
            {new Date(lastConnectedAt).toLocaleDateString('en-US')}
          </Subtext>
        )}
      </InfoRow>
    </Info>
  );
}

const Info = styled('div', {
  display: 'grid',
  gridTemplateRows: '1fr 1fr',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '$large'
});

const InfoRow = styled('div', {
  stack: {
    hdistribute: 'start',
    valign: 'center',
    gap: '$medium'
  }
});

const StyledAvatar = styled(Avatar, {
  gridRow: '1 / 3',
  width: '3.6rem',
  height: '3.6rem'
});

const Name = styled('div', {
  stack: {
    hdistribute: 'start'
  },
  fontSize: '$info',
  lineHeight: '$info',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
});

const Id = styled('span', {
  color: '$rhyhorn'
});

const AdminIcon = styled(Icon, {
  color: '$warning500',
  width: '1.2rem',
  height: '1.2rem'
});

const Subtext = styled('div', {
  position: 'relative',
  fontSize: '$info',
  lineHeight: '$info',
  color: '$rhyhorn',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  '&:not(:first-child)': {
    paddingLeft: '$medium',
    '&::before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      left: 0,
      width: '0.1rem',
      height: '100%',
      backgroundColor: '$pancham'
    }
  }
});

const Badge = styled('div', {
  stack: {
    valign: 'center',
    gap: '$small'
  },
  fontSize: '$info',
  lineHeight: '$info',
  fontWeight: 'bold',
  backgroundColor: '$pangoro',
  borderRadius: '$small',
  padding: '0 $small',
  variants: {
    color: {
      gray: {
        backgroundColor: '$pangoro'
      },
      green: {
        backgroundColor: '$success500'
      }
    }
  }
});
