import { useRef, useState } from 'react';

import { HexColorPicker } from 'react-colorful';

import { Icon, styled } from '@parsec/components';
import { useClickOutside } from '@parsec/hooks';

export const ROLE_DEFAULT_COLORS = [
  '#AFAFAF',
  '#F00000',
  '#F07800',
  '#F0B900',
  '#CBF000',
  '#2EF000',
  '#00F0CB',
  '#009DF0',
  '#006FF0',
  '#1C00F0',
  '#6F00F0',
  '#C200F0',
  '#F0006F'
];

const DEFAULT_CUSTOM_COLOR = '#FFFFFF';
export interface RoleRolorSelectorProps {
  disabled?: boolean;
  value: string;
  onChange?(newColor: string): void;
  hasText?: boolean;
}

export function RoleColorSelector(props: RoleRolorSelectorProps) {
  const { value, hasText = true } = props;
  const isDefaultColor = new Set(ROLE_DEFAULT_COLORS).has(value);

  const [customColor, setCustomColor] = useState(
    isDefaultColor ? DEFAULT_CUSTOM_COLOR : value
  );
  const parentRef = useRef<HTMLDivElement>(null);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
  const didOpen = useRef(false);
  const outside = useRef<HTMLDivElement>(null);
  useClickOutside<HTMLDivElement>(() => {
    setIsColorPickerOpen(false);
    if (didOpen.current) {
      didOpen.current = false;
      props.onChange?.(customColor);
    }
  }, outside);

  const isCustomPicked =
    (!props.disabled && isColorPickerOpen) || value === customColor;

  return (
    <Wrapper ref={parentRef}>
      <Swatch
        css={{
          backgroundColor: customColor,
          opacity: props.disabled ? 0.5 : 1,
          pointerEvents: props.disabled ? 'none' : 'initial',
          width: hasText ? '8.5rem' : '3.6rem',
          textAlign: 'center',
          alignItems: isCustomPicked ? 'normal' : 'center'
        }}
        onClick={() => {
          if (!isColorPickerOpen) {
            setIsColorPickerOpen(true);
            didOpen.current = true;
          }
        }}
      >
        {hasText && <>Custom &nbsp;</>}
        {isCustomPicked && <Icon name="check" />}
        {isColorPickerOpen && (
          <div
            ref={outside}
            style={{ position: 'absolute', bottom: '100%', zIndex: 1 }}
          >
            <div
              style={{
                position: 'fixed',
                transform: 'translate(-10rem, -20rem)'
              }}
            >
              <HexColorPicker
                color={customColor}
                onChange={color => {
                  setCustomColor(color.toUpperCase());
                }}
              />
            </div>
          </div>
        )}
      </Swatch>
      {ROLE_DEFAULT_COLORS.map(color => (
        <Swatch
          key={color}
          css={{
            backgroundColor: color,
            opacity: props.disabled ? 0.5 : 1,
            pointerEvents: props.disabled ? 'none' : undefined,
            alignItems: 'center'
          }}
          onClick={() => props.onChange?.(color)}
        >
          {!props.disabled && !isColorPickerOpen && value === color && (
            <Icon name="check" />
          )}
        </Swatch>
      ))}
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  display: 'flex',
  flexFlow: 'row wrap'
});

const Swatch = styled('label', {
  position: 'relative',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateRows: '1.4rem',
  alignContent: 'center',
  width: '3.6rem',
  height: '3.6rem',
  padding: '1.4rem 1.4rem',
  marginRight: '$large',
  marginBottom: '$large',
  borderRadius: '4rem',
  backgroundColor: '$consoleWhite',
  color: '$computerBlack',
  fontFamily: '$newDefault',
  fontSize: '$newInfo',
  fontWeight: 'bold',
  lineHeight: '$info',
  cursor: 'pointer'
});
