import { ReactNode } from 'react';

import { Button, Modal } from '@parsec/components';

interface ConfirmModalProps {
  title: string;
  children: ReactNode;
  onSubmit?(): void;
  form?: string;
  onClose(): void;
  isOpen: boolean;
  onAfterClose(): void;
  version?: 'newFont';
}

export function ConfirmModal(props: ConfirmModalProps) {
  const {
    title,
    children,
    form,
    onSubmit,
    onClose,
    isOpen,
    onAfterClose,
    version
  } = props;

  return (
    <Modal
      version={version}
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onAfterClose={onAfterClose}
    >
      {children}
      <Modal.Actions>
        {onSubmit ? (
          <Button
            onClick={() => {
              onSubmit();
              onClose();
            }}
            version={version}
          >
            Confirm
          </Button>
        ) : form ? (
          <Button form={form} version={version}>
            Confirm
          </Button>
        ) : null}

        <Button level="secondary" onClick={onClose} version={version}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
