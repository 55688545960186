import { styled } from '@parsec/styles';

/* 
Restores form styling in areas where we are deprecating old `dash/components/Form` <Form> component

Usage:

// old Form component, remove old onSubmit handler.
// Doing this will retain existing form styling, but removes submit handling.
<Form> 
   // this component, submit handler goes onto this form styled component.
   <StyledForm onSubmit={submitHandler}> 
   {...}
   </StledForm>
</Form>
*/

const StyledForm = styled('form', {
  marginTop: '3rem',
  display: 'grid',
  gridAutoFlow: 'inherit',
  rowGap: 'inherit',
  width: '30rem',
  justifySelf: 'center',
  maxWidth: '100%',
  '@large': {
    width: '38rem'
  }
});

export default StyledForm;
