import { useState } from 'react';

import { styled, FieldLabel, Input, Select } from '@parsec/components';
import { COUNTRY_CODES, STATE_CODES } from '@parsec/constants';
import { Customer } from '@parsec/kessel';

const version = 'newFont';

export interface BillingDetailsFormValues {
  company_name: string;
  billing_email: string;
  phone?: string;
  tax_id?: string;
  first_name?: string;
  last_name?: string;
  address_1?: string;
  address_2?: string;
  country: string;
  state?: string;
  city?: string;
  postal_code?: string;
}

export interface BillingDetailsFormProps {
  className?: string;
  email?: string;
  billingDetails?: Customer;
  form?: string;
  disabled?: boolean;
}

export function BillingDetailsForm(props: BillingDetailsFormProps) {
  const { className, email, billingDetails, form, disabled } = props;

  const [requireZip, setRequireZip] = useState(
    billingDetails?.billing_address.country === 'US'
  );

  return (
    <>
      <CardSection id="company_tax_billing_form" className={className}>
        <CardField version={version}>
          <FieldLabel.Label label="Company Name" required>
            <Input
              version={version}
              name="company_name"
              placeholder="Parsec"
              defaultValue={billingDetails?.company}
              form={form}
              disabled={disabled}
              required
            />
          </FieldLabel.Label>
        </CardField>

        <CardField version={version}>
          <FieldLabel.Label label="Billing Email" required>
            <Input
              version={version}
              name="billing_email"
              placeholder="sam.oak@parsec.app"
              defaultValue={billingDetails?.email || email}
              form={form}
              disabled={disabled}
              required
            />
          </FieldLabel.Label>
        </CardField>
        <CardField version={version}>
          <FieldLabel.Label label="Phone">
            <Input
              version={version}
              name="phone"
              placeholder="XXX-XXX-XXXX"
              defaultValue={billingDetails?.phone}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </CardField>
        <CardField version={version}>
          <FieldLabel.Label label="Tax ID">
            <Input
              version={version}
              name="tax_id"
              placeholder="ID"
              defaultValue={billingDetails?.tax_id}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </CardField>
      </CardSection>
      <AddressSection id="billing_address_form" className={className}>
        <AddressField version={version}>
          <FieldLabel.Label label="First Name">
            <Input
              version={version}
              name="first_name"
              placeholder="Samuel"
              defaultValue={billingDetails?.first_name}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        <AddressField version={version}>
          <FieldLabel.Label label="Last Name">
            <Input
              version={version}
              name="last_name"
              placeholder="Oak"
              defaultValue={billingDetails?.last_name}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        <AddressField version={version}>
          <FieldLabel.Label label="Address 1">
            <Input
              version={version}
              name="address_1"
              placeholder="123 Place St"
              defaultValue={billingDetails?.billing_address.line1}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        <AddressField version={version}>
          <FieldLabel.Label label="Address 2">
            <Input
              version={version}
              name="address_2"
              placeholder="Suite #1"
              defaultValue={billingDetails?.billing_address.line2}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        <AddressField version={version}>
          <FieldLabel.Label label="Country">
            <Select
              version={version}
              name="country"
              required
              options={countryCodes}
              defaultValue={billingDetails?.billing_address.country ?? ''}
              onChange={event => setRequireZip(event.target.value === 'US')}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        {requireZip ? (
          <AddressField version={version}>
            <FieldLabel.Label label="State" required={requireZip}>
              <Select
                version={version}
                name="state"
                options={stateCodes}
                defaultValue={billingDetails?.billing_address.state}
                disabled={disabled}
                required={requireZip}
                form={form}
              />
            </FieldLabel.Label>
          </AddressField>
        ) : (
          <AddressField version={version}>
            <FieldLabel.Label label="State">
              <Input
                version={version}
                name="state"
                placeholder="state/province"
                defaultValue={billingDetails?.billing_address.state}
                disabled={disabled}
                form={form}
              />
            </FieldLabel.Label>
          </AddressField>
        )}
        <AddressField version={version}>
          <FieldLabel.Label label="City">
            <Input
              version={version}
              name="city"
              placeholder="pallet town"
              defaultValue={billingDetails?.billing_address.city}
              disabled={disabled}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
        <AddressField span="quarter" version={version}>
          <FieldLabel.Label label="Zip / Postal Code" required={requireZip}>
            <Input
              version={version}
              name="postal_code"
              placeholder="00000"
              defaultValue={billingDetails?.billing_address.zip}
              disabled={disabled}
              required={requireZip}
              form={form}
            />
          </FieldLabel.Label>
        </AddressField>
      </AddressSection>
    </>
  );
}

const CardSection = styled('section', {
  padding: '$xxlarge 0',
  display: 'grid',
  rowGap: '$large',
  columnGap: '2.2rem',
  maxWidth: '100%',
  gridTemplateColumns: 'repeat(2, 1fr)'
});

const AddressSection = styled('section', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  rowGap: '$xxlarge',
  columnGap: '$large'
});

const CardField = styled(FieldLabel, {
  variants: {
    span: {
      full: {
        gridColumn: 'span 6'
      },
      half: {
        gridColumn: 'span 3'
      },
      third: {
        gridColumn: 'span 2'
      },
      sixth: {
        gridColumn: 'span 1'
      }
    }
  }
});

const AddressField = styled(FieldLabel, {
  variants: {
    span: {
      full: {
        gridColumn: 'span 4'
      },
      half: {
        gridColumn: 'span 2'
      },
      quarter: {
        gridColumn: 'span 1'
      }
    }
  }
});

const countryCodes = [
  { value: '', name: '-- select country --' },
  ...COUNTRY_CODES
];

const stateCodes = [{ value: '', name: '-- select state --' }, ...STATE_CODES];
