export interface BreadProps {
  kind?: 'ciabatta' | 'baguette' | 'sourdough';
  decimal?: number;
  children: number;
}

export function Bread({ children, decimal = 2 }: BreadProps) {
  return Number.isFinite(children) ? (
    `$${(children / 100).toFixed(decimal)}`
  ) : (
    <>$...</>
  );
}
