import { FC } from 'react';
/**
 * Creates a component that always renders null but has the desired typedef.
 * Useful for prototyping.
 * @param _render optional component implementation (does not get called)
 */
export function emptyComponent<T>(_render?: FC<T>) {
  return (() => null) as FC<T>;
}

/**
 * Converts a string to an array of JSX string and/or <a>
 * @param str a string possibly containing HTTP links
 */
export function linkify(str: string) {
  return str
    .split(/(\[.+?\]\(.+?\))/g)
    .map(s => s.replace(/\s+/, ' '))
    .filter(Boolean)
    .map((s, i) => {
      const match = /\[(.+?)\]\((.+?)\)/.exec(s);
      if (match) {
        const [, text, href] = match;
        return (
          <a key={i} href={href}>
            {text}
          </a>
        );
      } else return s;
    });
}
