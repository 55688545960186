import { Query } from 'react-query';
import { useAlertContext } from 'context';

interface QueryErrorProviderProps {
  render: (args: {
    onQueryCacheError: (error: unknown, query: Query) => void;
  }) => JSX.Element;
}

export const QueryErrorProvider = ({ render }: QueryErrorProviderProps) => {
  const { onQueryCacheError } = useAlertContext();
  return render({ onQueryCacheError });
};
