import { ReactNode } from 'react';

/**
 * This component is useful as an alernative for wrapping switch statements in an IIFE
 * @param props.value whatever enumeration you want
 * @param props.children a function that conditionally returns a ReactNode based on the enum value
 */
export function Enum<T>(props: {
  value: T;
  children: (value: T) => ReactNode;
}) {
  return <>{props.children(props.value)}</>;
}
