import { styled } from '@parsec/components';

export const ErrorMessage = styled('p', {
  color: '$error500',
  marginBottom: '$large',
  '&:first-letter': {
    textTransform: 'capitalize'
  },
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
