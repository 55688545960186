import type { ReactNode } from 'react';

import AnimateHeight from 'react-animate-height';

import { styled } from '@parsec/styles';

import IconButton from '../IconButton';

const Header = styled('header', {
  display: 'grid',
  gridTemplateColumns: '1fr auto',
  columnGap: '$xxxlarge',
  marginBottom: '$medium',
  fontFamily: '$newDefault'
});

const Title = styled('label', {
  fontSize: '$newBody',
  gridColumn: '1',
  marginBottom: '$medium'
});

const Description = styled('p', {
  gridColumn: '1',
  fontSize: '$newInfo',
  lineHeight: '$info'
});

const Toggle = styled(IconButton, {
  gridRow: '1 / 3',
  gridColumn: '2',
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {
        transform: 'rotate(0)'
      }
    }
  }
});

const Content = styled('div', {
  padding: '$xxlarge',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  fontFamily: '$newDefault'
});

export interface DetailsProps {
  className?: string;
  id: string;
  open: boolean;
  title: string;
  description: string;
  children: ReactNode;
  onToggle(): void;
}

export default function Details(props: DetailsProps) {
  const { className, id, open, title, description, children, onToggle } = props;
  const toggleId = `${id}_toggle`;
  const contentId = `${id}_content`;
  return (
    <div className={className}>
      <Header>
        <Title htmlFor={toggleId}>{title}</Title>
        <Description>{description}</Description>
        <Toggle
          id={toggleId}
          title={open ? 'Close' : 'Open'}
          open={open}
          icon="arrow"
          onClick={onToggle}
          aria-expanded={open}
          aria-controls={contentId}
        />
      </Header>
      <AnimateHeight
        id={contentId}
        role="region"
        height={open ? 'auto' : 0}
        aria-labelledby={toggleId}
      >
        <Content>{children}</Content>
      </AnimateHeight>
    </div>
  );
}
