import { INTERVAL_MONTHLY, INTERVAL_YEARLY } from 'constants/subscription';

export const ACCESS_LINK_COST = 2500;
export const BUNDLE_DISCOUNT = 0.1;
export const ACCESS_LINK_BUNDLE_COST = ACCESS_LINK_COST * (1 - BUNDLE_DISCOUNT);

// GUEST ACCESS CREDIT CONSTS
export const PRICE_PER_CREDIT_CENTS = 2500;
export const PACKAGE_PRICE_PER_CREDIT_CENTS = 2250;
export const MAX_REG_CREDIT_QUANTITY = 99;
export const PACKAGE_QUANTITY = 100;
export const PACKAGE_DISCOUNT = 10 / 100;

export const GUEST_ACCESS_ADDON_ID = {
  PACKAGE: 'guest-access-package',
  REGULAR: 'guest-access-standard'
} as const;

const STANDARD_MONTHLY_UNIT_PRICE = 3500;
const STANDARD_YEARLY_UNIT_PRICE = 36000;
const STANDARD_SEAT_UNIT_PRICE = {
  [INTERVAL_MONTHLY]: STANDARD_MONTHLY_UNIT_PRICE,
  [INTERVAL_YEARLY]: STANDARD_YEARLY_UNIT_PRICE
};

export function getSeatUnitPrice(
  interval: keyof typeof STANDARD_SEAT_UNIT_PRICE
) {
  return STANDARD_SEAT_UNIT_PRICE[interval];
}

// TODO: move this to API
export const CARD_STATUS_VALID = 'valid';
