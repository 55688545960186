// I don't think this is being used anywhere?
import Link from 'next/link';
import { useQueryClient } from 'react-query';

import { styled, Avatar, IconButton, Button } from '@parsec/components';
import { User } from '@parsec/kessel';
import { useGetTeamSubscription, useLogOut } from '@parsec/queries';
import * as time from '@parsec/time';

import { DASH_URL } from 'lib/config';

export interface ProfileProps {
  className?: string;
  user?: User;
}

export function Profile(props: ProfileProps) {
  const { user } = props;
  const { data: subscription } = useGetTeamSubscription();
  const queryClient = useQueryClient();

  const { mutateAsync: logOut } = useLogOut();

  const trialing = subscription?.status === 'in_trial';

  const trialEnd =
    subscription &&
    subscription.trial_end &&
    time.fromISO(subscription.trial_end);

  const trialEndsInHours =
    trialEnd &&
    Math.floor(time.sub(trialEnd, time.now()).getTime() / time.hours(1));

  const handleLogOutClick = async () => {
    try {
      await logOut();
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Could not delete session', err);
    } finally {
      queryClient.clear();
    }
  };

  return (
    <Wrapper highlight={trialing}>
      {trialing && (
        <TrialInfo>
          <h3>Parsec for Teams Trial</h3>
          <p>Trial ends in {trialEndsInHours} hours</p>
          <YUMadBro>Unlock all features</YUMadBro>
          <Link href="/subscription" passHref>
            <StyledButtonLink size="large">Subscribe to Teams</StyledButtonLink>
          </Link>
        </TrialInfo>
      )}
      {user && (
        <Footer highlight={trialing}>
          <UserProfile highlight={trialing}>
            <StyledAvatar userId={user.id} size={36} />
            <Name dark={trialing}>{user.name}</Name>
            <Info>
              <ViewProfile dark={trialing} href={DASH_URL}>
                View Profile
              </ViewProfile>
            </Info>
          </UserProfile>
          <LogOut
            css={{ color: '$error500' }}
            noBG={trialing}
            title="Log Out"
            icon="leave"
            kind="negative"
            onClick={handleLogOutClick}
          />
        </Footer>
      )}
    </Wrapper>
  );
}

const Footer = styled('footer', {
  stack: {
    layout: '1fr auto',
    valign: 'center'
  },
  padding: '0 $large',
  boxShadow: 'inset 0 0.1rem 0 $colors$pangoro',
  variants: {
    highlight: {
      true: {
        boxShadow: 'inset 0 0.1rem 0 #D08E0D'
      }
    }
  }
});

const UserProfile = styled('div', {
  margin: '$xxxlarge',
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'auto auto',
  columnGap: '$medium',
  fontSize: '$info',
  lineSeight: '$info',
  color: '$rhyhorn',
  textDecoration: 'none',
  variants: {
    highlight: {
      true: {
        margin: '$xlarge $medium'
      }
    }
  }
});

const StyledAvatar = styled(Avatar, {
  gridRow: '1 / 3',
  gridColumn: '1',
  width: '3.6rem',
  height: '3.6rem'
});

const Name = styled('span', {
  display: 'block',
  fontWeight: 'bold',
  color: '$consoleWhite',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  variants: {
    dark: {
      true: {
        color: '#453004'
      }
    }
  }
});

const Info = styled('div', {
  stack: {
    direction: 'horizontal',
    hdistribute: 'start'
  }
});

const ViewProfile = styled('a', {
  variants: {
    dark: {
      true: {
        color: '#453004'
      }
    }
  }
});

const LogOut = styled(IconButton, {
  margin: '$large',
  color: '$error500',
  '&:hover': {
    color: '$consoleWhite'
  },
  variants: {
    noBG: {
      true: {
        color: '$consoleWhite',
        backgroundColor: 'transparent'
      }
    }
  }
});

const Wrapper = styled('div', {
  margin: 0,
  borderRadius: '0.8rem',
  variants: {
    highlight: {
      true: {
        margin: '$large',
        backgroundColor: '#FFAD11'
      }
    }
  }
});

const TrialInfo = styled('div', {
  color: '$computerBlack',
  padding: '$xxlarge',
  textAlign: 'center',
  display: 'grid',
  gridTemplateRows: 'auto 3.2rem auto auto',
  justifyItems: 'center',
  gridRowGap: '$small'
});

const StyledButtonLink = styled(Button, {
  backgroundColor: 'transparent',
  border: '1px solid $colors$consoleWhite',
  '&:hover, &:active, &:focus': {
    backgroundColor: 'transparent'
  },
  padding: 0
});

const YUMadBro = styled('strong', {
  textTransform: 'uppercase'
});
