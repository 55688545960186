import { ReactNode, ReactElement, Children, isValidElement } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

// STYLES
const Wrapper = styled('div', {
  width: '100%',
  borderRadius: '$large',
  padding: '$large $xxlarge',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '$medium',
  marginTop: '4rem',
  '&:has(+ :not(&))': {
    marginBottom: '2rem'
  },
  '& + &': {
    marginTop: '$medium'
  },
  variants: {
    level: {
      promo: {
        backgroundColor: '$primary500'
      },
      ok: {
        backgroundColor: '$success500'
      },
      warn: {
        backgroundColor: '$warning500'
      },
      error: {
        backgroundColor: '$error500'
      }
    }
  }
});

const Title = styled('h1', {
  color: '$ultraDark',
  fontSize: '1rem',
  lineHeight: '2rem',
  fontWeight: '$bold',
  textTransform: 'uppercase',
  textAlign: 'left',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const Text = styled('p', {
  color: '$ultraDark',
  fontSize: '1rem',
  lineHeight: '1.6rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const Button = styled('button', {
  cursor: 'pointer',
  fontWeight: '$bold',
  fontSize: '1.4rem',
  lineHeight: '1.6rem',
  marginLeft: 'auto',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$info',
        lineHeight: '$body'
      }
    }
  }
});

const IconWrapper = styled(Icon, {
  padding: '$none',
  color: '$computerBlack'
});

// COMPONENT
export interface AlertBannerProps {
  className?: string;
  level:
    | 'promo' // Used for displaying sales promotions (e.g. holiday Warp sale)
    | 'ok'
    | 'warn'
    | 'error';
  onClose: () => void;
  isDismissible?: boolean;
  children: ReactNode;
  version?: 'newFont';
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const nodesByType = (children: ReactNode, types: ReactElement<any>['type'][]) =>
  Children.toArray(children).filter(
    child => isValidElement(child) && types.includes(child.type)
  );

const AlertBanner = ({
  className,
  children,
  onClose,
  level,
  isDismissible = true,
  version
}: AlertBannerProps) => {
  const [action] = nodesByType(children, [AlertBannerAction]);
  const [icon] = nodesByType(children, [AlertBannerIcon]);
  return (
    <Wrapper level={level} className={className}>
      {icon ? icon : null}
      <div>
        {nodesByType(children, [AlertBannerTitle, AlertBannerDescription])}
      </div>
      {isDismissible ? (
        <Button onClick={onClose} version={version}>
          {action ? action : 'Dismiss'}
        </Button>
      ) : null}
    </Wrapper>
  );
};

export interface AlertBannerTitleProps {
  children: ReactNode;
  version?: 'newFont';
}

const AlertBannerTitle = (props: AlertBannerTitleProps) => {
  return <Title version={props.version}>{props.children}</Title>;
};

export interface AlertBannerDescriptionProps {
  children: ReactNode;
  version?: 'newFont';
}

const AlertBannerDescription = (props: AlertBannerDescriptionProps) => {
  return <Text version={props.version}>{props.children}</Text>;
};

export interface AlertBannerActionProps {
  children: ReactNode;
}

const AlertBannerAction = (props: AlertBannerActionProps) => {
  return props.children;
};

export interface AlertIconProps {
  type: 'important' | 'warning' | 'info' | 'success';
}

const AlertBannerIcon = ({ type }: AlertIconProps) => {
  const iconName = (() => {
    switch (type) {
      case 'important':
        return 'infoFilled';
      case 'info':
        return 'bulb';
      case 'warning':
        return 'warningSign';
      case 'success':
        return 'success';
    }
  })();

  return <IconWrapper name={iconName} />;
};

AlertBanner.Title = AlertBannerTitle;
AlertBanner.Description = AlertBannerDescription;
AlertBanner.Action = AlertBannerAction;
AlertBanner.Icon = AlertBannerIcon;

export default AlertBanner;
