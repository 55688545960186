import { Children, ReactNode, useMemo } from 'react';

import { styled } from '@parsec/components';

interface BuilderProps {
  emptyState?: ReactNode;
  children: ReactNode;
  preset: {
    [key: string]: string;
  };
  version?: 'newFont';
}

function camelToWords(camel: string) {
  const result = camel.replace('And', ' &').replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function TableBuilder(props: BuilderProps) {
  const { preset, emptyState } = props;

  const colNames = Object.keys(preset);

  const columnTitles = colNames
    .filter(col => col !== 'select' && col !== 'more')
    .map(col => (
      <span key={col} style={{ gridArea: col }}>
        {camelToWords(col)}
      </span>
    ));

  const gridTemplate = useMemo(() => {
    const templateWidths = colNames.map(col => preset[col]);
    return {
      gridTemplateColumns: templateWidths.join(' '),
      gridTemplateAreas: `"${colNames.join(' ')}"`
    };
  }, [colNames, preset]);

  const children = Children.toArray(props.children);
  return (
    <>
      <Header css={gridTemplate} version={props.version}>
        {columnTitles}
      </Header>
      <List>
        {!children.length
          ? emptyState
          : children.map((child, i) => (
              <TableItem key={i} css={gridTemplate}>
                {child}
              </TableItem>
            ))}
      </List>
    </>
  );
}

const Header = styled('header', {
  display: 'grid',
  gap: '$xxxlarge',
  fontSize: '$info',
  lineHeight: '$info',
  fontWeight: 'bold',
  color: '$rhyhorn',
  textTransform: 'uppercase',
  padding: '$xlarge 0 $medium',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const TableItem = styled('li', {
  display: 'grid',
  gap: '$xxxlarge',
  alignItems: 'center',
  borderRadius: '$small',
  height: '4.8rem',
  minWidth: 'unset',
  variants: {
    selected: {
      true: {
        backgroundColor: '$computerBlack'
      }
    }
  }
});

const List = styled('ul', {
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$small',
  padding: '$large 0'
});
