import type { ReactNode } from 'react';

import { SUPPORT_LINKS } from '@parsec/constants';
import { captureException } from '@parsec/sentry';
import { styled } from '@parsec/styles';
import { formatDate } from '@parsec/time';

import { priceFormatCents } from '../../utils';
import AlertModal, { type AlertModalProps } from '../AlertModal';
import BaseButton from '../Button';

const INTERVAL_YEARLY = 'year';
const INTERVAL_MONTHLY = 'month';
const ENTERPRISE = 'enterprise';
const TEAMS = 'teams';

const Button = styled(BaseButton, {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution'
});

const ErrorModalTitle = styled(AlertModal.Title, {
  fontFamily: '$newDefault'
});

const ErrorModalContentWrapper = styled(AlertModal.ContentWrapper, {
  padding: '$xxxlarge $xxlarge',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution',
  display: 'grid',
  gridRowGap: '$xxxlarge',
  overflowY: 'hidden'
});

const ErrorModalList = styled('ul', {
  paddingLeft: '$xxxlarge',
  display: 'grid',
  gridRowGap: '$xxxlarge',
  variants: {
    warning: {
      true: {
        color: '$warning500'
      }
    },
    noSpacing: {
      true: {
        gridRowGap: '$none'
      }
    }
  }
});

const ErrorModalListItem = styled('li', {
  listStyle: 'disc'
});

const Bold = styled('span', {
  fontWeight: '$bold'
});

export const DowngradeErrorModal = (props: AlertModalProps) => {
  const { children, defaultOpen, open, onOpenChange, ...rest } = props;
  return (
    <AlertModal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <ErrorModalTitle>Downgrade Already Pending</ErrorModalTitle>
            </AlertModal.Header>
            <ErrorModalContentWrapper>
              <p>
                You cannot apply an upgrade while you have an existing downgrade
                already scheduled.
              </p>
              <p>To upgrade, either:</p>
              <ErrorModalList>
                <ErrorModalListItem>
                  <Bold>Revert your downgrade first</Bold>
                  <br />
                  Cancel any scheduled first to revert to your original
                  subscription, then retry your upgrade. After the upgrade is
                  complete, you may re-apply your downgrade.
                </ErrorModalListItem>
                <ErrorModalListItem>
                  <Bold>Try again later</Bold>
                  <br />
                  Depending on your needs, it may make sense to wait until your
                  downgrade has finished and then try again.
                </ErrorModalListItem>
              </ErrorModalList>
            </ErrorModalContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Cancel asChild>
                <Button>Got it</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export const CancellationErrorModal = (props: AlertModalProps) => {
  const { children, defaultOpen, open, onOpenChange, ...rest } = props;
  return (
    <AlertModal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <ErrorModalTitle>Account Cancellation Pending</ErrorModalTitle>
            </AlertModal.Header>
            <ErrorModalContentWrapper>
              <p>
                You cannot apply changes while your account is already scheduled
                for cancellation.
              </p>
              <p>To change:</p>
              <ErrorModalList>
                <ErrorModalListItem>
                  <Bold>Revert your cancellation first</Bold>
                  <br />
                  Reactivate your account first to revert back to your original
                  subscription, then retry your change. You may cancel again
                  afterwards, if desired.
                </ErrorModalListItem>
              </ErrorModalList>
            </ErrorModalContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Cancel asChild>
                <Button>Got it</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export interface MissingCardErrorModalProps
  extends Omit<AlertModalProps, 'children'> {
  children?: ReactNode;
  isAdmin: boolean;
  onActionClick: () => void;
}

export const MissingCardErrorModal = (props: MissingCardErrorModalProps) => {
  const {
    children,
    defaultOpen,
    isAdmin,
    onActionClick,
    onOpenChange,
    open,
    ...rest
  } = props;
  return (
    <AlertModal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      {children ? (
        <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      ) : null}
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <ErrorModalTitle>Missing Credit Card</ErrorModalTitle>
            </AlertModal.Header>
            <ErrorModalContentWrapper>
              <p>
                {isAdmin
                  ? `Your account is set for credit card payments, but does not have a card on file. You cannot make self-service changes, purchase guest access credits, or cancel your account without a valid credit card on file.`
                  : `Your account is set for credit card payments, but does not have a card on file. You cannot make self-service changes, purchase guest access credits, or cancel your account without a valid credit card on file. Please contact an admin to update your billing information.`}
              </p>
              <div>
                <p>To complete your action:</p>
                <ErrorModalList noSpacing>
                  <ErrorModalListItem>
                    {isAdmin
                      ? `Update your billing information with a valid credit card.`
                      : `Ask your admin to update your billing information.`}
                  </ErrorModalListItem>
                  <ErrorModalListItem>
                    <a
                      href={SUPPORT_LINKS.CONTACT_US}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Contact Support
                    </a>{' '}
                    for assistance.
                  </ErrorModalListItem>
                </ErrorModalList>
              </div>
            </ErrorModalContentWrapper>
            <AlertModal.Footer>
              {isAdmin ? (
                <AlertModal.Action asChild>
                  <Button onClick={onActionClick}>
                    Update Billing Information
                  </Button>
                </AlertModal.Action>
              ) : (
                <AlertModal.Cancel asChild>
                  <Button>Got it</Button>
                </AlertModal.Cancel>
              )}
              <AlertModal.Action asChild>
                <Button
                  level="secondary"
                  href={SUPPORT_LINKS.CONTACT_US}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Contact Support
                </Button>
              </AlertModal.Action>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export interface RateChangeModalProps extends AlertModalProps {
  existingPlan: {
    seatPrice: number;
    term: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
  };
  scheduledPlan: {
    seatPrice: number;
    term: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
    changeDate: Date;
  };
}

export const RateChangePendingModal = (props: RateChangeModalProps) => {
  const {
    children,
    defaultOpen,
    open,
    onOpenChange,
    existingPlan,
    scheduledPlan,
    ...rest
  } = props;
  return (
    <AlertModal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <ErrorModalTitle>Rate Change Pending</ErrorModalTitle>
            </AlertModal.Header>
            <ErrorModalContentWrapper>
              <p>
                You cannot update your subscription while you have an existing
                scheduled change for your seat price (rate).
              </p>
              <Bold>Details about your existing scheduled change:</Bold>

              <ErrorModalList noSpacing>
                <ErrorModalListItem>
                  <Bold>Existing seat price:</Bold>{' '}
                  {priceFormatCents(existingPlan.seatPrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                  /seat (billed{' '}
                  {existingPlan.term === INTERVAL_MONTHLY
                    ? 'monthly'
                    : 'annually'}
                  )
                </ErrorModalListItem>
                <ErrorModalListItem>
                  <Bold>Scheduled seat price:</Bold>{' '}
                  {priceFormatCents(scheduledPlan.seatPrice, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  })}
                  /seat (billed{' '}
                  {scheduledPlan.term === 'month' ? 'monthly' : 'annually'})
                </ErrorModalListItem>
                <ErrorModalListItem>
                  <Bold>Scheduled change date:</Bold>{' '}
                  {formatDate(scheduledPlan.changeDate, {
                    month: 'long',
                    day: 'numeric',
                    year: 'numeric'
                  })}
                </ErrorModalListItem>
              </ErrorModalList>

              <Bold>To complete your update:</Bold>

              <ErrorModalList noSpacing>
                <ErrorModalListItem>
                  Wait until <Bold>after the scheduled change date</Bold> and
                  try again.
                </ErrorModalListItem>
                <ErrorModalListItem>
                  <a
                    href={SUPPORT_LINKS.CONTACT_US}
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    Contact Support
                  </a>{' '}
                  for assistance.
                </ErrorModalListItem>
              </ErrorModalList>
            </ErrorModalContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Cancel asChild>
                <Button>Got it</Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

interface Plan {
  addOns?: { id: string; quantity: number }[] | null;
  product: typeof TEAMS | typeof ENTERPRISE;
  seatPrice: number;
  seatQuantity: number;
  term: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY;
}

type ScheduledPlan = Plan & { changeDate: Date };

const renderPlanDetails = (plan: Plan | ScheduledPlan) => {
  return `${plan.product === TEAMS ? 'Teams' : 'Enterprise'} ${
    plan.term === INTERVAL_MONTHLY ? 'Monthly' : 'Annual'
  }, ${plan.seatQuantity} seats, ${priceFormatCents(plan.seatPrice, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  })}/seat${
    plan.addOns && plan.addOns.length > 0
      ? plan.addOns.map(addOn => `, Add-on (${addOn.quantity} free seats)`)
      : ''
  }`;
};

export interface UpgradePendingModalProps
  extends Omit<AlertModalProps, 'children'> {
  children?: ReactNode;
  existingPlan: Plan;
  scheduledPlan: ScheduledPlan;
}

const upgradeModalSentryContext = {
  tags: { component: 'UpgradePendingModal' }
};

export const UpgradePendingModal = (props: UpgradePendingModalProps) => {
  const {
    children,
    defaultOpen,
    open,
    onOpenChange,
    existingPlan,
    scheduledPlan,
    ...rest
  } = props;
  const isDateInvalid = isNaN(scheduledPlan.changeDate.getTime());
  if (isDateInvalid && open) {
    // notify sentry
    captureException(new Error('Invalid scheduled renewal date'), {
      ...upgradeModalSentryContext,
      extra: { date: scheduledPlan.changeDate }
    });
  }
  return (
    <AlertModal
      defaultOpen={defaultOpen}
      open={open}
      onOpenChange={onOpenChange}
      {...rest}
    >
      {children ? (
        <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      ) : null}
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="large">
            <AlertModal.Header>
              <ErrorModalTitle>
                Managed Change Already Scheduled
              </ErrorModalTitle>
            </AlertModal.Header>
            <ErrorModalContentWrapper>
              <p>
                You cannot apply self-service subscription changes to your
                account while it’s already scheduled for a managed change.
              </p>
              <div>
                <Bold>Details about your upcoming managed change:</Bold>
                <ErrorModalList noSpacing warning>
                  <ErrorModalListItem>
                    <Bold>Current:</Bold> {renderPlanDetails(existingPlan)}
                  </ErrorModalListItem>
                  <ErrorModalListItem>
                    <Bold>Upcoming:</Bold> {renderPlanDetails(scheduledPlan)}
                  </ErrorModalListItem>
                  {isDateInvalid ? null : (
                    <ErrorModalListItem>
                      <Bold>Scheduled Date:</Bold>{' '}
                      {formatDate(scheduledPlan.changeDate, {
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric'
                      })}
                    </ErrorModalListItem>
                  )}
                </ErrorModalList>
              </div>
              <div>
                <Bold>What is a managed change?</Bold>
                <ErrorModalList noSpacing>
                  <ErrorModalListItem>
                    <Bold>Parsec-managed -</Bold> Managed changes are specific
                    subscription changes that do not have self-service access
                    today. They are made to your account, on your behalf, by
                    Parsec.
                  </ErrorModalListItem>
                  <ErrorModalListItem>
                    <Bold>Always scheduled -</Bold> Managed changes of this kind
                    are always scheduled to occur on a future date.
                  </ErrorModalListItem>
                  <ErrorModalListItem>
                    <Bold>Qualifying updates -</Bold> Managed changes may
                    include, but are not limited to, updates to your seat price,
                    scheduled increases in seats, the removal of free seats, or
                    the addition/removal of add-ons.
                  </ErrorModalListItem>
                </ErrorModalList>
              </div>
              <div>
                To complete your update:
                <ErrorModalList noSpacing>
                  <ErrorModalListItem>
                    Wait until <Bold>after the scheduled change date</Bold> and
                    try again.
                  </ErrorModalListItem>
                  <ErrorModalListItem>
                    <a
                      href={SUPPORT_LINKS.CONTACT_US}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      Contact Support
                    </a>{' '}
                    for assistance.
                  </ErrorModalListItem>
                </ErrorModalList>
              </div>
            </ErrorModalContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Cancel asChild>
                <Button>Got it</Button>
              </AlertModal.Cancel>
              <AlertModal.Action>
                <Button level="secondary" href={SUPPORT_LINKS.CONTACT_US}>
                  Contact Support
                </Button>
              </AlertModal.Action>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};
