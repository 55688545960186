import { useState } from 'react';

import { Checkbox, Input, styled } from '@parsec/components';
import { useGetTeamSubscription } from '@parsec/queries';

export function usePoNumberInputProps() {
  const { data: subscription } = useGetTeamSubscription();
  const [poNumber, setPoNumber] = useState(subscription?.po_number);

  const [usePoNumber, setUsePoNumber] = useState(
    Boolean(subscription?.po_number)
  );
  return {
    usePoNumber,
    poNumber,
    setUsePoNumber,
    setPoNumber
  };
}

export type PoNumberInputProps = ReturnType<typeof usePoNumberInputProps>;

export function PoNumberInput(props: PoNumberInputProps) {
  const { usePoNumber, poNumber, setUsePoNumber, setPoNumber } = props;
  return (
    <GridRow style={{ gap: '2.4rem' }}>
      <GridRow>
        <Checkbox
          id="po_number_checkbox"
          checked={usePoNumber}
          onChange={e => {
            setUsePoNumber(e.target.checked);
          }}
        />
        <Label htmlFor="po_number_checkbox">Use purchase order number</Label>
      </GridRow>
      <Input
        version="newFont"
        defaultValue={poNumber}
        placeholder="Insert PO number here"
        onChange={e => setPoNumber(e.target.value)}
        disabled={!usePoNumber}
      />
    </GridRow>
  );
}

const GridRow = styled('div', {
  display: 'grid',
  alignItems: 'center',
  gap: '$large',
  gridAutoFlow: 'column',
  gridTemplateColumns: 'max-content auto'
});

const Label = styled('label', {
  fontFamily: '$newDefault',
  fontSize: '$newBody'
});
