import type { FeedItem } from '@parsec/queries';

//Components
import Markdown from 'markdown-to-jsx';

import { styled, Modal, Button } from '@parsec/components';
import { formatDate } from '@parsec/time';

import { CHANGELOG_URL } from 'lib/config';
import { useViewChangelog } from 'lib/hooks';
import BugIcon from 'lib/images/changelog-bug.svg';
import ImprovementIcon from 'lib/images/changelog-improvements.svg';
import NewIcon from 'lib/images/changelog-new.svg';

interface ChangelogModalProps {
  isOpen: boolean;
  onClose(): void;
}

export default function ChangelogModal({
  isOpen,
  onClose
}: ChangelogModalProps) {
  const { allLogs, isSuccess } = useViewChangelog();
  if (isSuccess && allLogs.length >= 1) {
    const relatedBuilds = getRelatedBuilds(allLogs);

    const latestEntry = allLogs[0];

    // not destructuring latestEntry for better discoverability
    const descriptionText = `Changelog • ${
      latestEntry.build
        ? latestEntry.relatedBuild || latestEntry.build
        : latestEntry.title
    } ${latestEntry.releaseMessage ? ' • ' : ''}
    `;

    const description = (
      <div>
        {descriptionText}
        {latestEntry.releaseMessage && (
          <ReleaseMessage>{latestEntry.releaseMessage}</ReleaseMessage>
        )}
      </div>
    );

    return (
      <Modal
        title="What's new?"
        size="large"
        description={description}
        isOpen={isOpen}
        onClose={onClose}
      >
        {relatedBuilds ? (
          <RelatedBuilds {...relatedBuilds} />
        ) : (
          <LogWrapper>
            {latestEntry.changes.map(log => (
              <LogEntry
                key={log.title}
                title={log.title}
                build={latestEntry.build ?? latestEntry.title}
                date={latestEntry.pubDate}
                content={log.content}
              />
            ))}
          </LogWrapper>
        )}

        <Modal.Actions>
          <Button onClick={onClose}>Neat!</Button>
          <Button
            level="link"
            href={`${CHANGELOG_URL}?category=${latestEntry.category}`}
          >
            Read Other Entries
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
  return null;
}

const TITLE_ICONS = {
  bugfixes: BugIcon,
  new: NewIcon,
  improvements: ImprovementIcon
};

interface LogEntryProps {
  build?: string;
  title: string;
  date: FeedItem['pubDate'];
  content: FeedItem['changes'][number]['content'];
  isNew?: boolean;
}

const markdownProps = {
  overrides: {
    a: {
      props: {
        target: '_blank',
        rel: 'noopener noreferrer'
      }
    }
  }
};

const LogEntry = ({
  build,
  title,
  content,
  date,
  isNew = false,
  ...rest
}: LogEntryProps) => {
  const pubDate = new Date(date);

  return (
    <ChangeSection {...rest}>
      {title.toLowerCase() in TITLE_ICONS ? (
        <LeftDecoration>
          <Svg
            as={TITLE_ICONS[title.toLowerCase() as keyof typeof TITLE_ICONS]}
          />
          <SideLine />
        </LeftDecoration>
      ) : null}
      <div>
        <ReleaseHeading>
          {title}
          {isNew ? <NewIndicator role="presentation" /> : null}
        </ReleaseHeading>
        <HGroup>
          <h3>
            {formatDate(pubDate, {
              month: 'short',
              day: 'numeric',
              year: 'numeric'
            })}
          </h3>
          <BuildSubhead>{build ?? title}</BuildSubhead>
        </HGroup>
        <List showMarker={content.length > 1}>
          {content.map((item, idx) => {
            if (typeof item === 'object') {
              return (
                <ListItem key={idx}>
                  {item['#text']}
                  <List showMarker="sub">
                    {item.subcontent.map(subContent => (
                      <ListItem key={subContent}>
                        <Markdown options={markdownProps}>
                          {subContent}
                        </Markdown>
                      </ListItem>
                    ))}
                  </List>
                </ListItem>
              );
            }
            return (
              <ListItem key={idx}>
                <Markdown options={markdownProps}>{item}</Markdown>
              </ListItem>
            );
          })}
        </List>
      </div>
    </ChangeSection>
  );
};

const RelatedBuilds = ({
  parent,
  siblings
}: {
  parent?: FeedItem;
  siblings: FeedItem[];
}) => {
  return (
    <LogWrapper>
      {siblings.map((logItem, index) =>
        logItem.changes.map(change => (
          <LogEntry
            isNew={index === 0} // First sibling is the newest entry
            date={logItem.pubDate}
            key={change.title}
            title={change.title}
            build={logItem.build}
            content={change.content}
          />
        ))
      )}
      {parent &&
        parent.changes.map(change => (
          <LogEntry
            key={change.title}
            title={change.title}
            build={parent.build}
            date={parent.pubDate}
            content={change.content}
          />
        ))}
    </LogWrapper>
  );
};

function getRelatedBuilds(feed: FeedItem[], index = 0) {
  if (feed.length === 0) {
    return undefined;
  }

  const relatedBuild = feed[index].relatedBuild;
  if (relatedBuild === undefined) {
    return undefined;
  }

  // Since feed is sorted by release
  // Siblings are already sorted by latest -> earliest
  const siblingBuilds = feed.filter(
    (item: FeedItem) => item.relatedBuild === relatedBuild
  );

  const parentBuild = feed.find(
    (item: FeedItem) => item.build === relatedBuild
  );

  return {
    parent: parentBuild,
    siblings: siblingBuilds
  };
}

// Styled components
const Svg = styled('svg', {
  margin: '$small 0',
  lineHeight: '$body',
  width: '2rem',
  height: '2rem'
});

const HGroup = styled('hgroup', {
  display: 'inline-flex',
  lineHeight: '$info',
  fontSize: '1.2rem',
  color: '$rhyhorn',

  '& > h3': {
    lineHeight: '$info',
    fontWeight: '$bold',
    display: 'inline-flex'
  }
});

const BuildSubhead = styled('p', {
  '&::before': {
    position: 'relative',
    content: ' • ',
    whiteSpace: 'pre'
  }
});

const NewIndicator = styled('span', {
  display: 'inline-flex',
  backgroundColor: '$primary500',
  margin: 'auto 0.7rem',
  height: '0.9rem',
  width: '0.9rem',
  borderRadius: '100%',
  opacity: 1,
  transition: 'opacity 550ms ease'
});

const ReleaseHeading = styled('h2', {
  position: 'relative',
  lineHeight: '$attribution',
  fontSize: '$body',
  fontWeight: '$bold',
  textTransform: 'uppercase',
  color: '$consoleWhite'
});

const ChangeSection = styled('section', {
  display: 'flex',
  paddingBottom: '2.9rem'
});

const LogWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxHeight: '57rem',
  maxWidth: '58rem'
});

const List = styled('ul', {
  listStylePosition: 'inside',
  lineHeight: '$attribution',
  paddingTop: '0.3rem',
  '& &': {
    paddingLeft: '1.5rem'
  },

  variants: {
    showMarker: {
      true: { listStyleType: "'• '" },
      sub: {
        listStyleType: "'↳ '"
      }
    }
  }
});

const ListItem = styled('li', {
  '& + &': { paddingTop: '0.3rem' }
});

const LeftDecoration = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingRight: '1rem'
});

const SideLine = styled('div', {
  width: '0.1rem',
  position: 'relative',
  margin: '0 1rem',
  backgroundColor: '$rhyhorn',

  height: '100%',

  '&::before': {
    position: 'absolute',
    top: 0,
    left: '-0.1rem',
    content: '',
    width: '0.3rem',
    height: '0.3rem',
    backgroundColor: '$rhyhorn',
    borderRadius: '50%'
  }
});

const ReleaseMessage = styled('span', {
  fontStyle: 'italic',
  color: '#FFAD11' //no token
});
