import type { ComponentProps } from 'react';

import {
  Button,
  Icon,
  MultiSelect,
  MultiSelectItem,
  MultiSelectItemGroup,
  styled
} from '@parsec/components';

export type FilterButtonProps<T> = ComponentProps<
  typeof FilterButtonWrapper
> & {
  indicator?: boolean;
  filters: MultiSelectItem<T>[] | MultiSelectItemGroup<T>[];
  onSelect?(
    selectedItem: MultiSelectItem<T>,
    selectedItems: MultiSelectItem<T>[]
  ): void;
  onDeselect?(
    selectedItem: MultiSelectItem<T>,
    selectedItems: MultiSelectItem<T>[]
  ): void;
};

export function FilterButton<T>(props: FilterButtonProps<T>) {
  const { onSelect, onDeselect, filters, ...rest } = props;
  return (
    <MultiSelect
      onSelect={props.onSelect}
      onDeselect={props.onDeselect}
      items={props.filters}
    >
      {({ isOpen, props }) => {
        return (
          <FilterButtonWrapper
            level="secondary"
            open={isOpen}
            {...rest}
            {...props}
          >
            <FilterButtonContent>
              <div>
                {rest.indicator && <Indicator />}
                <Icon name="filter" />
              </div>{' '}
              <div>Filter</div>
            </FilterButtonContent>
          </FilterButtonWrapper>
        );
      }}
    </MultiSelect>
  );
}

const Indicator = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  marginTop: 8,
  marginLeft: 28,
  width: 8,
  height: 8,
  backgroundColor: '#EE3D30',
  borderRadius: '100%'
});

const FilterButtonWrapper = styled(Button, {
  display: 'inline-block',
  color: '$consoleWhite',
  '&:hover': {
    color: '$primary400',
    backgroundColor: '$pangoro'
  },
  '&:focus': {
    color: '$primary400',
    backgroundColor: '$pangoro'
  },
  '&:active': {
    color: '$primary400',
    backgroundColor: '$pangoro'
  },
  variants: {
    open: {
      true: {
        color: '$primary400',
        backgroundColor: '$pangoro'
      },
      false: {}
    }
  }
});

const FilterButtonContent = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  columnGap: '$medium',
  alignItems: 'center'
});
