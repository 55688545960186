import { styled } from '@parsec/components';

export const FormWrapper = styled('div', {
  borderRadius: '$medium',
  backgroundColor: '$carkol',

  variants: {
    padding: {
      true: {
        padding: '$xxlarge'
      },
      false: {}
    },
    background: {
      transparent: {
        backgroundColor: 'transparent'
      }
    }
  }
});
