import { styled } from '@parsec/components';

export const Section = styled('section', {
  stack: {
    direction: 'vertical',
    gap: '$xlarge'
  },
  '&:not(:first-of-type)': {
    marginTop: '4.8rem',
    paddingTop: '4.8rem',
    boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
  },
  variants: {
    selfserve: {
      true: {
        maxWidth: '94rem',
        alignSelf: 'center',
        width: '100%'
      }
    },
    border: {
      true: {
        paddingTop: '4.8rem',
        boxShadow: 'inset 0 0.1rem 0 $colors$pancham'
      },
      false: {}
    },
    borderless: {
      true: {
        paddingTop: '0 !important',
        boxShadow: 'none !important'
      },
      false: {}
    }
  }
});
