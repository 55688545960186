// Subscription billing intervals
export const INTERVAL_YEARLY = 'year';
export const INTERVAL_MONTHLY = 'month';

// Plan IDs
export const TEAM_MONTHLY_CB_PLAN_ID = 'team-monthly';
export const TEAM_YEARLY_CB_PLAN_ID = 'team-yearly';
export const TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID = 'team-enterprise-monthly';
export const TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID = 'team-enterprise-yearly';

export type PlanId =
  | typeof TEAM_MONTHLY_CB_PLAN_ID
  | typeof TEAM_YEARLY_CB_PLAN_ID
  | typeof TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID
  | typeof TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID;

const CB_TEAM_SUB_PLAN_ID = {
  enterprise: {
    [INTERVAL_MONTHLY]: TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID,
    [INTERVAL_YEARLY]: TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID
  },
  standard: {
    [INTERVAL_MONTHLY]: TEAM_MONTHLY_CB_PLAN_ID,
    [INTERVAL_YEARLY]: TEAM_YEARLY_CB_PLAN_ID
  }
};
export const getChargebeePlanID = (
  interval: typeof INTERVAL_MONTHLY | typeof INTERVAL_YEARLY,
  isEnterprise: boolean
) =>
  isEnterprise
    ? CB_TEAM_SUB_PLAN_ID.enterprise[interval]
    : CB_TEAM_SUB_PLAN_ID.standard[interval];

/**
 * Checks if a given plan is annual (yearly) term/interval.
 * @param planId - The ID of the plan to check.
 * @returns A boolean indicating whether the plan is annual or not.
 */
export const checkIsAnnual = (planId?: PlanId) => {
  // if no planId, default to monthly
  if (!planId) return false;
  return (
    planId === TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID ||
    planId === TEAM_YEARLY_CB_PLAN_ID
  );
};

/**
 * Checks if a given plan ID is an enterprise subscription.
 * @param planId - The plan ID to check.
 * @returns A boolean indicating whether the plan ID is an enterprise subscription.
 */
export const checkIsEnterprise = (planId?: PlanId) => {
  if (!planId) return false;
  return (
    planId === TEAM_ENTERPRISE_MONTHLY_CB_PLAN_ID ||
    planId === TEAM_ENTERPRISE_YEARLY_CB_PLAN_ID
  );
};

// Product and Term Types
export enum ProductType {
  Teams = 'teams',
  Enterprise = 'enterprise'
}

export enum TermType {
  Monthly = INTERVAL_MONTHLY,
  Yearly = INTERVAL_YEARLY
}

/**
 * Determines the term type based on the provided plan ID.
 * If no plan ID is provided, it defaults to monthly.
 * @param planId - The ID of the plan.
 * @returns The term type (monthly or yearly).
 */
export const planToTermType = (planId?: PlanId): TermType => {
  if (!planId) return TermType.Monthly; // default to monthly
  return checkIsAnnual(planId) ? TermType.Yearly : TermType.Monthly;
};

/**
 * Converts a term string to a TermType.
 * @param term - The term string to convert. Must be either 'month' or 'year'. Often used with billing_interval from the API.
 * @returns The corresponding TermType.
 */
export const asTermType = (term: string): TermType => {
  if (term !== 'month' && term !== 'year') {
    // eslint-disable-next-line no-console
    console.error(`Invalid term type: ${term}`);
  }
  // safer than casting with as
  // since we only have two terms, we can just check if it's not monthly
  return term === TermType.Monthly ? TermType.Monthly : TermType.Yearly;
};

/**
 * Converts a plan ID to a product type.
 * @param planId The ID of the plan.
 * @returns The corresponding product type.
 */
export const asProductType = (planId: PlanId): ProductType => {
  return checkIsEnterprise(planId) ? ProductType.Enterprise : ProductType.Teams;
};
