import { useEffect, useRef, useState } from 'react';

import {
  type TurnstileInstance,
  CaptchaWidget as BaseCaptchaWidget,
  Button,
  CaptchaActions,
  Dropdown,
  Icon,
  Modal,
  TextArea,
  Tip,
  styled
} from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import {
  useCreateTeamInvites,
  useGetAllAppRules,
  useGetTeam
} from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { TURNSTILE_SITE_KEY } from 'lib/config';
import { useGetGroupsFromPerms } from 'lib/hooks';
import { emailIsValid } from 'lib/util/email';

import { Alerts } from 'components';

const version = 'newFont';

export interface InviteTeamMembersModalProps {
  isOpen: boolean;
  onClose(): void;
}

export function InviteTeamMembersModal(props: InviteTeamMembersModalProps) {
  // 🚨 Alert
  const { show } = useAlertContext();

  // Team capacity
  const getTeamQuery = useGetTeam();
  const team = getTeamQuery.data;
  const totalSeats = team?.capabilities.total_seats ?? 0;
  const numMembers = team?.num_members ?? 0;
  const numInvites = team?.num_invites ?? 0;
  const seatsFilled = numMembers + numInvites;
  const seatsLeft = totalSeats - seatsFilled;
  const isTeamFull = seatsLeft < 1;

  // Invites hook
  const createTeamInvites = useCreateTeamInvites();

  // Permissions & Groups
  const selectableGroups = useGetGroupsFromPerms(['invite_team_members']);
  const [groupID, setGroupId] = useState(selectableGroups[0]?.id);
  const canSelectGroup = Boolean(selectableGroups.length);

  const group =
    selectableGroups.find(g => g.id === groupID) ?? selectableGroups[0];

  // App Rules
  const allAppRulesQuery = useGetAllAppRules();

  const [allAppRules, setAllAppRules] = useState(
    allAppRulesQuery.data?.data ?? []
  );
  const [appRule, setAppRule] = useState(allAppRules[0]);
  const defaultAppRule = allAppRules.find(ar => ar.is_default);

  const isSAMLEnforced =
    appRule?.enforce_saml ?? defaultAppRule?.enforce_saml ?? false;
  useEffect(() => {
    if (allAppRulesQuery.data?.data === undefined) {
      return;
    }
    const appRules = allAppRulesQuery.data?.data;
    setAllAppRules(appRules);
    setAppRule(appRules[0]);
  }, [allAppRulesQuery.data]);

  // CAPTCHA
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);

  return (
    <Modal
      title="Invite to Team"
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
      }}
      version={version}
    >
      <Alerts showHere version={version} />
      <Using>
        You are currently using <strong>{seatsFilled}</strong> out of{' '}
        <strong>{totalSeats}</strong> seats.
      </Using>
      <StyledForm
        id="add_members_to_team_form"
        method="post"
        onSubmit={handleFormSubmit<{
          emails: string;
        }>(async values => {
          try {
            let emails = values.emails
              .trim()
              .replace(/[,;]/g, ' ')
              .split(/\s+/)
              .map(email => email.trim())
              .filter(email => !!email);
            emails = [...new Set(emails)];
            const invalidEmails = emails.filter(email => !emailIsValid(email));
            if (invalidEmails.length) {
              show({
                type: 'error',
                title: 'Could not send invites',
                message: `Please fix the following invalid email addresses and try again: ${invalidEmails.join(
                  ', '
                )}`,
                version
              });
              return;
            }
            if (emails.length > seatsLeft) {
              show({
                type: 'error',
                title: 'Could not send invites',
                version,
                message: `You are trying to create ${emails.length} invites, but you only have ${seatsLeft} seats left. Please remove some email addresses or increase the number of seats on your team.`
              });
              return;
            }
            await createTeamInvites.mutateAsync({
              emails,
              group_ids: groupID ? [groupID] : undefined,
              team_app_rule_id: appRule?.id,
              captcha_token: captchaToken
            });
            props.onClose();
          } catch (err) {
            const res = parseError(err, { error: "Couldn't send invites." });
            show({
              type: 'error',
              title: 'Error',
              message: res.error,
              version
            });
          } finally {
            // Reset the captcha widget so we are granted a new token
            setCaptchaToken('');
            captchaRef.current?.reset();
          }
        })}
      >
        {isSAMLEnforced && (
          <Warning>
            The selected app rule enforces SAML. To invite new members, please
            add them through your SAML iDP then notify them directly. The seat
            will be counted the first time they log in to Parsec using SAML.
          </Warning>
        )}
        {isTeamFull && (
          <Warning>
            Your team is full. You must add more seats before you can invite
            more members.
          </Warning>
        )}
        <p>
          Add email addresses separated by a comma, space or a new line to
          invite people to your team. Anyone without a Parsec account will be
          prompted to create one.
        </p>
        <Wrapper>
          <TextArea
            required
            name="emails"
            placeholder="me@myemail.com, imhaving@fun.com, …"
            rows={2}
            disabled={isTeamFull}
            version={version}
          />
        </Wrapper>
        <Tip version={version}>
          <strong>Tip: </strong>Pending invites will reserve a seat from your
          total seat count.
        </Tip>
        <Actions>
          {canSelectGroup && group && (
            <Action>
              <StyledLabel>Assign to Group</StyledLabel>
              <Dropdown
                version={version}
                defaultValue={`${group.name}`}
                items={selectableGroups.map(group => ({
                  text: group.name,
                  value: `${group.id}`,
                  onSelect() {
                    setGroupId(group.id);
                  }
                }))}
              >
                {({ props, isOpen }) => (
                  <Button
                    version={version}
                    {...props}
                    type="button"
                    level="secondary"
                    icon={<ArrowIcon name="caret" open={isOpen} />}
                  >
                    {group.name ?? ''}
                  </Button>
                )}
              </Dropdown>
            </Action>
          )}
          {allAppRules && appRule && (
            <Action>
              <StyledLabel>Assign to Ruleset</StyledLabel>
              <Dropdown
                defaultValue={`${appRule.name}`}
                items={allAppRules.map(appRule => ({
                  text: appRule.name,
                  value: `${appRule.id}`,
                  onSelect() {
                    setAppRule(appRule);
                  }
                }))}
              >
                {({ props, isOpen }) => (
                  <Button
                    version={version}
                    {...props}
                    type="button"
                    level="secondary"
                    icon={<ArrowIcon name="caret" open={isOpen} />}
                  >
                    {appRule.name ?? ''}
                  </Button>
                )}
              </Dropdown>
            </Action>
          )}
        </Actions>
        <CaptchaWidget
          ref={captchaRef}
          action={CaptchaActions.InviteTeamMember}
          siteKey={TURNSTILE_SITE_KEY}
          onSuccess={(token: string) => {
            setCaptchaToken(token);
          }}
        />
      </StyledForm>
      <Modal.Actions>
        <Button
          version={version}
          disabled={
            createTeamInvites.isLoading ||
            isTeamFull ||
            isSAMLEnforced ||
            !captchaToken.length
          }
          form="add_members_to_team_form"
        >
          {createTeamInvites.isLoading ? 'Sending...' : 'Send Invites'}
        </Button>
        <Button
          version={version}
          onClick={() => {
            props.onClose();
          }}
          level="secondary"
        >
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

const StyledForm = styled('form', {
  display: 'grid',
  gridAutoFlow: 'row',
  gridGap: '1.2rem',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$attribution'
});

const Wrapper = styled('div', {
  overflow: 'visible',
  marginBottom: '0.6rem'
});

const Box = styled('div', {
  borderRadius: '0.3rem',
  backgroundColor: 'rgba(24, 24, 26, 0.298)',
  padding: '2rem',
  marginTop: '2rem',
  fontFamily: '$newDefault',
  fontSize: '$newBody'
});

const Using = styled(Box, {
  textAlign: 'center',
  marginBottom: '2rem'
});

const Warning = styled(Box, {
  color: '#18181a',
  background: '#f4da1e',
  textAlign: 'center',
  marginBottom: '2rem'
});

const Actions = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  gap: '$xxxlarge',
  overflow: 'visible',
  marginBottom: '0.6rem',
  marginLeft: 'auto'
});

const Action = styled('div', {
  display: 'grid',
  columnGap: '$medium',
  gridAutoFlow: 'column',
  alignItems: 'center',
  gridTemplateColumns: '1fr 1.2fr'
});

const StyledLabel = styled('label', {
  fontSize: '$newbody',
  lineHeight: '$body'
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const CaptchaWidget = styled(BaseCaptchaWidget, {
  justifyContent: 'flex-start'
});
