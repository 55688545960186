import { useRef, useState } from 'react';

import {
  type TurnstileInstance,
  AlertModal,
  Button,
  CaptchaActions,
  CaptchaWidget,
  styled
} from '@parsec/components';

import { TURNSTILE_SITE_KEY } from 'lib/config';

const newFont = {
  fontFamily: '$newDefault'
};

const Title = styled(AlertModal.Title, newFont);

const Description = styled(AlertModal.Description, newFont);

interface ResendTeamInviteModalProps {
  children: ReactNode;
  onResend(values: string): void;
}

const ResendTeamInviteModal = ({
  children,
  onResend
}: ResendTeamInviteModalProps) => {
  const [captchaToken, setCaptchaToken] = useState('');
  const captchaRef = useRef<TurnstileInstance | null>(null);

  return (
    <AlertModal>
      <AlertModal.Trigger asChild>{children}</AlertModal.Trigger>
      <AlertModal.Portal>
        <AlertModal.Overlay>
          <AlertModal.Content size="small">
            <AlertModal.Header>
              <Title>Security Check</Title>
              <Description>Verify that you are human to proceed.</Description>
            </AlertModal.Header>
            <AlertModal.ContentWrapper>
              <CaptchaWidget
                ref={captchaRef}
                action={CaptchaActions.ResendInvite}
                siteKey={TURNSTILE_SITE_KEY}
                onSuccess={(token: string) => {
                  setCaptchaToken(token);
                }}
              />
            </AlertModal.ContentWrapper>
            <AlertModal.Footer>
              <AlertModal.Action asChild>
                <Button
                  version="newFont"
                  disabled={!captchaToken.length}
                  onClick={() => {
                    try {
                      onResend(captchaToken);
                    } finally {
                      // Reset the captcha widget so we are granted a new token
                      setCaptchaToken('');
                      captchaRef.current?.reset();
                    }
                  }}
                >
                  Resend Invite
                </Button>
              </AlertModal.Action>
              <AlertModal.Cancel asChild>
                <Button version="newFont" level="secondary">
                  Cancel
                </Button>
              </AlertModal.Cancel>
            </AlertModal.Footer>
          </AlertModal.Content>
        </AlertModal.Overlay>
      </AlertModal.Portal>
    </AlertModal>
  );
};

export default ResendTeamInviteModal;
