import {
  useGetTeamBillingCard,
  useGetTeamBillingDetails,
  useGetTeamSubscription
} from '@parsec/queries';

import { CARD_STATUS_VALID } from 'constants/payment';

export function useHasInvalidCard() {
  const { data: billingDetails } = useGetTeamBillingDetails();
  const { error: cardError } = useGetTeamBillingCard();
  // invoiced customers have auto_collection=false
  const isInvoicedCustomer = !billingDetails?.invoice_config.auto_collection;

  const hasInvalidCard =
    billingDetails?.card_status !== CARD_STATUS_VALID || !!cardError;
  return !isInvoicedCustomer && hasInvalidCard;
}

export function useHasLateCardPayment() {
  const { data: billingDetails } = useGetTeamBillingDetails();
  const { data: subscription } = useGetTeamSubscription();

  // creditcard customer === auto_collection=true && more than 1 due invoices
  const isCreditCardCustomer = billingDetails?.invoice_config.auto_collection;
  return isCreditCardCustomer && Number(subscription?.due_invoices_count) > 0;
}

export function useHasLateInvoicePayment() {
  const { data: billingDetails } = useGetTeamBillingDetails();
  const { data: subscription } = useGetTeamSubscription();

  const isInvoicedCustomer = !billingDetails?.invoice_config.auto_collection;

  return isInvoicedCustomer && Number(subscription?.due_invoices_count) > 0;
}

export function useHasLatePayment() {
  const { data: subscription } = useGetTeamSubscription();
  const { data: billingDetails } = useGetTeamBillingDetails();
  // TODO: remove isInvoicedCustomer, we now have state defined for late payment
  const isInvoicedCustomer = !billingDetails?.invoice_config.auto_collection;
  // this is a temporary fix while we work out quickbooks sync because we might erroneously show that if a customer has paid but we haven't manually updated quickbooks
  return isInvoicedCustomer
    ? false
    : Number(subscription?.due_invoices_count) > 0;
}
