import {
  createContext,
  useContext,
  ReactNode,
  useMemo,
  useEffect,
  useState
} from 'react';

import { boolean } from 'narrows';

import { useStickyState } from '@parsec/hooks';
import { Downtime } from '@parsec/kessel';
import { captureException } from '@parsec/sentry';

import { noop, storageAvailable } from '../../utils';
import { IncidentStatus } from '../SharedType';

interface ContextProps {
  incidentStatus: IncidentStatus;
  incidentText: string | JSX.Element;
  incidentTitle?: string;
  isDismissible: boolean;
  onIncidentClose: () => void;
  showIncidentBanner: boolean;
}

export const Context = createContext<ContextProps>({
  incidentStatus: IncidentStatus.Positive,
  incidentText: '',
  isDismissible: false,
  onIncidentClose: noop,
  showIncidentBanner: false
});

export const useIncidentNotification = () => {
  const context = useContext(Context);
  if (!context) {
    throw new Error(
      'useIncidentNotification must be used within an IncidentNotificationContext.Provider. Wrap a parent component in <IncidentNotificationProvider> to fix this error.'
    );
  }
  return context;
};

export interface IncidentNotificationContextProps {
  children: ReactNode;
  incidentCount: number;
  isDismissible: boolean;
  isDisplayed: boolean;
  status: IncidentStatus;
  text: string | JSX.Element;
  externalDowntimeURL: string;
}

/**
 * Clears localStorage of previous incidents. Will delete all incidents PRIOR to `incidentCount` in localStorage.
 * @param currentCount number
 */
// UNCOMMENT WHEN YOU WANT TO CLEAR PREV INCIDENTS
function clearPrevIncidents(incidentCount: number) {
  if (storageAvailable('localStorage')) {
    for (let count = 0; count < incidentCount; count += 1) {
      window.localStorage.removeItem(`showIncidentBanner-${count}`);
    }
  } else {
    console.warn('No access to localStorage.');
  }
}

export const IncidentNotificationProvider = ({
  children,
  incidentCount,
  isDismissible,
  isDisplayed,
  status,
  text,
  externalDowntimeURL
}: IncidentNotificationContextProps) => {
  const [isOpen, setIsOpen] = useStickyState(
    isDisplayed,
    `showIncidentBanner-${incidentCount}`,
    boolean
  );

  const [externalDowntime, setExternalDowntime] = useState<Downtime>();

  useEffect(() => {
    async function getExternalDowntime(downtimeURL: string) {
      if (!downtimeURL) {
        return;
      }

      try {
        const res = await fetch(downtimeURL);

        if (res.status === 404) {
          // downtime will return a 404 status when there is no downtime.
          // so we'll do nothing.
        } else {
          const downtime: Downtime = await res.json();
          setExternalDowntime(downtime);
        }
      } catch (err) {
        // failed to fetch
        captureException(err, {
          tags: { component: 'IncidentNotificationProvider', downtimeURL }
        });
      }
    }

    getExternalDowntime(externalDowntimeURL);
  }, [setExternalDowntime, externalDowntimeURL]);

  const downtime = useMemo(() => {
    if (externalDowntime === undefined || !externalDowntime.display) {
      // No external downtime
      return {
        incidentStatus: status,
        incidentText: text,
        isDismissible,
        showIncidentBanner: isOpen
      };
    } else {
      return {
        incidentStatus:
          externalDowntime.type === 'error'
            ? IncidentStatus.Negative
            : IncidentStatus.Neutral,
        incidentText: (
          <p>
            {externalDowntime.message}
            <br />
            Click{' '}
            <a
              href={externalDowntime.link_url}
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{' '}
            for more information.
          </p>
        ),
        isDismissible: false,
        showIncidentBanner: true,
        incidentTitle: externalDowntime.title
      };
    }
  }, [status, text, isDismissible, externalDowntime, isOpen]);

  useEffect(() => {
    clearPrevIncidents(incidentCount);
  }, [incidentCount]);

  const value = useMemo(() => {
    function onIncidentClose() {
      if (downtime.isDismissible) {
        setIsOpen(!isOpen);
      }
    }

    return {
      incidentStatus: downtime.incidentStatus,
      incidentText: downtime.incidentText,
      incidentTitle: downtime.incidentTitle,
      isDismissible: downtime.isDismissible,
      onIncidentClose,
      showIncidentBanner: downtime.showIncidentBanner
    };
  }, [downtime, isOpen, setIsOpen]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
