/* eslint-disable @typescript-eslint/no-explicit-any */
//TODO: fix the anys
import { ReactNode, ComponentType } from 'react';

export interface ComposeProps {
  components: Array<ComponentType<any>>;
  children?: ReactNode;
}

export function Compose({ components, children }: ComposeProps) {
  return (
    <>
      {components.reduceRight((children, Component) => {
        return <Component>{children}</Component>;
      }, children)}
    </>
  );
}

export const compose = (
  ...components: ComponentType<any>[]
): ComponentType<any> => {
  // eslint-disable-next-line react/display-name
  return props => <Compose components={components}>{props.children}</Compose>;
};
