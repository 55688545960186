import { TeamRelay } from '@parsec/kessel';
import * as time from '@parsec/time';

// A healthy relay sends a heartbeat to kessel every 1 minute.
const HEARTBEAT_INTERVAL_MS = 60000;

export function isRelayActive(r: TeamRelay) {
  const msSinceLastBeat =
    time.now().getTime() - time.fromISO(r.last_heartbeat_received_at).getTime();
  /**
   * If kessel has received a heartbeat in the last 1 minute (inclusive), the relay is considered to be active (probably online).
   * Due to false negatives from potential race condition and reasonable blips, the UI will allow a relay to miss up to 5 minutes of heartbeat before it is considered missing.
   * UI polling the backend still occurs every 30 seconds.
   */
  return msSinceLastBeat <= HEARTBEAT_INTERVAL_MS * 5;
}

// Alternative to equality checks for readability
export function isRelayRegistered(r: TeamRelay) {
  return r.stun_address !== '';
}
