import { ReactElement, ReactNode } from 'react';

import * as Dialog from '@radix-ui/react-dialog';

import { keyframes, styled } from '@parsec/styles';

import Checkbox from '../Checkbox';
import Icon from '../Icon';
import Input, { InputProps } from '../Input';

const Tr = styled('tr', {});

const Th = styled('th', {
  fontFamily: '$newDefault',
  '&[scope="row"]': {
    paddingLeft: '2rem',
    textAlign: 'left'
  }
});

const Td = styled('td', {
  fontFamily: '$newDefault',
  fontSize: '1.2rem',
  fontWeight: '$normal',
  lineHeight: '$attribution',
  textAlign: 'left',
  paddingTop: '1.2rem',

  variants: {
    xtraPadding: {
      top: {
        paddingTop: '1.6rem'
      }
    },
    price: {
      true: {
        textAlign: 'right',
        color: '$rhyhorn'
      }
    }
  }
});

const Table = styled('table', {
  minWidth: '62rem',
  backgroundColor: '$samehada',
  paddingBottom: '0.6rem',
  '& tr td': { paddingRight: '2rem' },
  '& tr:last-of-type td': {
    paddingRight: '2rem',
    paddingBottom: '1.6rem'
  }
});

const Tfoot = styled('tfoot', {
  backgroundColor: '$cereza',
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$bold',
  lineHeight: '$attribution'
});

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 }
});

const DialogOverlay = styled(Dialog.Overlay, {
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  background: 'rgba(0 0 0 / 0.5)',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  display: 'grid',
  placeItems: 'center',
  overflowY: 'auto',
  fontFamily: '$newDefault',
  zIndex: 1 // needed for teams app
});

const DialogContent = styled(Dialog.Content, {
  fontFamily: '$newDefault',
  display: 'grid',
  borderRadius: '$xlarge',
  minWidth: '30rem',
  backgroundColor: '$computerBlack',
  maxWidth: '62rem'
});

const DialogTitle = styled(Dialog.Title, {
  fontFamily: '$newDefault',
  color: '$ultraDark',
  fontSize: '$subtitle',
  fontWeight: '$bold',
  lineHeight: '2.9rem',
  letterSpacing: 0
});

const HeaderCloseWrapper = styled('div', {
  position: 'absolute',
  top: '2.1rem',
  right: '2.1rem'
});

const DialogDescription = styled(Dialog.Description, {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  fontWeight: '$normal',
  lineHeight: '$attribution',
  color: '$ultraDark'
});

// make sure to use a `h2` element inside
const DialogHeader = styled('div', {
  fontFamily: '$newDefault',
  position: 'relative',
  padding: '$xxlarge $xxlarge 1.7rem $xxlarge',
  borderRadius: '$xlarge $xlarge 0 0',
  backgroundColor: '$primary500'
});

const DialogFooter = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$xxlarge',
  padding: '$xxlarge',
  backgroundColor: '$samehada',
  height: '7.6rem',
  borderRadius: '0 0 $xlarge $xlarge',
  fontFamily: '$newDefault'
});

const LegalCopy = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '1rem', // no token available
  fontWeight: '$normal',
  color: '$rhyhorn',
  lineHeight: '$info',
  padding: '0 $xxlarge'
});

interface ModalHeaderProps {
  children: ReactNode | ReactElement;
}

const ModalHeader = ({ children }: ModalHeaderProps) => (
  <DialogHeader>
    {children}
    <HeaderCloseWrapper>
      <Dialog.Close>
        <Icon name="ex" />
      </Dialog.Close>
    </HeaderCloseWrapper>
  </DialogHeader>
);

/** PoINPUT COMPONENTS */
const PoWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '$xlarge $xxlarge',
  fontFamily: '$newDefault'
});

const Flex = styled('div', {
  fontFamily: '$newDefault',
  display: 'flex',
  alignItems: 'center',
  gap: '$large'
});

const PoInput = styled(Input, {
  fontFamily: '$newDefault',
  flex: '0 1 22rem'
});

interface PoInputProps {
  className?: string;
  checked?: boolean;
  inputOptions?: InputProps;
  readOnly?: boolean;
  onCheckboxChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * PO Number check box and input. Can pass input specific props via props.inputOptions.
 * @param props
 * @returns JSX.Element
 */
const PoNumber = ({
  className,
  checked = false,
  inputOptions,
  readOnly = false,
  onCheckboxChange
}: PoInputProps) => {
  return (
    <PoWrapper className={className}>
      <Flex>
        <Checkbox
          id="po_number"
          checked={checked}
          readOnly={readOnly}
          onChange={onCheckboxChange}
        />
        <label htmlFor="po_number">Assign purchase order number</label>
      </Flex>
      <PoInput
        placeholder="Enter PO number"
        disabled={!checked}
        {...inputOptions}
      />
    </PoWrapper>
  );
};

export interface PurchaseModalProps extends Dialog.DialogProps {
  trigger?: React.ReactNode | ReactElement;
}

export function PurchaseModal({
  children,
  defaultOpen,
  onOpenChange,
  open,
  trigger
}: PurchaseModalProps) {
  return (
    <Dialog.Root
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
      open={open}
    >
      {trigger ? <Dialog.Trigger asChild>{trigger}</Dialog.Trigger> : null}
      <Dialog.Portal>
        <DialogOverlay>{children}</DialogOverlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

PurchaseModal.Header = ModalHeader;
PurchaseModal.Footer = DialogFooter;
PurchaseModal.Title = DialogTitle;
PurchaseModal.Content = DialogContent;
PurchaseModal.Description = DialogDescription;
PurchaseModal.Close = Dialog.Close;

PurchaseModal.PoNumber = PoNumber;
PurchaseModal.LegalCopy = LegalCopy;
PurchaseModal.Table = Table;
PurchaseModal.Th = Th;
PurchaseModal.Tr = Tr;
PurchaseModal.Td = Td;
PurchaseModal.Tfoot = Tfoot;
