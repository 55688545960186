import { useEffect } from 'react';

import Router from 'next/router';

/**
 * This hook stops routing if unsavedChanges is true, it asks for confirmation * from the callback
 * @param unsavedChanges
 * @param callback
 */
const useWarnIfUnsavedChanges = (
  unsavedChanges: boolean,
  callback: () => boolean
) => {
  useEffect(() => {
    if (unsavedChanges) {
      const routeChangeStart = () => {
        const ok = callback();
        if (!ok) {
          Router.events.emit('routeChangeError');
          throw 'Abort route change. Please ignore this error.';
        }
      };
      Router.events.on('routeChangeStart', routeChangeStart);

      return () => {
        Router.events.off('routeChangeStart', routeChangeStart);
      };
    }

    return;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unsavedChanges]);
};

export default useWarnIfUnsavedChanges;
