import { ReactNode } from 'react';

import { Button, Modal } from '@parsec/components';

interface KickUserModalProps {
  user: string | ReactNode;
  computer: string;
  isOpen: boolean;
  onClose(): void;
  onSubmit(): void;
  version?: 'newFont';
}

export default function KickUserModal(props: KickUserModalProps) {
  const { user, computer, isOpen, onClose, onSubmit, version } = props;
  return (
    <Modal
      version={version}
      title="Kick User"
      description="This user will immediately be disconnected from their session."
      isOpen={isOpen}
      onClose={onClose}
    >
      <>
        <p>
          {user} will be kicked from {computer}. Are you sure you want to
          disconnect them?
        </p>
        <Modal.Actions>
          <Button
            version={version}
            kind="error"
            onClick={async () => {
              await onSubmit();
              onClose();
            }}
          >
            Kick
          </Button>
          <Button onClick={onClose} level="secondary" version={version}>
            Cancel
          </Button>
        </Modal.Actions>
      </>
    </Modal>
  );
}
