import { useCallback, useMemo } from 'react';

import { string } from 'narrows';

import { useStickyState } from '@parsec/hooks';
import { useChangelogQuery } from '@parsec/queries';

export function useViewChangelog() {
  const { data, isSuccess } = useChangelogQuery();

  const validator = string;

  const [viewedLogTitle, setViewedLogTitle] = useStickyState(
    '',
    'viewedLogTitle',
    validator
  );

  const onView = useCallback(() => {
    if (isSuccess) {
      if (viewedLogTitle !== '') {
        return; // already seen
      }
      setViewedLogTitle(data.allLogs[0]?.title);
    }
  }, [data, isSuccess, setViewedLogTitle, viewedLogTitle]);

  return useMemo(
    () => ({
      isSuccess,
      newLogAvailable: viewedLogTitle === '',
      onView,
      lastUpdatedDate: isSuccess ? new Date(data.lastBuildDate) : null,
      allLogs: data?.allLogs ?? []
    }),
    [data, isSuccess, onView, viewedLogTitle]
  );
}
