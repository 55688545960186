import {
  useEffect,
  useState,
  ReactNode,
  useMemo,
  useCallback,
  createContext
} from 'react';

import { createPortal } from 'react-dom';
import { usePopper, PopperProps } from 'react-popper';

import { useMultiSelect, useDebounce } from '@parsec/hooks';
import { styled } from '@parsec/styles';

import Button from '../Button';
import Checkbox from '../Checkbox';
import Divider from '../Divider';
import Icon, { IconNames } from '../Icon';
import Input from '../Input';

export interface MultiSelectItem<T> {
  icon?: IconNames;
  text: ReactNode;
  value: T;
  key?: string;
  defaultSelected?: boolean;
  type?: 'neutral' | 'negative';
  disabled?: boolean;
  indeterminate?: boolean;
}

export interface MultiSelectItemGroup<T> {
  label?: string;
  items: MultiSelectItem<T>[];
}

export type MultiSelectChildrenFunction<T> = (options: {
  isOpen: boolean;
  props: object;
  selectedItems: MultiSelectItem<T>[];
  addSelectedItem(item: MultiSelectItem<T>): void;
  removeSelectedItem(item: MultiSelectItem<T>): void;
}) => ReactNode;

export interface MultiSelectAction {
  text: string;
  onClick?(): void;
  level?: 'primary' | 'secondary' | 'link';
  kind?: 'neutral' | 'success' | 'error';
  disabled?: boolean;
  form?: string;
}

export interface MultiSelectProps<T> {
  className?: string;
  y?: number;
  x?: number;
  placement?: PopperProps<unknown>['placement'];
  title?: string;
  showActions?: boolean;
  showSearchbar?: boolean;
  items: MultiSelectItem<T>[] | MultiSelectItemGroup<T>[];
  onSelect?(
    selectedItem: MultiSelectItem<T>,
    selectedItems: MultiSelectItem<T>[]
  ): void;
  onDeselect?(
    selectedItem: MultiSelectItem<T>,
    selectedItems: MultiSelectItem<T>[]
  ): void;
  onReset?(): void;
  onSubmit?(selection: Set<T>): void;
  children: MultiSelectChildrenFunction<T>;
  version?: 'newFont';
}

const MultiSelectContext = createContext<HTMLElement | null>(null);

function isItemGroup<T>(
  arr: MultiSelectItem<T>[] | MultiSelectItemGroup<T>[] | undefined
): arr is MultiSelectItemGroup<T>[] {
  if (!arr) return false;
  return arr.length !== 0 && 'items' in arr[0];
}

function deepCopy<T>(arr: MultiSelectItem<T>[] | MultiSelectItemGroup<T>[]) {
  if (!arr || arr.length === 0) {
    return [];
  }
  if (isItemGroup(arr)) {
    return arr.map(itemGroup => ({
      ...itemGroup,
      // deep copy nested obj
      items: itemGroup.items.map(i => ({ ...i }))
    })) as MultiSelectItemGroup<T>[];
  }
  return arr.map(i => ({ ...i })) as MultiSelectItem<T>[];
}

export default function MultiSelect<T>(props: MultiSelectProps<T>) {
  const {
    x = 0,
    y = 8,
    placement = 'bottom-start',
    showActions = true,
    showSearchbar = true,
    onSubmit,
    children,
    version
  } = props;
  // Items
  const [items, setItems] = useState(deepCopy(props.items));

  const groupedItems =
    items.length && isItemGroup(items)
      ? items
      : ([{ items }] as MultiSelectItemGroup<T>[]);

  const flattenedItems = groupedItems.reduce<MultiSelectItem<T>[]>(
    (list, group) => {
      return [...list, ...group.items];
    },
    []
  );

  const initialSelectedItems = flattenedItems.filter(
    item => item.defaultSelected
  );

  // Search query
  const [queryString, setQueryString] = useState('');
  const onDebouncedInput = useDebounce(setQueryString, { ms: 500 });

  const filteredGroupItems = groupedItems.map(itemGroup => ({
    ...itemGroup,
    items: itemGroup.items
  }));
  //Actions
  const [portal, setPortal] = useState<HTMLElement | null>(null);

  // Popover
  const [toggle, setToggle] = useState<HTMLElement | null>(null);
  const [popover, setPopover] = useState<HTMLElement | null>(null);

  const offset: [number, number] =
    placement.startsWith('left') || placement.startsWith('right')
      ? [y, x]
      : [x, y];

  const { styles, attributes, forceUpdate } = usePopper(toggle, popover, {
    placement,
    modifiers: [{ name: 'flip' }, { name: 'offset', options: { offset } }]
  });

  const {
    isOpen,
    selectedItems,
    selectedValues,
    highlightedIndex,
    getDropdownProps,
    getToggleButtonProps,
    toggleMenu,
    addSelectedItem,
    removeSelectedItem,
    getMenuProps,
    setHighlightedIndex,
    getItemProps,
    resetSelections
  } = useMultiSelect<T, MultiSelectItem<T>>({
    items: flattenedItems,
    initialSelectedItems,
    onForceUpdate: () => Promise.resolve().then(forceUpdate),
    onSelect: props.onSelect,
    onDeselect: props.onDeselect,
    isItemDisabled: item => Boolean(item?.disabled)
  });

  const toggleProps = getDropdownProps(
    getToggleButtonProps({
      ref: setToggle
    })
  );

  // index here to keep track of selections across nested arrays.
  // this value needs to be reinitialized on every render.
  let index = 0;

  const canSubmit = useMemo(() => {
    const hasIndeterminates = filteredGroupItems.some(group =>
      group.items.some(item => item.indeterminate)
    );

    const isUnchanged =
      initialSelectedItems.length === selectedValues.size &&
      initialSelectedItems.every(item => selectedValues.has(item.value));
    return !hasIndeterminates || !isUnchanged;
  }, [filteredGroupItems, initialSelectedItems, selectedValues]);

  useEffect(() => {
    if (forceUpdate) {
      Promise.resolve().then(forceUpdate);
    }
    setQueryString('');
  }, [forceUpdate, isOpen]);

  useEffect(() => {
    if (!isOpen) setItems(deepCopy(props.items));
  }, [setItems, props.items, isOpen]);

  const onChangeHandler = useCallback(
    (item: MultiSelectItem<T>) => {
      if (!selectedValues.has(item.value) || item.indeterminate) {
        addSelectedItem(item);
      } else {
        removeSelectedItem(item);
      }
      if (item.indeterminate) item.indeterminate = false;
    },
    [selectedValues, addSelectedItem, removeSelectedItem]
  );

  const isItemMatched = useCallback(
    (itemText: ReactNode) => {
      return !!itemText
        ?.toString()
        .toLowerCase()
        .includes(queryString.toLowerCase());
    },
    [queryString]
  );

  return (
    <>
      {children({
        isOpen,
        props: toggleProps,
        selectedItems,
        addSelectedItem,
        removeSelectedItem
      })}

      {createPortal(
        <MultiSelectContext.Provider value={portal}>
          <MultiSelectMenuWrapper
            style={styles.popper}
            {...attributes.popper}
            {...getMenuProps({ ref: setPopover })}
          >
            {isOpen && (
              <MultiSelectMenu>
                <HeaderWrapper version={version}>
                  {showSearchbar && (
                    <StyledInput
                      onInput={(event: React.ChangeEvent<HTMLInputElement>) =>
                        onDebouncedInput(event.target.value)
                      }
                      icon="search"
                      version={version}
                    />
                  )}
                  {props.title && props.title}
                  {props.onReset && (
                    <ResetButton
                      level="link"
                      size="small"
                      onClick={onReset}
                      version={version}
                    >
                      Reset All
                    </ResetButton>
                  )}
                </HeaderWrapper>
                {Object.values(filteredGroupItems).map((group, i) => (
                  <ULWrapper key={i}>
                    {/* Every filter group other than the one at very the top gets a top-divider. */}
                    {i !== 0 && <Divider />}

                    {group.label && (
                      <Label version={version}>{group.label}</Label>
                    )}
                    <ul onMouseLeave={() => setHighlightedIndex(-1)} key={i}>
                      {group.items.map(item => {
                        const itemIndex = index;
                        index++;
                        /** Read:
                         * Filtering must happen at this step, because doing so earlier will cause item index to be mismatched on every render and a selection/deselection event will do the unexpected
                         */
                        const isMatch = isItemMatched(item.text);
                        return isMatch ? (
                          <MultiSelectLi
                            {...getItemProps({
                              key: item.key || `${item.text}`,
                              item,
                              index: itemIndex,
                              highlighted: highlightedIndex === itemIndex,
                              type: item.type
                            })}
                            version={version}
                          >
                            <Checkbox
                              readOnly
                              checked={selectedValues.has(item.value)}
                              indeterminate={
                                item.indeterminate &&
                                !selectedValues.has(item.value)
                              }
                              onChange={() => onChangeHandler(item)}
                              disabled={item.disabled}
                            />
                            {item.icon && <Icon name={item.icon} />}
                            {item.text}
                          </MultiSelectLi>
                        ) : null;
                      })}
                    </ul>
                  </ULWrapper>
                ))}
                {showActions && (
                  <Footer ref={setPortal}>
                    <FooterButton
                      level="primary"
                      disabled={!canSubmit}
                      tabIndex={0}
                      version={version}
                      onClick={() => {
                        if (onSubmit) {
                          onSubmit(selectedValues);
                        }
                        toggleMenu();
                      }}
                    >
                      Apply
                    </FooterButton>
                    <FooterButton
                      level="link"
                      tabIndex={0}
                      version={version}
                      onClick={() => {
                        resetSelections();
                        toggleMenu();
                      }}
                    >
                      Cancel
                    </FooterButton>
                  </Footer>
                )}
              </MultiSelectMenu>
            )}
          </MultiSelectMenuWrapper>
        </MultiSelectContext.Provider>,
        document.getElementById('popovers') ?? document.body
      )}
    </>
  );

  /**
   * Propogates the reset event up to the parent element and calls the reset function from the child Multiselect items.
   */
  function onReset() {
    props.onReset?.();
    resetSelections();
  }
}

export const MultiSelectMenuWrapper = styled('div', {
  outline: 0,
  minWidth: '22rem',
  '&:focus': {
    outlineStyle: 'none'
  }
});

const Label = styled('p', {
  padding: '1rem',
  fontSize: '$info',
  paddingLeft: '$xlarge',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

export const ULWrapper = styled('div', {
  overflowY: 'auto',
  overflowX: 'hidden',
  height: '100%',
  maxHeight: '24.4rem',
  outline: 0
});

export const MultiSelectLi = styled('li', {
  padding: '1rem $large', //not a token
  borderRadius: '$small',
  cursor: 'pointer',
  fontSize: '1.4rem', //not a token
  lineHeight: '$info',
  whiteSpace: 'nowrap',
  display: 'grid',
  margin: '0 $medium',
  gridTemplateColumns: 'auto 1fr',
  gap: '1.7rem', //not a token
  overflow: 'hidden',
  color: '$consoleWhite',
  '&:last-child': {
    marginBottom: '$medium'
  },
  '&:hover': {
    backgroundColor: 'rgba(249,249,249,0.05)'
  },
  alignItems: 'center',

  variants: {
    highlighted: {
      true: {
        backgroundColor: 'rgba(249,249,249,0.05)'
      }
    },

    clickable: {
      false: {
        cursor: 'default'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$info',
        lineHeight: '$body'
      }
    }
  }
});

const Footer = styled('footer', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  backgroundColor: '$carkol',
  borderRadius: ' 0 0 $large $large',
  padding: '$xxlarge',
  '&:empty': {
    display: 'none'
  }
});

export const MultiSelectMenu = styled('div', {
  backgroundColor: '$fragile',
  zIndex: 1000,
  display: 'flex',
  flexDirection: 'column',
  border: '.05rem solid rgba(249, 249, 249, 0.1)',
  boxShadow:
    '0rem $xsmall 4rem $xxlarge rgba(0, 0, 0, 0.15), inset 0rem .1rem 0rem rgba(255, 255, 255, 0.2)',
  borderRadius: '$large'
});

const StyledInput = styled(Input, {
  borderRadius: '$medium',
  backgroundColor: '$samehada'
});

const HeaderWrapper = styled('div', {
  fontSize: '$info',
  lineHeight: '$attribution',
  fontWeight: '$bold',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '$xlarge $xlarge $small $xlarge',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const ResetButton = styled(Button, {
  color: '$primary500',
  padding: '0rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const FooterButton = styled(Button, {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});
