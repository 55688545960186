import { useCallback, useMemo } from 'react';

import {
  useGetTeamMembersToRemove,
  useGetTeamSubscription,
  useGetTeamRolePermissionSummary
} from '@parsec/queries';

import { exportCSV } from 'lib/csv/csvActions';

interface TeamMembersToRemoveProps {
  upcomingSeats?: number;
}

/**
 * Hook to get team members to remove
 */
export function useTeamMembersToRemove(props?: TeamMembersToRemoveProps) {
  const { data: membersData, isLoading } = useGetTeamMembersToRemove({
    upcomingSeats: props?.upcomingSeats // check before we remove for in-trial subscriptions
  });
  const subscription = useGetTeamSubscription();
  const { data: permissions } = useGetTeamRolePermissionSummary();

  const membersToRemoveCount = membersData?.member_count || 0;
  const invitesToRemoveCount = membersData?.invite_count || 0;

  const membersToRemove = useMemo(
    () => membersData?.members ?? [],
    [membersData]
  );

  const invitesToRemove = useMemo(
    () => membersData?.invites ?? [],
    [membersData]
  );

  const endOfTerm = subscription.data?.current_term_end
    ? subscription.data.current_term_end
    : '';

  const hasPermissions = Boolean(
    permissions?.team.manage_subscription && permissions?.team.read_team_members
  );

  const showPendingMembersWarning = Boolean(
    hasPermissions && (invitesToRemoveCount !== 0 || membersToRemoveCount !== 0)
  );

  /**
   * Exports a CSV of team members to remove
   */
  const downloadCSV = useCallback(
    ({ onError }: { onError?: (err: unknown) => void }) => {
      const memberRows = membersToRemove.map(member => {
        const {
          user_id,
          user: { email },
          last_connected_at
        } = member;

        const dateStamp = last_connected_at
          ? new Date(last_connected_at).toLocaleDateString('en-US')
          : '';

        const row = [user_id, email, dateStamp]
          .map(s => `${s}`.replace(/^(=|\+|-|@)+/, ''))
          .map(s => `"${s.replace(/"/g, '"""')}"`)
          .join(',');

        return row;
      });

      const memberCsv = `Id,Email,Last connection date\n${memberRows.join('\n')}`;

      const inviteRows = invitesToRemove.map(invite => {
        const { team_id, email, created_at } = invite;

        const dateStamp = created_at
          ? new Date(created_at).toLocaleDateString('en-US')
          : '';

        const row = [team_id, email, dateStamp]
          .map(s => `${s}`.replace(/^(=|\+|-|@)+/, ''))
          .map(s => `"${s.replace(/"/g, '"""')}"`)
          .join(',');

        return row;
      });

      const inviteCsv = `Team Id,Email,Created date\n${inviteRows.join('\n')}`;

      try {
        exportCSV(memberCsv, 'parsec_pending_team_members.csv');
        if (invitesToRemoveCount > 0) {
          exportCSV(inviteCsv, 'parsec_pending_team_invites.csv');
        }
      } catch (err) {
        onError?.(err);
        throw err;
      }
    },
    [invitesToRemove, invitesToRemoveCount, membersToRemove]
  );

  return {
    membersToRemoveCount,
    invitesToRemoveCount,
    showPendingMembersWarning,
    downloadCSV,
    endOfTerm,
    isLoading
  };
}
