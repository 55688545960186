import { useState } from 'react';

import { LegacyModal, styled } from '@parsec/components';

import { useAlertContext } from 'context';

import LogoSvg from 'lib/images/logo.svg';

interface SeatsDowngradeModalProps {
  isOpen: boolean;
  onUpdate(): Promise<void>;
  onClose(): void;
}

export function SeatsDowngradeModal(props: SeatsDowngradeModalProps) {
  const alerts = useAlertContext();
  const { isOpen, onClose, onUpdate } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const [error, setError] = useState('');

  return (
    <LegacyModal
      type="highlight"
      title="Confirm your plan changes &amp; payment"
      description={
        <>
          <Logo />
          <Description>Remote access for creatives.</Description>
        </>
      }
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          text: 'Confirm Changes',
          disabled: isUpdating,
          onClick: onDowngrade,
          kind: 'success'
        },
        {
          text: 'Cancel',
          level: 'secondary',
          onClick: () => {
            onClose();
          }
        }
      ]}
    >
      <strong>This is a non-refundable purchase.</strong> Your seat count will
      change immediately. You will not be refunded for the remainder of the
      billing cycle for the removed seats.
      {error ? <ErrorMessage>{error}</ErrorMessage> : null}
    </LegacyModal>
  );

  async function onDowngrade() {
    try {
      setIsUpdating(true);
      setError('');
      await onUpdate();
    } catch (err) {
      const message =
        err instanceof Error ? err.message : "Couldn't downgrade subscription.";
      alerts.show({
        type: 'error',
        title: 'Subscription update error',
        message
      });
      setIsUpdating(false);
      setError(error);
    } finally {
      setIsUpdating(false);
      onClose();
    }
  }
}

const Logo = styled(LogoSvg, {
  width: '29rem',
  height: '3rem',
  color: '$computerBlack'
});

const Description = styled('p', {
  fontSize: '$body',
  lineHeight: '$body',
  marginTop: '$medium'
});

const ErrorMessage = styled('p', {
  marginTop: '$medium',
  color: '$error500'
});
