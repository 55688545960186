import { ComponentProps } from 'react';

import { styled, Loading } from '@parsec/components';

export interface DataListProps extends ComponentProps<typeof Loading> {}

export function DataList(props: DataListProps) {
  return (
    <Wrapper loading={props.loading}>
      <Loading loading={props.loading}>{props.children}</Loading>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  position: 'relative',
  backgroundColor: '$carkol',
  borderRadius: '$large',
  minHeight: '80rem',
  variants: {
    loading: {
      true: {
        '> div': {
          height: '100%'
        }
      }
    }
  }
});
