import { Method, request } from '@parsec/request';

import { ApiOptions } from './api';
import {
  PaginatedTeamGroupData,
  TeamGroup,
  PermittedTeamGroup,
  Host,
  PermittedGroupPermission
} from './schema';

/******************************************************************************
 * Get TeamGroups
 ******************************************************************************/

export interface GetTeamGroupsReq {
  team_id: string;
  offset: number;
  limit: number;
}
export const getTeamGroups =
  (options: ApiOptions) =>
  /**
   * Gets a paginated list of TeamGroups for a Team.
   */
  (req: GetTeamGroupsReq) => {
    const { team_id, offset, limit } = req;
    return request<PaginatedTeamGroupData>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/groups/?offset=${offset}&limit=${limit}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Get TeamGroup
 ******************************************************************************/

export interface GetTeamGroupReq {
  team_id: string;
  group_id: number;
}
export const getTeamGroup =
  (options: ApiOptions) =>
  /**
   * Gets a TeamGroup.
   */
  (req: GetTeamGroupReq) => {
    const { team_id, group_id } = req;
    return request<{ data: TeamGroup }>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Create TeamGroup
 ******************************************************************************/

export interface CreateTeamGroupReq {
  team_id: string;
  name: string;
  allow_connections_within_group: boolean;
  show_group_assigned_managed_hosts: boolean;
  show_user_assigned_managed_hosts: boolean;
  show_personal_hosts: boolean;
}
export interface CreateTeamGroupRes {
  data: TeamGroup;
}
export const createTeamGroup =
  (options: ApiOptions) =>
  /**
   * Create a new TeamGroup for a Team.
   * Will also create a default permission to allow connection to itself.
   */
  (req: CreateTeamGroupReq) => {
    const { team_id, ...body } = req;
    return request<CreateTeamGroupRes>({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update TeamGroup
 ******************************************************************************/

export interface UpdateTeamGroupReq {
  team_id: string;
  group_id: number;
  name?: string;
}
export const updateTeamGroup =
  (options: ApiOptions) =>
  /**
   * Updates a TeamGroup's name.
   */
  (req: UpdateTeamGroupReq) => {
    const { team_id, group_id, name } = req;
    return request({
      type: Method.PATCH,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: {
        name
      }
    });
  };

/******************************************************************************
 * Delete TeamGroup
 ******************************************************************************/

export interface DeleteTeamGroupReq {
  team_id: string;
  group_id: number;
}
export const deleteTeamGroup =
  (options: ApiOptions) =>
  /**
   * Delete a TeamGroup.
   */
  (req: DeleteTeamGroupReq) => {
    const { team_id, group_id } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Bulk Update TeamMembers Groups
 ******************************************************************************/

export interface BulkUpdateGroupMembershipsReq {
  team_id: string;
  members: { [user_id: number]: number[] | null };
}
export const BulkUpdateGroupMemberships =
  (options: ApiOptions) =>
  /**
   * Adds one or more TeamMembers to one or more TeamGroups. Idempotent.
   */
  (req: BulkUpdateGroupMembershipsReq) => {
    const { team_id, members } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body: { members }
    });
  };

/******************************************************************************
 * Get TeamGroup Connections
 ******************************************************************************/

export interface GetTeamGroupConnectionsReq {
  team_id: string;
  group_id: number;
  direction: 'incoming' | 'outgoing' | '';
  offset: number;
  limit: number;
}
export interface GetTeamGroupConnectionsRes {
  data: TeamGroup[];
  count: number;
}
export const getTeamGroupConnections =
  (options: ApiOptions) =>
  /**
   * Fetches all TeamGroups that have permission to form an incoming or outgoing
   * connection to the given TeamGroup ID.
   */
  (req: GetTeamGroupConnectionsReq) => {
    const { team_id, group_id, direction, offset, limit } = req;
    return request<GetTeamGroupConnectionsRes>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}/permitted-groups?offset=${offset}&limit=${limit}&direction=${direction}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Get TeamGroup Connections V2
 ******************************************************************************/

export interface GetTeamGroupConnectionsV2Req {
  team_id: string;
  group_id: number;
}
export interface GetTeamGroupConnectionsV2Res {
  data: PermittedTeamGroup[];
}
export const getTeamGroupConnectionsV2 =
  (options: ApiOptions) =>
  /**
   * Fetches all TeamGroups that have permission to form an incoming or outgoing
   * connection to the given TeamGroup ID.
   */
  (req: GetTeamGroupConnectionsV2Req) => {
    const { team_id, group_id } = req;
    return request<GetTeamGroupConnectionsV2Res>({
      type: Method.GET,
      url: `${options.kessel}/v2/teams/${team_id}/groups/${group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Create TeamGroup Connections
 ******************************************************************************/

export interface CreateTeamGroupConnectionsReq {
  team_id: string;
  group_id: number;
  direction: 'incoming' | 'outgoing';
  group_ids: number[];
}
export const createTeamGroupConnections =
  (options: ApiOptions) =>
  /**
   * Give one or more TeamGroups permission to form an incoming or outgoing
   * connection with a TeamGroup ID.
   */
  (req: CreateTeamGroupConnectionsReq) => {
    const { team_id, group_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Create TeamGroup Connections V2
 ******************************************************************************/

export interface CreateTeamGroupConnectionsV2Req {
  team_id: string;
  group_id: number;
  permitted_group_id: number;
  incoming_permissions?: PermittedGroupPermission;
  outgoing_permissions?: PermittedGroupPermission;
}
export const createTeamGroupConnectionsV2 =
  (options: ApiOptions) =>
  /**
   * Give one or more TeamGroups permission to form an incoming or outgoing
   * connection with a TeamGroup ID.
   */
  (req: CreateTeamGroupConnectionsV2Req) => {
    const { team_id, group_id, ...body } = req;
    return request({
      type: Method.POST,
      url: `${options.kessel}/v2/teams/${team_id}/groups/${group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update TeamGroup Connections
 ******************************************************************************/

export interface UpdateTeamGroupConnectionsReq {
  team_id: string;
  from_group_id: number;
  to_group_id: number;
  show_group_assigned_managed_hosts: boolean;
  show_user_assigned_managed_hosts: boolean;
  show_personal_hosts: boolean;
}
export const updateTeamGroupConnections =
  (options: ApiOptions) => (req: UpdateTeamGroupConnectionsReq) => {
    const { team_id, from_group_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/teams/${team_id}/groups/${from_group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Update TeamGroup Connections V2
 ******************************************************************************/

export interface UpdateTeamGroupConnectionsV2Req {
  team_id: string;
  group_id: number;
  permitted_group_id: number;
  incoming_permissions?: PermittedGroupPermission;
  outgoing_permissions?: PermittedGroupPermission;
}
export const updateTeamGroupConnectionsV2 =
  (options: ApiOptions) => (req: UpdateTeamGroupConnectionsV2Req) => {
    const { team_id, group_id, ...body } = req;
    return request({
      type: Method.PUT,
      url: `${options.kessel}/v2/teams/${team_id}/groups/${group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Delete TeamGroup Connections
 ******************************************************************************/

export interface DeleteTeamGroupConnectionsReq {
  team_id: string;
  group_id: number;
  direction: 'incoming' | 'outgoing' | '';
  group_ids: number[];
}
export const deleteTeamGroupConnections =
  (options: ApiOptions) =>
  /**
   * Revoke one or more TeamGroups permission to form an incoming or outgoing
   * connection with a TeamGroup ID.
   */
  (req: DeleteTeamGroupConnectionsReq) => {
    const { team_id, group_id, ...body } = req;
    return request({
      type: Method.DELETE,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}/permitted-groups`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware,
      body
    });
  };

/******************************************************************************
 * Get TeamGroup Permitted Hosts
 ******************************************************************************/

export interface GetTeamGroupPermittedHostsReq {
  team_id: string;
  group_id: number;
}
export interface GetTeamGroupPermittedHostsRes {
  data: Host[];
}
export const getTeamGroupPermittedHosts =
  (options: ApiOptions) =>
  /**
   * Fetches all TeamGroup permitted hosts that will appear in the host list for members of the given
   * group.
   */
  (req: GetTeamGroupPermittedHostsReq) => {
    const { team_id, group_id } = req;
    return request<GetTeamGroupPermittedHostsRes>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}/permitted-hosts`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };

/******************************************************************************
 * Get TeamGroup Display Hosts
 ******************************************************************************/

export interface GetTeamGroupDisplayHostsReq {
  team_id: string;
  group_id: number;
  include: 'group-assigned' | 'member-assigned' | 'personal';
}
export interface GetTeamGroupDisplayHostsRes {
  data: {
    group_assigned_hosts: Host[];
    user_assigned_hosts: Host[];
    personal_hosts: Host[];
  };
}
export const getTeamGroupDisplayHosts =
  (options: ApiOptions) =>
  /**
   * Fetches TeamGroup hosts that are associated with the group. This includes hosts that are
   * assigned to the group, hosts that are assigned to the group's members, and personal hosts belonging to members of the
   * group.
   */
  (req: GetTeamGroupDisplayHostsReq) => {
    const { team_id, group_id, include } = req;
    return request<GetTeamGroupDisplayHostsRes>({
      type: Method.GET,
      url: `${options.kessel}/teams/${team_id}/groups/${group_id}/hosts?include=${include}`,
      headers: { Authorization: `Bearer ${options.token()}` },
      middleware: options.middleware
    });
  };
