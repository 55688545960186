import { styled } from '@parsec/components';

export const Badge = styled('div', {
  backgroundColor: '#FFAD11', // no token
  color: '#745008',
  borderRadius: '2px',
  textTransform: 'uppercase',
  fontWeight: 'bold',
  padding: '0 $small',
  fontSize: '$info',
  marginLeft: '$medium',
  height: '2rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});
