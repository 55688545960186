import { CardElement } from '@stripe/react-stripe-js';

import { styled } from '@parsec/components';

export interface CreditCardInputProps {
  className?: string;
  id?: string;
  disabled?: boolean;
}

const MAIN_TEXT = '#f9fafc';
const PLACEHOLDER_TEXT = '#7d7d7e';
const DISABLED_TEXT = '#666767';

export function CreditCardInput(props: CreditCardInputProps) {
  const { className, id, disabled } = props;

  return (
    <Input
      className={className}
      id={id}
      disabled={disabled}
      options={{
        disabled,
        iconStyle: 'solid',
        style: {
          base: {
            lineHeight: '36px',
            fontFamily: 'Source Sans Pro',
            color: MAIN_TEXT,
            ':disabled': {
              color: DISABLED_TEXT
            },
            '::placeholder': {
              color: PLACEHOLDER_TEXT
            }
          }
        }
      }}
    />
  );
}

const Input = styled(CardElement, {
  width: '100%',
  padding: '0 $medium',
  lineHeight: '2rem',
  backgroundColor: '$carkol',
  boxShadow: 'inset 0 0 0 0.1rem $colors$pancham',
  borderRadius: '$small',
  color: '$consoleWhite',
  transition: '125ms box-shadow ease',
  variants: {
    disabled: {
      true: {
        backgroundColor: '$zekrom',
        boxShadow: 'inset 0 0 0 0.1rem $colors$carkol'
      },
      false: {}
    }
  },

  '&.StripeElement--hover': {
    boxShadow: 'inset 0 0 0 0.2rem $colors$primary500'
  },
  '&.StripeElement--focus': {
    boxShadow: 'inset 0 0 0 0.2rem $colors$primary500'
  }
});
