import { type ReactNode } from 'react';

import { styled, Button } from '@parsec/components';

import { useWarnIfUnsavedChanges } from 'lib/hooks';

interface ResetButtonProps {
  isResetDisabled?: boolean;
  onReset: () => void;
  children: ReactNode;
  version?: 'newFont';
}

function ResetButton(props: ResetButtonProps) {
  return (
    <Button
      level="secondary"
      disabled={props.isResetDisabled}
      onClick={props.onReset}
      version={props.version}
    >
      {props.children}
    </Button>
  );
}

interface SaveButtonProps {
  isSaveDisabled?: boolean;
  onSave: () => void;
  children: ReactNode;
  version?: 'newFont';
}

function SaveButton(props: SaveButtonProps) {
  return (
    <Button
      version={props.version}
      disabled={props.isSaveDisabled}
      level="primary"
      kind="neutral"
      onClick={props.onSave}
    >
      {props.children}
    </Button>
  );
}

export interface SaveChangesPromptProps {
  message?: string;
  isDisabled?: boolean;
  children: ReactNode;
  version?: 'newFont';
}
export function SaveChangesPrompt(props: SaveChangesPromptProps) {
  const { message, isDisabled, children, version } = props;

  useWarnIfUnsavedChanges(!isDisabled, () => {
    return confirm('Changes you made may not be saved.');
  });

  return (
    <UpdateWrapper>
      <Message disabled={isDisabled} version={version}>
        {message}
      </Message>
      <ButtonWrapper>{children}</ButtonWrapper>
    </UpdateWrapper>
  );
}

SaveChangesPrompt.ResetButton = ResetButton;
SaveChangesPrompt.SaveButton = SaveButton;
export default SaveChangesPrompt;

const Message = styled('p', {
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    },
    disabled: {
      true: {
        color: '$error500'
      }
    }
  }
});

const UpdateWrapper = styled('div', {
  position: 'sticky',
  zIndex: 2,
  bottom: '-8rem',
  left: 0,
  borderRadius: '$medium',
  backgroundColor: '#0C0C0D',
  padding: '$xxlarge',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  fontWeight: 'bold',
  width: 'calc(100% + 1.6rem)',
  marginLeft: '-0.8rem'
});

const ButtonWrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$large'
});
