import {
  IncidentNotificationBanner,
  useIncidentNotification
} from '@parsec/components';

import { Content } from './Content';

export const ContentWithIncidentBanner = ({
  children
}: {
  children: ReactNode;
}) => {
  //Incident notification
  const { incidentStatus, incidentText, incidentTitle, showIncidentBanner } =
    useIncidentNotification();

  return (
    <Content>
      {showIncidentBanner ? (
        <IncidentNotificationBanner
          status={incidentStatus}
          text={incidentText}
          title={incidentTitle}
        />
      ) : null}
      {children}
    </Content>
  );
};
