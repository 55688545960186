import { useGetTeamRelays } from '@parsec/queries';

import { isRelayActive, isRelayRegistered } from 'lib/util/relay';

export function useCountRelayIssues(): number {
  const { data: relays } = useGetTeamRelays();
  let count = 0;
  relays?.forEach(r => {
    if (isRelayRegistered(r) && !isRelayActive(r)) {
      count += 1;
    }
  });
  return count;
}
