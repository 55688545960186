import Link from 'next/link';

import { Tip } from '@parsec/components';
import { useSeatCompliance } from '@parsec/hooks';

export const NonCompliantUserTip = () => {
  const { freePlanBlockingEnabled, nonCompliantUserCount } =
    useSeatCompliance();

  const userString = nonCompliantUserCount === 1 ? 'user' : 'users';

  return (
    <Tip type="warning" disabled={freePlanBlockingEnabled} version="newFont">
      {`You have ${nonCompliantUserCount} ${userString} (last 90 days) not on
                  your team — this may pose data risks. Disable personal plan
                  access to protect your team. `}
      <Link href="https://support.parsec.app/hc/en-us/articles/16422677325709-Domain-Verification">
        Learn why this matters.
      </Link>
    </Tip>
  );
};
