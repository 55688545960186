import { styled, IconButton } from '@parsec/components';

import { Description } from './Description';
import { Section } from './Section';

export interface PaginationProps {
  description?: string;
  offset: number;
  limit: number;
  count: number;
  onPrev(): void;
  onNext(): void;
  version?: 'newFont';
}
export function Pagination(props: PaginationProps) {
  const { offset, limit, count, description, onPrev, onNext, version } = props;
  return (
    <Wrapper>
      {description && (
        <SmallDescription version={version}>{description}</SmallDescription>
      )}
      <ButtonSection>
        {count ? (
          <Label version={version}>
            {offset + 1}–{Math.min(count, offset + limit)} of {count}
          </Label>
        ) : null}
        <PageButton
          title="Previous Page"
          disabled={offset === 0 || count === 0}
          onClick={onPrev}
          icon="caret"
          direction="left"
        />
        <PageButton
          title="Next Page"
          disabled={count <= offset + limit || count === 0}
          onClick={onNext}
          icon="caret"
          direction="right"
        />
      </ButtonSection>
    </Wrapper>
  );
}

const Wrapper = styled(Section, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end'
});

const SmallDescription = styled(Description, {
  color: '$rhyhorn',
  fontSize: '$info',
  padding: '0 2rem',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault'
      }
    }
  }
});

const ButtonSection = styled('div', {
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '1rem'
});

const Label = styled('p', {
  display: 'inline-flex',
  alignItems: 'center',
  fontSize: '$info',
  lineHeight: '$info',
  variants: {
    bold: {
      true: {
        fontWeight: 'bold'
      },
      false: {
        fontWeight: 'normal'
      }
    },
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newInfo'
      }
    }
  }
});

const PageButton = styled(IconButton, {
  variants: {
    direction: {
      left: {
        transform: 'rotate(90deg)'
      },
      right: {
        transform: 'rotate(-90deg)'
      }
    }
  }
});
