export const SUPPORT_LINKS = {
  BILLING_SUPPORT: 'https://support.parsec.app/hc/en-us/sections/4425877326733',
  CONTACT_US: 'https://support.parsec.app/hc/en-us/requests/new',
  GUEST_ACCESS_SUPPORT:
    'https://support.parsec.app/hc/en-us/articles/4420201013261',
  MAIL_TO_SALES: 'mailto:sales@parsec.app',
  DECREASING_SEATS:
    'https://support.parsec.app/hc/en-us/articles/25955996060941-Managing-Seats#decreasing_seats',
  GROUP_HOSTS_SUPPORT:
    'https://support.parsec.app/hc/en-us/articles/4420034046221-Manage-Groups#where-are-my-hosts'
};
