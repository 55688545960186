/**
 * Exports any CSV string to the client's local machine.
 *
 * @param csv A CSV file, likely generated by `buildCSV`.
 * @param filename The name the CSV file will have when exported.
 */
export function exportCSV(csv: string, filename: string) {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if ('msSaveBlob' in navigator) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (navigator as any).msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

/**
 * Transforms an array of any object <T> into a CSV string.
 *
 * @param data An array of objects of any type <T>. The properties of the object <T>
 *     will form the headers of the CSV and the values will make up the rows.
 */
export function buildCSV<T extends object>(data?: Array<T>): string {
  if (!data) {
    return '';
  }

  const csvRows = [];
  const headers = Object.keys(data[0]);
  csvRows.push(headers.join(','));

  for (const row of data) {
    const values = headers.map(header => {
      const val = row[header as keyof T];
      return `"${val}"`;
    });
    csvRows.push(values.join(','));
  }
  return csvRows.join('\n');
}
