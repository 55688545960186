import { ReactNode } from 'react';

import { styled } from '@parsec/styles';

import Icon from '../Icon';

const Wrapper = styled('div', {
  display: 'grid',
  gridAutoFlow: 'column',
  gridColumnGap: '$large',
  alignItems: 'center',
  justifyContent: 'left',
  padding: '$large',
  boxShadow: 'inset 0 0 0 0.1rem $colors$rhyhorn',
  borderRadius: '$small',

  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    },
    type: {
      info: {
        color: '$rhyhorn'
      },
      warning: {
        color: '$consoleWhite'
      },
      important: {
        color: '$consoleWhite',
        gridColumnGap: '$xxlarge',
        padding: '$xxlarge',
        backgroundColor: '$chandelure',
        boxShadow: 'inset 0 0 0 0.1rem $colors$pancham',
        lineHeight: '$attribution'
      }
    },
    disabled: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

const StyledIcon = styled(Icon, {
  width: '1.6rem',
  height: '1.6rem',
  color: '$warning500',
  variants: {
    type: {
      warning: {
        color: '$warning500'
      },
      important: {
        color: '$consoleWhite'
      },
      info: {
        color: '$warning500'
      }
    },
    disabled: {
      true: {
        color: '$rhyhorn'
      }
    }
  }
});

export interface TipProps {
  className?: string;
  children: ReactNode;
  type?: 'important' | 'warning' | 'info';
  disabled?: boolean;
  version?: 'newFont';
}

export default function Tip({
  type = 'info',
  disabled = false,
  className,
  version,
  ...props
}: TipProps) {
  const iconName = (() => {
    switch (type) {
      case 'important':
        return 'infoFilled';
      case 'info':
        return 'bulb';
      case 'warning':
        return 'warningSign';
      default:
        return 'bulb';
    }
  })();

  return (
    <Wrapper
      className={className}
      type={type}
      disabled={disabled}
      version={version}
    >
      <StyledIcon name={iconName} type={type} disabled={disabled} />
      <p>{props.children}</p>
    </Wrapper>
  );
}
