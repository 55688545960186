/**
 * Allows us to pad right aligned text to the right of center aligned table heads.
 * This is a wack calculation on how 620 total width /4 columns = 155px is
 * alotted per column. Then we subtrack the width of the head characters and
 * divide by two to get the right padding needing.
 * May need slight tweaking with number of chars if it's looking off.
 *
 * @param {number} charNum - number of characters in the corresponding table header
 * @returns {string} right cell padding value as a css object
 */
export const getCellPadding = (charNum: number) => ({
  paddingRight: `calc((155px - ${charNum}ch) / 2)`
});
