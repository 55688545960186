import { useState } from 'react';

import { useRouter } from 'next/router';

import {
  Modal,
  Input,
  styled,
  Divider,
  Dropdown,
  Icon
} from '@parsec/components';
import { handleFormSubmit } from '@parsec/form';
import { GroupPermissions, TeamPermissions } from '@parsec/kessel';
import { useCreateRole, useGetTeamGroups } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

import { RoleColorSelector } from 'components';

const version = 'newFont';

export interface NewRoleModalProps {
  isOpen: boolean;
  onClose(): void;
  initialName?: string;
  initialColor?: string;
  initialActions?: TeamPermissions | GroupPermissions;
  initialGroupId?: number;
}
export function NewRoleModal(props: NewRoleModalProps) {
  const alert = useAlertContext();
  const createRole = useCreateRole();
  const router = useRouter();
  const roleTab = router.pathname.split('/').at(-1);

  // Display all groups because super admin has access to all
  const groups = useGetTeamGroups().data?.data ?? [];
  const hasGroupId = typeof props.initialGroupId === 'number';

  const [name, setName] = useState(props.initialName ?? '');

  const [roleType, setRoleType] = useState<'global' | 'group'>(
    hasGroupId ? 'group' : 'global'
  );
  const [groupId, setGroupId] = useState(props.initialGroupId);
  const [color, setColor] = useState(props.initialColor ?? '#FFFFFF');

  const allGroups = groups.map(group => ({
    text: group.name,
    onSelect: () => setGroupId(group.id)
  }));

  const groupName =
    groups.find(g => g.id === groupId)?.name ?? 'Select a group';
  const teamHasNoGroups = !allGroups.length;

  const isDuplicating = !!props.initialName;

  async function onCreateRole(values: { name: string }) {
    try {
      alert.clear();
      createRole.reset();

      const newRole = await createRole.mutateAsync({
        name: values.name,
        color: color.substring(1),
        group_id: groupId,
        actions: props.initialActions
      });
      alert.show({
        type: 'success',
        title: 'Success',
        message: 'Role created',
        version
      });
      props.onClose();
      if (newRole) {
        router.push(`/roles/${newRole.id}/${roleTab}`);
      }
    } catch (err) {
      const res = parseError(err, {
        error: "Couldn't create role."
      });
      alert.show({
        type: 'error',
        title: 'Error',
        message: res.error,
        version
      });
    }
  }

  return (
    <Modal
      version={version}
      title={isDuplicating ? 'Duplicate Role' : 'Create New Role'}
      size="small"
      isOpen={props.isOpen}
      onClose={props.onClose}
      actions={[
        {
          text: isDuplicating ? 'Duplicate' : 'Create New Role',
          form: 'create_new_role_form',
          disabled:
            !name || (roleType === 'group' && !groupId) || createRole.isLoading
        },
        { text: 'Cancel', level: 'secondary', onClick: props.onClose }
      ]}
    >
      <form
        id="create_new_role_form"
        onSubmit={handleFormSubmit<{ name: string }>(onCreateRole)}
      >
        {/* Role Name */}
        <Wrapper>
          <Label htmlFor="create_new_role_form_name">Role Name</Label>
          <Input
            version={version}
            id="create_new_role_form_name"
            name="name"
            autoFocus
            defaultValue={name}
            onInput={e => setName((e.target as HTMLInputElement).value)}
          />
        </Wrapper>
        <Divider />
        {/* Role type */}
        <Wrapper>
          <Label htmlFor="create_new_role_form_type">Role Type</Label>
          <p>
            You will not be able to switch between global or group once role is
            created.
          </p>
          <Flex>
            <RadioWrapper>
              <Radio
                disabled={!!props.initialName}
                type="radio"
                name="roleType"
                onChange={() => {
                  setRoleType('global');
                  setGroupId(undefined);
                }}
                checked={roleType === 'global'}
              />
              <span>Global</span>
            </RadioWrapper>
            <RadioWrapper>
              <Radio
                type="radio"
                name="roleType"
                disabled={!!props.initialName || teamHasNoGroups}
                onChange={() => {
                  setRoleType('group');
                  setGroupId(groups[0]?.id);
                }}
                checked={roleType === 'group'}
              />
              <span>Group</span>
            </RadioWrapper>
          </Flex>
          {roleType === 'group' && (
            <Wrapper>
              <Sublabel>Group Role</Sublabel>
              <p>Select a group these role permissions will apply to.</p>
              <Dropdown
                version={version}
                items={allGroups}
                placeholder="Find a group"
                showSearchBar={true}
                defaultValue={groupName}
                x={0}
                y={6}
              >
                {({ isOpen, props }) => (
                  <DropdownToggle {...props}>
                    {groupName}
                    <ArrowIcon name="caret" open={isOpen} />
                  </DropdownToggle>
                )}
              </Dropdown>
            </Wrapper>
          )}
        </Wrapper>
        <Divider />
        <Label>Role Color</Label>
        <RoleColorSelector value={color} onChange={setColor} hasText={false} />
      </form>
    </Modal>
  );
}

const Wrapper = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  display: 'grid',
  gridAutoFlow: 'row',
  gap: '$medium',
  '&:not(:last-child)': {
    marginBottom: '$xxlarge'
  }
});

const Flex = styled('div', {
  display: 'flex',
  margin: '$large 0',
  gridAutoFlow: 'column'
});

const RadioWrapper = styled('div', {
  display: 'flex',
  '&:not(:last-child)': {
    marginRight: '$xxxlarge'
  }
});

const Label = styled('label', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  lineHeight: '$subtitle',
  fontWeight: 'bold'
});

const Sublabel = styled('label', {
  fontWeight: 'bold',
  lineHeight: '$subtitle',
  fontSize: '$newBody'
});

const Radio = styled('input', {
  marginRight: '$large',
  width: '2rem',
  height: '2rem',
  appearance: 'none',
  backgroundColor: '$chandelure',
  border: 'none',
  borderRadius: '50%',
  cursor: 'pointer',
  transition: '125ms box-shadow ease',
  '&:checked': {
    boxShadow: `inset 0 0 0 0.1rem $colors$primary500,
      inset 0 0 0 0.5rem $colors$primary500`,
    cursor: 'default',
    backgroundColor: '$consoleWhite'
  },
  '&:hover': {
    boxShadow: `inset 0 0 0 0.1rem $colors$primary500,
    inset 0 0 0 0.5rem $colors$primary500`
  },
  '&:disabled': {
    cursor: 'not-allowed',
    '&:hover': {
      boxShadow: 'none'
    },
    '&:checked': {
      boxShadow: `inset 0 0 0 0.1rem $colors$primary500,
      inset 0 0 0 0.5rem $colors$primary500`,
      backgroundColor: '$consoleWhite'
    }
  }
});

const ArrowIcon = styled(Icon, {
  transition: 'transform 125ms ease-in-out',
  variants: {
    open: {
      true: {
        transform: 'rotate(180deg)'
      },
      false: {}
    }
  }
});

const DropdownToggle = styled('div', {
  margin: '$medium 0',
  width: '100%',
  padding: '0.8rem $large',
  lineHeight: '2rem',
  backgroundColor: '$carkol',
  boxShadow: 'inset 0 0 0 0.1rem $colors$pancham',
  borderRadius: '$small',
  color: '$consoleWhite',
  transition: '125ms box-shadow ease',
  fontSize: '$newBody',
  fontWeight: 'bold',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '&:disabled': {
    color: '$nice',
    backgroundColor: '$zekrom',
    boxShadow: 'inset 0 0 0 0.1rem $colors$carkol'
  },
  '&:not(:disabled)': {
    '&:hover': {
      outline: 0,
      boxShadow: 'inset 0 0 0 0.1rem $colors$primary500'
    },

    '&:focus': {
      outline: 0,
      boxShadow: 'inset 0 0 0 0.2rem $colors$primary500'
    },
    '&:active': {
      outline: 0,
      boxShadow: 'inset 0 0 0 0.2rem $colors$primary500'
    },

    '&::-moz-focus-inner': {
      border: 'none'
    }
  }
});
