import { Icon, styled } from '@parsec/components';

interface InfoBoxProps {
  title: string;
  subtitle?: string;
  body: string;
  onClickHelp?(): void;
}

export function InfoBox(props: InfoBoxProps) {
  const { title, subtitle, body, onClickHelp = () => {} } = props;
  return (
    <Wrapper>
      <Heading>
        <h4>{title}</h4>
        <TextIconButton onClick={onClickHelp}>
          <Icon name="help" />
          <span>{subtitle}</span>
        </TextIconButton>
      </Heading>
      <p>{body}</p>
    </Wrapper>
  );
}

const Wrapper = styled('div', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  marginTop: '$large',
  padding: '$xxlarge',
  borderRadius: '$medium',
  backgroundColor: '$pancham',
  display: 'grid',
  gap: '$large',
  lineHeight: '$attribution'
});

const TextIconButton = styled('button', {
  fontFamily: '$newDefault',
  fontSize: '$newBody',
  display: 'grid',
  gridAutoFlow: 'column',
  gap: '$medium',
  alignItems: 'center',
  color: '$rhyhorn',
  cursor: 'pointer',
  transition: '0.25s all ease',
  '&:hover': {
    color: '$dewgon'
  }
});

const Heading = styled('div', {
  display: 'flex',
  justifyContent: 'space-between'
});
