import { Modal, styled } from '@parsec/components';
import { GroupPermissions, TeamPermissions, TeamRole } from '@parsec/kessel';
import { useUpdateRole } from '@parsec/queries';
import { parseError } from '@parsec/request';

import { useAlertContext } from 'context';

const version = 'newFont';

const Text = styled('p', {
  fontFamily: '$newDefault',
  fontSize: '$newBody'
});
export interface ClearPermissionsModalProps {
  role: TeamRole;
  isOpen: boolean;
  onClose(): void;
}

export function ClearPermissionsModal(props: ClearPermissionsModalProps) {
  const alert = useAlertContext();
  const updateRole = useUpdateRole();
  // null or undefined group role means it's a global role
  const isGroupRole = props.role.group_id != null;
  return (
    <Modal
      version={version}
      title="Clear Permissions"
      isOpen={props.isOpen}
      onClose={props.onClose}
      size="small"
      actions={[
        {
          text: updateRole.isLoading ? 'Clearing...' : 'Clear Permissions',
          kind: 'error',
          disabled: updateRole.isLoading,
          async onClick() {
            try {
              alert.clear();

              // Send empty permissions to set all permission to false
              const actions = isGroupRole
                ? ({} as GroupPermissions)
                : ({} as TeamPermissions);
              await updateRole.mutateAsync({
                ...props.role,
                actions: actions
              });
              alert.show({
                type: 'success',
                title: 'Success',
                message: 'Permissions were cleared',
                version
              });
              props.onClose();
            } catch (err) {
              const res = parseError(err, {
                error: "Couldn't clear permissions."
              });
              alert.show({
                type: 'error',
                title: 'Error',
                message: res.error,
                version
              });
            }
          }
        },
        { text: 'Cancel', level: 'secondary', onClick: props.onClose }
      ]}
    >
      <Text>
        Reset all permissions to their default state (off) for this role?
      </Text>
    </Modal>
  );
}
