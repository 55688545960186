import { useRouter } from 'next/router';
import { FC, useEffect } from 'react';

import { styled } from '@parsec/components';
import {
  useGetMe,
  useGetTeam,
  useGetTeamRolePermissionSummary
} from '@parsec/queries';
import { redirect } from '@parsec/redirect';
import { Sidebar } from 'components';
import { DASH_URL } from 'lib/config';
import { useEnabledPages } from 'lib/hooks';

interface InsideProps {
  children: ReactNode;
}

export const Inside: FC<InsideProps> = ({ children }) => {
  const meQuery = useGetMe();
  const me = meQuery.data;
  const team = useGetTeam();
  const router = useRouter();

  const {
    billing: isBillingEnabled = false,
    subscription: isSubscriptionEnabled = false,
    isLoading: isEnabledPagesLoading = true
  } = useEnabledPages() ?? {};
  const isTeamActive = Boolean(team.data?.is_active);

  const getAdminPermissionsQuery = useGetTeamRolePermissionSummary();
  const perms = getAdminPermissionsQuery.data;

  const isLoading = isEnabledPagesLoading || getAdminPermissionsQuery.isLoading;
  useEffect(() => {
    const hasAnyPermission =
      Object.values(perms?.team ?? {}).some(Boolean) ||
      Object.keys(perms?.groups ?? {}).length > 0;

    // Check for pathname to avoid infinite loading
    if (!isLoading) {
      if (me && !me.team_id) {
        redirect(`${DASH_URL}/create-team`);
      } else if (
        !isTeamActive &&
        !isBillingEnabled &&
        !isSubscriptionEnabled &&
        router.pathname !== '/inactive-team'
      ) {
        router.replace('/inactive-team');
      } else if (
        !isTeamActive &&
        !isSubscriptionEnabled &&
        router.pathname !== '/billing'
      ) {
        router.replace('/billing');
      } else if (
        perms &&
        !hasAnyPermission &&
        router.pathname !== '/no-permissions'
      ) {
        router.replace('/no-permissions');
      }
    }
  }, [router, me, team, perms, isLoading]);

  return (
    <Wrapper>
      <Sidebar />
      <Main id="main">{children}</Main>
    </Wrapper>
  );
};

const Wrapper = styled('div', {
  stack: {
    layout: '30rem auto'
  },
  height: '100vh',
  width: '100vw',
  overflow: 'hidden'
});

const Main = styled('main', {
  overflow: 'auto',
  backgroundColor: '$computerBlack',
  paddingBottom: '9.6rem'
});
