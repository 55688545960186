import { forwardRef, ChangeEventHandler } from 'react';

import { styled, Toggle } from '@parsec/components';

import { linkify } from 'lib/react-utils';

import { Description } from './Description';

export interface BooleanSettingProps {
  className?: string;
  title: string;
  description: string;
  name: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  version?: 'newFont';
}

export const BooleanSetting = forwardRef<HTMLInputElement, BooleanSettingProps>(
  function BooleanSetting(props, ref) {
    return (
      <Wrapper className={props.className} disabled={props.disabled}>
        <Label htmlFor={props.name} version={props.version}>
          {props.title}
        </Label>
        <StyledDescription info="true" version={props.version}>
          {linkify(props.description)}
        </StyledDescription>
        <StyledToggle
          ref={ref}
          id={props.name}
          name={props.name}
          defaultChecked={props.defaultChecked}
          checked={props.checked}
          disabled={props.disabled}
          onChange={props.onChange}
        />
      </Wrapper>
    );
  }
);

const Wrapper = styled('div', {
  display: 'grid',
  gridTemplateRows: 'auto auto',
  gridTemplateColumns: '3fr 1fr',
  gridColumnGap: '2rem',
  alignItems: 'center',
  justifyItems: 'start',
  variants: {
    disabled: {
      true: {
        color: '$rhyhorn'
      },
      false: {}
    }
  }
});

const Label = styled('label', {
  fontSize: '$body',
  fontWeight: 'bold',
  lineHeight: '$body',
  variants: {
    version: {
      newFont: {
        fontFamily: '$newDefault',
        fontSize: '$newBody'
      }
    }
  }
});

const StyledDescription = styled(Description, {
  gridColumn: '1 / 2'
});

const StyledToggle = styled(Toggle, {
  gridRow: '1 / 3',
  gridColumn: '2 / 3',
  justifySelf: 'end',
  '> input': {
    '&:disabled': {
      color: '$duskull',
      boxShadow: 'inset 0 0 0 0.1rem $colors$pancham'
    }
  }
});
